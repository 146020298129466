import { GeocodeDefinedStatus } from './types';

const SHORT_NAME = 'short_name';
const LONG_NAME = 'long_name';
const STREET_NUMBER = 'street_number';
const STREET = 'route';
const CITY = 'locality';
const SUB_LOCALITY = 'sublocality_level_1'; // Brooklyn and other parts of New York City do not include the city as part of the address. Instead, they use sublocality_level_1.
const POSTAL_TOWN = 'postal_town'; // City in Great Britain
const STATE = 'administrative_area_level_1';
const COUNTY = 'administrative_area_level_2';
const POSTAL_CODE = 'postal_code';
const POLITICAL = 'political';
const PREMISE = 'premise';
const SUBPREMISE = 'subpremise';
// Puerto Rico, US Virgin Islands, Guam, American Samoa, Northern Mariana Islands
const US_NON_STATE_TERRITORIES = ['PR', 'VI', 'GU', 'AS', 'MP'];
const COUNTRY = 'country';

export {
  CITY,
  COUNTRY,
  COUNTY,
  LONG_NAME,
  POLITICAL,
  POSTAL_CODE,
  POSTAL_TOWN,
  PREMISE,
  SHORT_NAME,
  STATE,
  STREET,
  STREET_NUMBER,
  SUB_LOCALITY,
  SUBPREMISE,
  US_NON_STATE_TERRITORIES,
};

export const searchOptionsByRegion = {
  us: {
    types: ['address'],
    componentRestrictions: { country: 'us' },
  },
  uk: {
    types: ['address'],
    componentRestrictions: { country: 'gb' },
  },
};

export const geocodeStatusMap: Record<
  GeocodeDefinedStatus,
  { retryable: boolean }
> = {
  ZERO_RESULTS: {
    retryable: false,
  },
  OVER_QUERY_LIMIT: {
    retryable: true,
  },
  REQUEST_DENIED: {
    retryable: true,
  },
  INVALID_REQUEST: {
    retryable: false,
  },
  UNKNOWN_ERROR: {
    retryable: true,
  },
  ERROR: {
    retryable: true,
  },
};
