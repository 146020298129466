import {
  anExpertMetric,
  aSalesMetric,
  mockExpertMetricSearchesQuery,
  mockMetricsQuery,
} from '@/generated/types';
import {
  rand,
  randAnimalType,
  randFullName,
  randNumber,
  randRecentDate,
  randSoonDate,
  randUuid,
} from '@ngneat/falso';

export const handlers = [
  mockMetricsQuery((req, res, ctx) =>
    res(
      ctx.data({
        metrics: {
          product: {
            sku: req.variables.productSKU,
          },
          interval: {
            start: randRecentDate().toISOString(),
            end: randSoonDate().toISOString(),
          },
          total: {
            accepts: randNumber(),
            opportunities: randNumber(),
            offers: randNumber(),
            acceptRate: randNumber({ min: 0, max: 1, fraction: 2 }),
            offerRate: randNumber({ min: 0, max: 1, fraction: 2 }),
          },
          businessUnits: {
            edges: Array.from({ length: 2 }, () => ({
              node: {
                id: randUuid(),
                businessUnit: rand(['pss', 'protection']),
              },
            })),
          },
          sites: {
            edges: Array.from({ length: 9 }, () => ({
              node: {
                id: randUuid(),
                businessUnit: rand(['pss', 'protection']),
                source: rand([
                  'glow',
                  'sales-tool',
                  'field-app',
                  'ubif',
                  'anywhere-expert',
                ]),
                site: randAnimalType(),
                metrics: {
                  accepts: randNumber(),
                },
              },
            })),
          },
          managers: {
            edges: Array.from({ length: 100 }, () => ({
              node: {
                id: randUuid(),
                managerName: randFullName(),
                managerId: randUuid(),
              },
            })),
          },
          supervisors: {
            edges: Array.from({ length: randNumber({ max: 300 }) }, () => ({
              node: {
                id: randUuid(),
                supervisorName: randFullName(),
                supervisorId: randUuid(),
              },
            })),
          },
          experts: {
            pageInfo: {
              hasNextPage: true,
              endCursor: randUuid(),
            },
            edges: Array.from(
              { length: req.variables.firstExperts ?? 100 },
              () => ({
                node: {
                  id: randUuid(),
                  name: randFullName(),
                  metrics: {
                    accepts: randNumber({ max: 100 }),
                    offers: randNumber({ max: 100 }),
                    opportunities: randNumber({ max: 100 }),
                    acceptRate: randNumber({ min: 0, max: 1, fraction: 2 }),
                    offerRate: randNumber({ min: 0, max: 1, fraction: 2 }),
                  },
                },
              }),
            ),
          },
        },
      }),
    ),
  ),
  mockExpertMetricSearchesQuery((_req, res, ctx) =>
    res(
      ctx.data({
        expertMetric: anExpertMetric({
          metrics: aSalesMetric({
            searches: 5,
          }),
        }),
      }),
    ),
  ),
];
