import { getCurrentSessionURL, isInitialized } from '@fullstory/browser';
import { useAnalytics } from '@sales-domain/analytics-react';
import { MessageType } from '@soluto-private/asp-core';
import { useCreateCommunication } from '@soluto-private/asp-react-core';
import { Button, ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import { redactedFsEvent } from '@soluto-private/utils';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export interface SendCommunicationButtonProps extends ButtonProps {
  communicationUrl: string;
  messageType: MessageType;
  nextPageRoute: string;
  partner: string;
  productOfferId: string;
  programName: string;
  template: string;
  productSku: string;
  params: URLSearchParams;
}

export function SendCommunicationButton({
  children,
  communicationUrl,
  disabled,
  messageType,
  nextPageRoute,
  partner,
  productOfferId,
  programName,
  template,
  productSku,
  params,
  onClick,
  isLoading,
  ...props
}: SendCommunicationButtonProps) {
  const analytics = useAnalytics();
  const router = useRouter();
  const [{ fetching: creatingCommunication }, createCommunication] =
    useCreateCommunication();

  const sendLinkClick = useCallback(async () => {
    try {
      const templateVariables = {
        PROGRAM_NAME: programName,
        TEMPLATE_NAME: template,
        magicLink_poid: productOfferId,
        magicLink_redirectURI: communicationUrl,
      };

      const communicationResponse = await createCommunication({
        templateId: template,
        templateVariables,
        productSku: productSku,
        type: messageType,
      });

      if (communicationResponse.error) {
        throw new Error(
          communicationResponse.error.message || 'Unknown error occurred',
        );
      }

      router.push(`${nextPageRoute}?${params}`);
    } catch (error) {
      const analyticEvent = {
        Name: 'NoCommunication_Created',
        Scope: analytics.scope,
        ExtraData: {
          Scope: analytics.scope,
          FullstorySession: isInitialized()
            ? getCurrentSessionURL()
            : undefined,
          Partner: partner,
          CarrierName: partner,
        },
        MetaData: {
          SourceApp: analytics.sourceApp,
          Client: partner,
        },
      };
      analytics.track(analyticEvent.Name, analyticEvent);
      redactedFsEvent(analyticEvent.Name, analyticEvent);
    }
  }, [
    programName,
    productOfferId,
    communicationUrl,
    template,
    productSku,
    messageType,
    partner,
    createCommunication,
    nextPageRoute,
    router,
    analytics,
  ]);

  return (
    <Button
      disabled={disabled}
      onClick={(e) => {
        onClick?.(e);
        sendLinkClick();
      }}
      isLoading={isLoading || creatingCommunication}
      {...props}
    >
      {children}
    </Button>
  );
}
