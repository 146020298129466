import Bugsnag from '@bugsnag/js';
import { InvariantEvent, WarnEvent } from '@soluto-private/bridge';
import { useCallback } from 'react';
import { useBridgeEvent } from './useBridgeEvent';

/**
 * Relay invariant events to Bugsnag.
 *
 * This hook should be used in the root component of the application to ensure
 * that all ASPEventBridge invariant events are reported.
 */
export function useBridgeInvariant() {
  useBridgeEvent(
    InvariantEvent,
    useCallback((event) => {
      Bugsnag.notify(event.message, (event) => {
        event.severity = 'error';
        event.context = 'twilio-flex';
      });
    }, []),
  );

  useBridgeEvent(
    WarnEvent,
    useCallback((event) => {
      Bugsnag.notify(event.message, (event) => {
        event.severity = 'warning';
        event.context = 'twilio-flex';
      });
    }, []),
  );
}
