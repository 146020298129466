export const validateStreetAddress = (streetAddress: string): boolean => {
  const [first, ...rest] = streetAddress.split(' ');
  return (
    !isNaN(Number(first)) &&
    rest.length > 0 &&
    rest.every((word) => word.length >= 1)
  );
};

export const validateCity = (city: string): boolean => {
  return (
    city.length >= 3 &&
    city.length <= 36 &&
    /^[A-Za-z -]+$/.test(city) &&
    /[A-Za-z]/.test(city)
  );
};

export const validateZipCode = (zipCode: string): boolean =>
  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode.trim());
