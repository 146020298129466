import { ProductEligibilityStatus } from '@soluto-private/asp-core';

export const PRODUCT_SKU_DEFAULT_ELIGIBILITY_MAP = new Map([
  ['ASURIONCOMPLETEPROTECT', ProductEligibilityStatus.Eligible],
  ['VZN_AAL', ProductEligibilityStatus.Eligible],
  ['VZN_ACC', ProductEligibilityStatus.Eligible],
  ['VZN_VMPMD', ProductEligibilityStatus.External],
  ['MCAFEEADVANCED', ProductEligibilityStatus.Eligible],
  ['MCAFEEULTIMATE', ProductEligibilityStatus.Eligible],
  ['MCAFEEPCOPTIMIZER', ProductEligibilityStatus.Eligible],
  ['OPTIMUM-PPS', ProductEligibilityStatus.Eligible],
]);
