import { ASPEventBridge } from '@/utils/aspEventBridge';
import {
  CustomerSessionStartedEvent,
  CustomerValidatedEvent,
  ExpertAuthStatusChangedEvent,
  SupportSessionClosedEvent,
  SupportSessionStartedEvent,
  TaskStatusUpdatedEvent,
  UpdateExpertSessionEvent,
  WorkerAttributesEvent,
} from '@soluto-private/bridge';
import { useEffect } from 'react';

/**
 * Hook to set up event listeners for ASP events.
 *
 * It will add and remove event listeners for the handlers provided. This hook
 * should be updated when new events from ASP event bridge need to be handled.
 *
 * @param handleCustomerSessionStarted Callback for when a customer session
 *   started event is received
 * @param handleUpdateExpertSession Callback for when an expert session updated
 *   event is received
 * @param handleTaskStatusUpdated Callback for when a task status is updated
 * @param handleExpertAuthStatusChanged Callback for when an expert's auth
 *   status changes
 * @param handleCustomerValidated Callback for when a customer validated event
 *   is received
 * @param handleSupportSessionStarted Callback for when a support session
 *   started event is received
 * @param handleSupportSessionClosed Callback for when a support session closed
 *   event is received
 * @param handleWorkerAttributes Callback for when worker attributes are updated
 */
export function useEventBridge({
  handleCustomerSessionStarted,
  handleUpdateExpertSession,
  handleTaskStatusUpdated,
  handleExpertAuthStatusChanged,
  handleCustomerValidated,
  handleSupportSessionStarted,
  handleSupportSessionClosed,
  handleWorkerAttributes,
}: {
  handleCustomerSessionStarted?: (event: CustomerSessionStartedEvent) => void;
  handleUpdateExpertSession?: (event: UpdateExpertSessionEvent) => void;
  handleTaskStatusUpdated?: (event: TaskStatusUpdatedEvent) => void;
  handleExpertAuthStatusChanged?: (event: ExpertAuthStatusChangedEvent) => void;
  handleCustomerValidated?: (event: CustomerValidatedEvent) => void;
  handleSupportSessionStarted?: (event: SupportSessionStartedEvent) => void;
  handleSupportSessionClosed?: (event: SupportSessionClosedEvent) => void;
  handleWorkerAttributes?: (event: WorkerAttributesEvent) => void;
}) {
  useEffect(() => {
    if (handleCustomerSessionStarted) {
      ASPEventBridge.getInstance().addEventListener(
        CustomerSessionStartedEvent.type,
        handleCustomerSessionStarted,
      );

      return () => {
        ASPEventBridge.getInstance().removeEventListener(
          CustomerSessionStartedEvent.type,
          handleCustomerSessionStarted,
        );
      };
    }
  }, [handleCustomerSessionStarted]);

  useEffect(() => {
    if (handleUpdateExpertSession) {
      ASPEventBridge.getInstance().addEventListener(
        UpdateExpertSessionEvent.type,
        handleUpdateExpertSession,
      );

      return () => {
        ASPEventBridge.getInstance().removeEventListener(
          UpdateExpertSessionEvent.type,
          handleUpdateExpertSession,
        );
      };
    }
  }, [handleUpdateExpertSession]);

  useEffect(() => {
    if (handleTaskStatusUpdated) {
      ASPEventBridge.getInstance().addEventListener(
        TaskStatusUpdatedEvent.type,
        handleTaskStatusUpdated,
      );

      return () => {
        ASPEventBridge.getInstance().removeEventListener(
          TaskStatusUpdatedEvent.type,
          handleTaskStatusUpdated,
        );
      };
    }
  }, [handleTaskStatusUpdated]);

  useEffect(() => {
    if (handleExpertAuthStatusChanged) {
      ASPEventBridge.getInstance().addEventListener(
        ExpertAuthStatusChangedEvent.type,
        handleExpertAuthStatusChanged,
      );

      return () => {
        ASPEventBridge.getInstance().removeEventListener(
          ExpertAuthStatusChangedEvent.type,
          handleExpertAuthStatusChanged,
        );
      };
    }
  }, [handleExpertAuthStatusChanged]);

  useEffect(() => {
    if (handleCustomerValidated) {
      ASPEventBridge.getInstance().addEventListener(
        CustomerValidatedEvent.type,
        handleCustomerValidated,
      );

      return () => {
        ASPEventBridge.getInstance().removeEventListener(
          CustomerValidatedEvent.type,
          handleCustomerValidated,
        );
      };
    }
  }, [handleCustomerValidated]);

  useEffect(() => {
    if (handleSupportSessionStarted) {
      ASPEventBridge.getInstance().addEventListener(
        SupportSessionStartedEvent.type,
        handleSupportSessionStarted,
      );

      return () => {
        ASPEventBridge.getInstance().removeEventListener(
          SupportSessionStartedEvent.type,
          handleSupportSessionStarted,
        );
      };
    }
  }, [handleSupportSessionStarted]);

  useEffect(() => {
    if (handleSupportSessionClosed) {
      ASPEventBridge.getInstance().addEventListener(
        SupportSessionClosedEvent.type,
        handleSupportSessionClosed,
      );

      return () => {
        ASPEventBridge.getInstance().removeEventListener(
          SupportSessionClosedEvent.type,
          handleSupportSessionClosed,
        );
      };
    }
  }, [handleSupportSessionClosed]);

  useEffect(() => {
    if (handleSupportSessionStarted) {
      ASPEventBridge.getInstance().addEventListener(
        SupportSessionStartedEvent.type,
        handleSupportSessionStarted,
      );

      return () => {
        ASPEventBridge.getInstance().removeEventListener(
          SupportSessionStartedEvent.type,
          handleSupportSessionStarted,
        );
      };
    }
  }, [handleSupportSessionStarted]);

  useEffect(() => {
    if (handleWorkerAttributes) {
      ASPEventBridge.getInstance().addEventListener(
        WorkerAttributesEvent.type,
        handleWorkerAttributes,
      );

      return () => {
        ASPEventBridge.getInstance().removeEventListener(
          WorkerAttributesEvent.type,
          handleWorkerAttributes,
        );
      };
    }
  }, [handleWorkerAttributes]);
}
