export class SocoEventHandlerQueue {
  queue: { callback: Function; name?: string }[] = [];
  isProcessing = false;
  eventTimeoutDuration = 20000;

  constructor(eventTimeoutDurationOverride?: number) {
    this.eventTimeoutDuration =
      eventTimeoutDurationOverride ?? this.eventTimeoutDuration;
  }

  add = (event: Function, name?: string) => {
    this.queue.push({ callback: event, name: name });
    console.log(
      `SoCo Event Queue: Event ${name} added to queue.  Length:`,
      this.queue.length,
    );
    if (!this.isProcessing) {
      this.process();
    }
  };

  process = async () => {
    if (this.queue.length === 0) {
      console.log('SoCo Event Queue: Queue is empty');
      this.isProcessing = false;
      return;
    }

    this.isProcessing = true;
    const event = this.queue.shift();
    let timedOut = false;
    if (event) {
      console.log(
        `SoCo Event Queue: Popping event ${event.name} from the queue. Processing...`,
      );

      // handle case of timed-out events
      let pending = true;
      setTimeout(async () => {
        if (pending) {
          console.log(
            `SoCo Event Queue: Event ${event.name} timed out after ${
              this.eventTimeoutDuration / 1000
            } seconds.  Proceeding to the next event.`,
          );
          timedOut = true;
          await this.process();
        }
      }, this.eventTimeoutDuration);

      await event.callback();
      pending = false;
    } else {
      console.log('SoCo Event Queue: Event is undefined, skipping...');
    }
    if (timedOut) {
      console.log(
        `SoCo Event Queue: Event ${event?.name} timed out but finally returned.`,
      );
    } else {
      console.log(
        `SoCo Event Queue: Event ${event?.name} processed. Remaining events:`,
        this.queue.length,
      );
      await this.process();
    }
  };
}
