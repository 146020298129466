import { AnalyticsInstance } from '@soluto-private/analytics-core';
import AnalyticsContext from './AnalyticsContext';

type Props = {
  children?: React.ReactNode;
  instance: AnalyticsInstance;
};

const AnalyticsProvider = ({ children, instance }: Props) => {
  return <AnalyticsContext.Provider value={instance} children={children} />;
};

export default AnalyticsProvider;
