import { Suggestion } from 'react-places-autocomplete';
import {
  CITY,
  COUNTRY,
  COUNTY,
  LONG_NAME,
  POLITICAL,
  POSTAL_CODE,
  POSTAL_TOWN,
  PREMISE,
  SHORT_NAME,
  STATE,
  STREET,
  STREET_NUMBER,
  SUBPREMISE,
  SUB_LOCALITY,
  US_NON_STATE_TERRITORIES,
} from './constants';
import { ExtractedAddress } from './types';

export const streetNumberExists = (
  term: google.maps.places.PredictionTerm,
): boolean => {
  return term.offset === 0 && !isNaN(parseInt(term.value));
};

export const filterDropdownItems = (suggestion: Suggestion) => {
  return suggestion.terms.some((term) => {
    return streetNumberExists(term);
  });
};

export const extractAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): ExtractedAddress => {
  let streetNumber = '';
  let street = '';
  let city = '';
  let state = '';
  let county = '';
  let zip = '';
  let address2 = '';
  let country = '';

  addressComponents.forEach((part) => {
    if (part.types.includes(STREET_NUMBER)) {
      streetNumber = part[SHORT_NAME];
    }

    if (part.types.includes(STREET)) {
      street += street === '' ? part[SHORT_NAME] : `, ${part[SHORT_NAME]}`;
    }

    if (
      part.types.includes(CITY) ||
      part.types.includes(POSTAL_TOWN) ||
      part.types.includes(SUB_LOCALITY)
    ) {
      city = part[LONG_NAME];
    }

    if (part.types.includes(STATE) && !state) {
      state = part[SHORT_NAME];
    }

    // These come in with the territory as the country, so stick it into the state field
    if (
      part.types.includes(POLITICAL) &&
      US_NON_STATE_TERRITORIES.includes(part[SHORT_NAME])
    ) {
      state = part[SHORT_NAME];
    }

    if (part.types.includes(COUNTY)) {
      county = part[SHORT_NAME];
    }

    if (part.types.includes(POSTAL_CODE)) {
      zip = part[SHORT_NAME];
    }
    if (part.types.includes(PREMISE)) {
      address2 = part[SHORT_NAME];
    }
    if (part.types.includes(SUBPREMISE)) {
      address2 += ` ${part[SHORT_NAME]}`;
    }

    if (part.types.includes(COUNTRY)) {
      country = part[SHORT_NAME];
    }
  });

  const address1 = `${streetNumber} ${street}`.trim();

  return { address1, city, state, zip, county, address2, country };
};
