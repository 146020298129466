import styles from './ContentContainer.module.css';

type Props = {
  children: React.ReactNode;
  display?: 'flex' | 'block';
};

export default function ContentContainer({
  children,
  display = 'block',
}: Props) {
  const displayClass =
    display == 'flex' ? styles['flex-container'] : styles['block-container'];
  return (
    <div className={`${styles['content-container']} ${displayClass}`}>
      {children}
    </div>
  );
}
