import {
  RadioButton,
  Text,
  TextFieldStatus,
} from '@soluto-private/mx-asurion-ui-react';
import { useState } from 'react';
import { emptyAddress } from '../AddressInput/constants';
import { ExtractedAddress } from '../AddressSearch';
import { AddressSelect } from '../AddressSelect';
import { ButtonGroup } from './elements';

type BillingAddressSelectProps = {
  onAddressSelected: (address: ExtractedAddress | undefined) => void;
  addressPromptText?: string;
  helperText?: string;
  disabled?: boolean;
  fieldStatus?: TextFieldStatus;
};
export const BillingAddressSelect: React.FC<BillingAddressSelectProps> = ({
  onAddressSelected,
  addressPromptText = 'Is your billing address the same as your home address?',
  helperText = 'Begin typing and select the correct address from the list.',
  disabled = false,
  fieldStatus = 'default',
}) => {
  const [choice, setChoice] = useState(true);

  return (
    <>
      <Text data-testid="prompt-text">{addressPromptText}</Text>
      <ButtonGroup>
        <RadioButton
          label="Yes"
          checked={choice}
          onChange={() => {
            setChoice(true);
            onAddressSelected(undefined);
          }}
        />
        <RadioButton
          data-testid="no-radio-button"
          label="No"
          checked={!choice}
          onChange={() => {
            setChoice(false);
            onAddressSelected(emptyAddress);
          }}
        />
      </ButtonGroup>
      {!choice && (
        <AddressSelect
          labelText="Billing Address"
          onAddressSelected={onAddressSelected}
          helperText={helperText}
          linkText="Can't find your address? Enter it manually."
          disabled={disabled}
          fieldStatus={fieldStatus}
        ></AddressSelect>
      )}
    </>
  );
};
