var __require = /* @__PURE__ */ ((x) => typeof require !== "undefined" ? require : typeof Proxy !== "undefined" ? new Proxy(x, {
  get: (a, b) => (typeof require !== "undefined" ? require : a)[b]
}) : x)(function(x) {
  if (typeof require !== "undefined")
    return require.apply(this, arguments);
  throw Error('Dynamic require of "' + x + '" is not supported');
});

// src/common/hooks/use-exponentional-interval.ts
import { useEffect, useRef } from "react";
import {
  concatMap,
  delay,
  filter,
  fromIterable,
  fromValue,
  pipe,
  subscribe
} from "wonka";
function useExponentialInterval(callback, {
  base = 1e3,
  factor = 2,
  maxTries,
  maxInterval = 30 * 1e3,
  predicate
} = {}) {
  const callbackRef = useRef(callback);
  const predicateRef = useRef(predicate);
  useEffect(() => {
    const subscription = pipe(
      fromIterable(
        function* () {
          let count = 0;
          while (maxTries == null || count < maxTries) {
            yield count++;
          }
        }()
      ),
      concatMap(
        (value) => pipe(
          fromValue(value),
          delay(Math.min(base * factor ** value, maxInterval))
        )
      ),
      filter(() => predicateRef.current?.() ?? true),
      subscribe(() => {
        callbackRef.current();
      })
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [base, factor, maxTries, maxInterval]);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  useEffect(() => {
    predicateRef.current = predicate;
  }, [predicate]);
}

// src/common/hooks/use-start-customer-session.ts
import { PRODUCT_SKU_DEFAULT_ELIGIBILITY_MAP } from "@sales-domain/constants";
import {
  isCreateCustomerSessionSuccessPayload
} from "@soluto-private/asp-core";

// ../invariant/dist/index.mjs
var __require2 = /* @__PURE__ */ ((x) => typeof __require !== "undefined" ? __require : typeof Proxy !== "undefined" ? new Proxy(x, {
  get: (a, b) => (typeof __require !== "undefined" ? __require : a)[b]
}) : x)(function(x) {
  if (typeof __require !== "undefined")
    return __require.apply(this, arguments);
  throw Error('Dynamic require of "' + x + '" is not supported');
});
var prefix = "Invariant failed";
var InvariantError = class extends Error {
  constructor(message, context, metadata) {
    super(message ? `${prefix}: ${message}` : prefix);
    this.name = "InvariantError";
    this.context = context;
    this.metadata = metadata;
  }
};
var bugsnag;
try {
  bugsnag = __require2("@bugsnag/js").default;
} catch {
}
function bugsnagInvariantHandler(error) {
  if (bugsnag?.isStarted()) {
    bugsnag.notify(error, notifyCallback(error));
  }
}
function notifyCallback(error) {
  return (event2) => {
    if (error instanceof InvariantError) {
      event2.severity = "error";
    }
    if (error.context) {
      event2.context = error.context;
    }
    if (error.metadata) {
      event2.addMetadata("invariant", error.metadata);
    }
  };
}
function consoleInvariantHandler(error) {
  if (process.env.NODE_ENV !== "test") {
    console.error(error);
  }
}
var invariantHandlers = [
  consoleInvariantHandler,
  bugsnagInvariantHandler
];
function invariant(condition, message, options) {
  if (condition) {
    return;
  }
  const error = new InvariantError(
    message,
    options?.context,
    options?.metadata
  );
  Error.captureStackTrace(error, invariant);
  for (const handler of invariantHandlers) {
    handler(error);
  }
  throw error;
}

// ../../node_modules/.pnpm/@fullstory+browser@1.7.0/node_modules/@fullstory/browser/dist/index.esm.js
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function(sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), true).forEach(function(key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function(key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
var snippet = function snippet2(_ref) {
  var orgId = _ref.orgId, _ref$namespace = _ref.namespace, namespace = _ref$namespace === void 0 ? "FS" : _ref$namespace, _ref$debug = _ref.debug, _ref$host = _ref.host, host = _ref$host === void 0 ? "fullstory.com" : _ref$host, _ref$script = _ref.script, script = _ref$script === void 0 ? "edge.fullstory.com/s/fs.js" : _ref$script;
  if (!orgId) {
    throw new Error("FullStory orgId is a required parameter");
  }
  window["_fs_host"] = host;
  window["_fs_script"] = script;
  window["_fs_org"] = orgId;
  window["_fs_namespace"] = namespace;
  (function(m, n, e, t, l, o, g, y) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
      }
      return;
    }
    g = m[e] = function(a, b, s) {
      g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
    };
    g.q = [];
    o = n.createElement(t);
    o.async = 1;
    o.crossOrigin = "anonymous";
    o.src = "https://" + _fs_script;
    y = n.getElementsByTagName(t)[0];
    y.parentNode.insertBefore(o, y);
    g.identify = function(i, v, s) {
      g(l, {
        uid: i
      }, s);
      if (v)
        g(l, v, s);
    };
    g.setUserVars = function(v, s) {
      g(l, v, s);
    };
    g.event = function(i, v, s) {
      g("event", {
        n: i,
        p: v
      }, s);
    };
    g.anonymize = function() {
      g.identify(false);
    };
    g.shutdown = function() {
      g("rec", false);
    };
    g.restart = function() {
      g("rec", true);
    };
    g.log = function(a, b) {
      g("log", [a, b]);
    };
    g.consent = function(a) {
      g("consent", !arguments.length || a);
    };
    g.identifyAccount = function(i, v) {
      o = "account";
      v = v || {};
      v.acctId = i;
      g(o, v);
    };
    g.clearUserCookie = function() {
    };
    g.setVars = function(n2, p) {
      g("setVars", [n2, p]);
    };
    g._w = {};
    y = "XMLHttpRequest";
    g._w[y] = m[y];
    y = "fetch";
    g._w[y] = m[y];
    if (m[y])
      m[y] = function() {
        return g._w[y].apply(this, arguments);
      };
    g._v = "1.3.0";
  })(window, document, window["_fs_namespace"], "script", "user");
};
var fs = function fs2() {
  return window[window._fs_namespace];
};
var ensureSnippetLoaded = function ensureSnippetLoaded2() {
  var snippetLoaded = !!fs();
  if (!snippetLoaded) {
    throw Error("FullStory is not loaded, please ensure the init function is invoked before calling FullStory API functions");
  }
};
var hasFullStoryWithFunction = function hasFullStoryWithFunction2() {
  ensureSnippetLoaded();
  for (var _len = arguments.length, testNames = new Array(_len), _key = 0; _key < _len; _key++) {
    testNames[_key] = arguments[_key];
  }
  return testNames.every(function(current) {
    return fs()[current];
  });
};
var guard = function guard2(name) {
  return function() {
    if (window._fs_dev_mode) {
      var message = "FullStory is in dev mode and is not recording: ".concat(name, " method not executed");
      console.warn(message);
      return message;
    }
    if (hasFullStoryWithFunction(name)) {
      var _fs;
      return (_fs = fs())[name].apply(_fs, arguments);
    }
    console.warn("FS.".concat(name, " not ready"));
    return null;
  };
};
var event = guard("event");
var log = guard("log");
var getCurrentSessionURL = guard("getCurrentSessionURL");
var identify = guard("identify");
var setUserVars = guard("setUserVars");
var consent = guard("consent");
var shutdown = guard("shutdown");
var restart = guard("restart");
var anonymize = guard("anonymize");
var setVars = guard("setVars");
var _init = function _init2(inputOptions, readyCallback) {
  var options = _objectSpread2({}, inputOptions);
  if (fs()) {
    console.warn("The FullStory snippet has already been defined elsewhere (likely in the <head> element)");
    return;
  }
  if (options.recordCrossDomainIFrames) {
    window._fs_run_in_iframe = true;
  }
  if (options.recordOnlyThisIFrame) {
    window._fs_is_outer_script = true;
  }
  if (options.cookieDomain) {
    window._fs_cookie_domain = options.cookieDomain;
  }
  if (options.debug === true) {
    if (!options.script) {
      options.script = "edge.fullstory.com/s/fs-debug.js";
    } else {
      console.warn("Ignoring `debug = true` because `script` is set");
    }
  }
  snippet(options);
  if (readyCallback) {
    fs()("observe", {
      type: "start",
      callback: readyCallback
    });
  }
  if (options.devMode === true) {
    var message = "FullStory was initialized in devMode and will stop recording";
    event("FullStory Dev Mode", {
      message_str: message
    });
    shutdown();
    window._fs_dev_mode = true;
    console.warn(message);
  }
};
var initOnce = function initOnce2(fn, message) {
  return function() {
    if (window._fs_initialized) {
      if (message)
        console.warn(message);
      return;
    }
    fn.apply(void 0, arguments);
    window._fs_initialized = true;
  };
};
var init = initOnce(_init, "FullStory init has already been called once, additional invocations are ignored");

// ../utils/dist/index.mjs
var NitroVariable = {
  $ApiKey$: "$ApiKey$",
  $Authorization$: "$Authorization$",
  $CorrelationId$: "$CorrelationId$",
  $Client$: "$Client$",
  $ClientChannelId$: "$ClientChannelId$",
  $ClientProductSKUNumber$: "$ClientProductSKUNumber$",
  $Mdn$: "$Mdn$",
  $AddressLine1$: "$AddressLine1$",
  $City$: "$City$",
  $State$: "$State$",
  $PostalCode$: "$PostalCode$",
  $Country$: "$Country$",
  $SubscriptionNumber$: "$SubscriptionNumber$",
  $AsurionRouting$: "$AsurionRouting$",
  $LineOfBusiness$: "$LineOfBusiness$",
  $EndUserDomain$: "$EndUserDomain$"
};
var ContractUtils = class _ContractUtils {
  // changes will cause Nitro to invalidate the cache of any contract that uses this
  static generateMdnAndCorrelationId() {
    return {
      description: "Generate a random MDN and correlation ID",
      method: "post",
      url: "https://tas-hc-prod.use1.sqa.aws.asurion.net/api/nitro/v1/run/skip",
      body: {
        mdn: "6newDate(0,yyyySSSss)",
        correlationId: "nitro-newUUID()"
      },
      expect: {
        jsonBody: {
          mdn: "save(" + NitroVariable.$Mdn$ + ")",
          correlationId: "save(" + NitroVariable.$CorrelationId$ + ")"
        }
      }
    };
  }
  // changes (names ignoring-case) will cause Nitro to invalidate the cache of any contract that uses this
  static L7Headers() {
    return {
      "content-type": "application/json",
      "asurion-client": NitroVariable.$Client$,
      "asurion-correlationid": NitroVariable.$CorrelationId$,
      "asurion-apikey": NitroVariable.$ApiKey$,
      authorization: NitroVariable.$Authorization$,
      "asurion-channel": "web",
      "asurion-region": "North America",
      "asurion-routing": NitroVariable.$AsurionRouting$,
      "asurion-enduserdomain": NitroVariable.$EndUserDomain$,
      "asurion-lineofbusiness": NitroVariable.$LineOfBusiness$,
      "accept-language": "en-US",
      accept: "application/json",
      "cache-control": "no-cache",
      "asurion-enduser": "TAS_NITRO"
    };
  }
  static {
    this.consumerKey = process.env.NITRO_CONSUMER_KEY;
  }
  static async sendToNitro(contract) {
    const response = await fetch(
      // we will change to the '/consumer' endpoint once we're ready
      "https://tas-hc-prod.use1.sqa.aws.asurion.net/api/nitro/v1/tryit",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "consumer-key": _ContractUtils.consumerKey
        },
        body: JSON.stringify(contract)
      }
    );
    const jsonResponseBody = JSON.stringify(await response.json(), null, 2);
    if (response.ok) {
      console.log(jsonResponseBody);
    } else {
      throw new Error("Contract failed \u2193\n\n" + jsonResponseBody);
    }
  }
};
function addCountryCode(mdn, countryCode = "+1") {
  return mdn.startsWith("+") ? mdn : `${countryCode}${mdn}`;
}
var DefaultCase = Symbol("DefaultCase");
function pickBy(obj, predicate) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => predicate(value))
  );
}
function pickDefined(object) {
  return pickBy(
    object,
    (value) => value != null
  );
}

// src/common/hooks/use-start-customer-session.ts
import { useCallback as useCallback22 } from "react";

// src/customer-session/CustomerSessionContext.tsx
import { createContext } from "react";
var CustomerSessionContext = createContext(null);

// src/customer-session/hooks/use-create-billing.ts
import {
  CreateBillingDocument
} from "@soluto-private/asp-core";
import { useCallback } from "react";
import { useMutation } from "urql";

// src/customer-session/hooks/use-customer-session.ts
import {
  CustomerSessionDocument
} from "@soluto-private/asp-core";
import { useContext } from "react";
import { useQuery } from "urql";
function useCustomerSession({
  customerSessionId: providedId,
  includeCustomer,
  includeMarketing,
  required
} = {}) {
  const contextId = useContext(CustomerSessionContext);
  const customerSessionId = providedId ?? contextId;
  const [{ data, ...rest }, queryCustomerSession] = useQuery({
    query: CustomerSessionDocument,
    variables: {
      customerSessionId,
      includeCustomer,
      includeMarketing
    },
    pause: customerSessionId == null
  });
  invariant(
    !required || data?.customerSession != null,
    "customer session is required to be defined"
  );
  return [
    { data: data?.customerSession, ...rest },
    queryCustomerSession
  ];
}

// src/customer-session/hooks/use-create-billing.ts
function useCreateBilling() {
  const [{ data: customerSession, fetching }] = useCustomerSession();
  const [{ fetching: creatingBilling, ...createBillingResult }, createBilling] = useMutation(CreateBillingDocument);
  return [
    {
      fetching: fetching || creatingBilling,
      ...createBillingResult
    },
    useCallback(
      (variables, context) => {
        invariant(
          customerSession,
          "customer session is required to be defined to create a Billing entity"
        );
        return createBilling(
          {
            customerSessionId: customerSession.id,
            ...pickDefined(variables)
          },
          appendPartner(customerSession.partner, context)
        );
      },
      [createBilling, customerSession]
    )
  ];
}

// src/customer-session/hooks/use-create-communication.ts
import {
  CreateCommunicationDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback2, useContext as useContext2 } from "react";
import { useMutation as useMutation2 } from "urql";
function useCreateCommunication() {
  const customerSessionId = useContext2(CustomerSessionContext);
  invariant(
    customerSessionId != null,
    "useCreateCommunication must be wrapped in a CustomerSessionProvider"
  );
  const [{ data: customerSession, fetching }] = useCustomerSession();
  const [
    { fetching: creatingCommunication, ...createCommunicationResult },
    createCommunication
  ] = useMutation2(CreateCommunicationDocument);
  return [
    {
      fetching: fetching || creatingCommunication,
      ...createCommunicationResult
    },
    useCallback2(
      (variables, context) => {
        invariant(
          customerSession,
          "customer session is required to be defined to create a Communication entity"
        );
        return createCommunication(
          {
            customerSessionId: customerSession.id,
            ...pickDefined(variables)
          },
          appendPartner(customerSession.partner, context)
        );
      },
      [createCommunication, customerSession]
    )
  ];
}

// src/customer-session/hooks/use-create-customer-session.ts
import {
  CreateCustomerSessionDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback3, useContext as useContext3 } from "react";
import { useMutation as useMutation3 } from "urql";

// src/expert-session/ExpertSessionContext.tsx
import { createContext as createContext2 } from "react";
var ExpertSessionContext = createContext2(null);

// src/customer-session/hooks/use-create-customer-session.ts
function useCreateCustomerSession() {
  const expertSessionId = useContext3(ExpertSessionContext);
  const [createCustomerSessionResult, createCustomerSession] = useMutation3(
    CreateCustomerSessionDocument
  );
  return [
    createCustomerSessionResult,
    useCallback3(
      (variables, context) => createCustomerSession(
        {
          expertSessionId,
          ...pickDefined(variables)
        },
        appendPartner(variables.partner, context)
      ),
      [createCustomerSession, expertSessionId]
    )
  ];
}

// src/customer-session/hooks/use-create-mdn-verification.ts
import {
  CreateMdnVerificationDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback4, useContext as useContext4 } from "react";
import { useMutation as useMutation4 } from "urql";
function useCreateMdnVerification() {
  const customerSessionId = useContext4(CustomerSessionContext);
  invariant(
    customerSessionId != null,
    "useCreateMdnVerification must be wrapped in a CustomerSessionProvider"
  );
  const [{ data: customerSession, fetching }] = useCustomerSession();
  const [
    { fetching: creatingMdnVerification, ...createMdnVerificationResult },
    createMdnVerification
  ] = useMutation4(CreateMdnVerificationDocument);
  return [
    {
      fetching: fetching || creatingMdnVerification,
      ...createMdnVerificationResult
    },
    useCallback4(
      (variables, context) => {
        invariant(
          customerSession,
          "customer session is required to be defined to create a MDN Verification entity"
        );
        return createMdnVerification(
          {
            customerSessionId: customerSession.id,
            ...variables
          },
          appendPartner(customerSession.partner, context)
        );
      },
      [createMdnVerification, customerSession]
    )
  ];
}

// src/customer-session/hooks/use-create-pay-session.ts
import {
  CreatePaySessionDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback13 } from "react";
import { useMutation as useMutation11 } from "urql";

// src/product-offer/hooks/use-create-product-declination.ts
import {
  CreateProductDeclinationDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback6 } from "react";
import { useMutation as useMutation5 } from "urql";

// src/product-offer/hooks/use-product-offer.ts
import {
  ProductEligibilityStatus,
  ProductEnrollmentStatus,
  ProductOfferDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback5, useContext as useContext5, useMemo } from "react";
import { useQuery as useQuery2 } from "urql";

// src/product-offer/ProductOfferContext.tsx
import { createContext as createContext3 } from "react";
var ProductOfferContext = createContext3(null);

// src/product-offer/hooks/use-product-offer.ts
function useProductOffer({
  includeBillingDiscounts,
  includeProductDeclination,
  includeProductEligibility,
  includeProductEnrollment,
  productOfferId,
  required
} = {}) {
  const contextId = useContext5(ProductOfferContext);
  const offerId = productOfferId ?? contextId;
  const context = useMemo(
    () => ({
      additionalTypenames: [
        includeBillingDiscounts && "BillingDiscounts",
        includeProductDeclination && "ProductDeclination",
        includeProductEligibility && "ProductEligibility",
        includeProductEnrollment && "ProductEnrollment"
      ].filter((value) => Boolean(value))
    }),
    [
      includeBillingDiscounts,
      includeProductDeclination,
      includeProductEligibility,
      includeProductEnrollment
    ]
  );
  const [{ data, ...rest }, queryProductOffer] = useQuery2({
    query: ProductOfferDocument,
    variables: {
      includeBillingDiscounts,
      includeProductDeclination,
      includeProductEligibility,
      includeProductEnrollment,
      productOfferId: offerId
    },
    pause: offerId == null,
    context,
    requestPolicy: "network-only"
  });
  useExponentialInterval(
    useCallback5(() => {
      queryProductOffer({ requestPolicy: "network-only" });
    }, [queryProductOffer]),
    {
      predicate: useCallback5(
        () => data?.productOffer?.productEligibility?.status === ProductEligibilityStatus.Initialized || data?.productOffer?.productEnrollment?.status === ProductEnrollmentStatus.Initialized,
        [
          data?.productOffer?.productEligibility?.status,
          data?.productOffer?.productEnrollment?.status
        ]
      )
    }
  );
  invariant(
    !required || data?.productOffer != null,
    "product offer is required to be defined"
  );
  return [
    { data: data?.productOffer, ...rest },
    queryProductOffer
  ];
}

// src/product-offer/hooks/use-create-product-declination.ts
function useCreateProductDeclination() {
  const [{ data: productOffer, fetching }] = useProductOffer();
  const [
    { fetching: creatingProductDeclination, ...createProductDeclinationResult },
    createProductDeclination
  ] = useMutation5(CreateProductDeclinationDocument);
  return [
    {
      fetching: fetching || creatingProductDeclination,
      ...createProductDeclinationResult
    },
    useCallback6(
      (variables, context) => {
        invariant(
          productOffer,
          "product offer is required to be defined to decline"
        );
        return createProductDeclination(
          { ...variables, productOfferId: productOffer.id },
          appendPartner(productOffer.partner, context)
        );
      },
      [createProductDeclination, productOffer]
    )
  ];
}

// src/product-offer/hooks/use-create-product-enrollment.ts
import {
  CreateProductEnrollmentDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback7 } from "react";
import { useMutation as useMutation6 } from "urql";
function useCreateProductEnrollment() {
  const [{ data: productOffer, fetching }] = useProductOffer();
  const [
    { fetching: creatingProductEnrollment, ...createProductEnrollmentResult },
    createProductEnrollment
  ] = useMutation6(CreateProductEnrollmentDocument);
  return [
    {
      fetching: fetching || creatingProductEnrollment,
      ...createProductEnrollmentResult
    },
    useCallback7(
      (variables, context) => {
        invariant(
          productOffer,
          "product offer is required to be defined to enroll"
        );
        return createProductEnrollment(
          { ...variables, productOfferId: productOffer.id },
          appendPartner(productOffer.partner, context)
        );
      },
      [createProductEnrollment, productOffer]
    )
  ];
}

// src/product-offer/hooks/use-create-product-offer.ts
import {
  CreateProductOfferDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback8, useContext as useContext6 } from "react";
import { useMutation as useMutation7 } from "urql";
function useCreateProductOffer() {
  const contextCustomerSessionId = useContext6(CustomerSessionContext);
  const contextExpertSessionId = useContext6(ExpertSessionContext);
  const [createProductOfferResult, createProductOffer] = useMutation7(
    CreateProductOfferDocument
  );
  return [
    createProductOfferResult,
    useCallback8(
      ({ customerSessionId: providedCustomerSessionId, ...variables }, context) => {
        const customerSessionId = providedCustomerSessionId ?? contextCustomerSessionId;
        const expertSessionId = contextExpertSessionId;
        invariant(
          customerSessionId,
          "customerSessionId is required for createProductOffer"
        );
        return createProductOffer(
          {
            customerSessionId,
            expertSessionId,
            ...pickDefined(variables)
          },
          appendPartner(variables.partner, context)
        );
      },
      [contextCustomerSessionId, contextExpertSessionId, createProductOffer]
    )
  ];
}

// src/product-offer/hooks/use-delete-product-offer.ts
import {
  DeleteProductOfferDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback9 } from "react";
import { useMutation as useMutation8 } from "urql";
function useDeleteProductOffer() {
  const [deleteProductOfferResult, deleteProductOffer] = useMutation8(
    DeleteProductOfferDocument
  );
  return [
    deleteProductOfferResult,
    useCallback9(
      ({ ...variables }) => {
        return deleteProductOffer({
          ...pickDefined(variables)
        });
      },
      [deleteProductOffer]
    )
  ];
}

// src/product-offer/hooks/use-product-eligibility.ts
import { useContext as useContext7 } from "react";
function useProductEligibility() {
  const productOfferId = useContext7(ProductOfferContext);
  invariant(
    productOfferId != null,
    "useProductEligibility must be wrapped in a ProductOfferProvider component"
  );
  const [{ data: productOffer, ...rest }, queryProductOffer] = useProductOffer({
    includeProductEligibility: true,
    productOfferId
  });
  return [
    { data: productOffer?.productEligibility, ...rest },
    queryProductOffer
  ];
}

// src/product-offer/hooks/use-product-eligibility-updated.ts
import {
  ProductEligibilityUpdatedDocument
} from "@soluto-private/asp-core";
import { useSubscription } from "urql";
function useProductEligibilityUpdated({
  productOfferId,
  since
}) {
  const [{ data, ...rest }, subscribeProductEligibilityUpdated] = useSubscription({
    query: ProductEligibilityUpdatedDocument,
    variables: { productOfferId, since },
    pause: productOfferId == null
  });
  return [
    { data: data?.productEligibilityUpdated, ...rest },
    subscribeProductEligibilityUpdated
  ];
}

// src/product-offer/hooks/use-product-enrollment.ts
import { useContext as useContext8 } from "react";
function useProductEnrollment() {
  const productOfferId = useContext8(ProductOfferContext);
  invariant(
    productOfferId != null,
    "useProductEnrollment must be wrapped in a ProductOfferProvider component"
  );
  const [{ data: productOffer, ...rest }, queryProductOffer] = useProductOffer({
    productOfferId,
    includeProductEnrollment: true
  });
  return [
    { data: productOffer?.productEnrollment, ...rest },
    queryProductOffer
  ];
}

// src/product-offer/hooks/use-product-enrollment-updated.ts
import {
  ProductEnrollmentUpdatedDocument
} from "@soluto-private/asp-core";
import { useSubscription as useSubscription2 } from "urql";
function useProductEnrollmentUpdated({
  productOfferId,
  since
}) {
  const [{ data, ...rest }, subscribeProductEnrollmentUpdated] = useSubscription2({
    query: ProductEnrollmentUpdatedDocument,
    variables: { productOfferId, since },
    pause: productOfferId == null
  });
  return [
    { data: data?.productEnrollmentUpdated, ...rest },
    subscribeProductEnrollmentUpdated
  ];
}

// src/product-offer/hooks/use-product-offer-customer-session.ts
import {
  ProductOfferCustomerSessionDocument
} from "@soluto-private/asp-core";
import { useContext as useContext9 } from "react";
import { useQuery as useQuery3 } from "urql";
function useProductOfferCustomerSession() {
  const productOfferId = useContext9(ProductOfferContext);
  invariant(
    productOfferId != null,
    "useProductOfferCustomerSession must be wrapped in a ProductOfferProvider component"
  );
  const [{ data, ...rest }, queryProductOfferCustomerSession] = useQuery3({
    query: ProductOfferCustomerSessionDocument,
    variables: { productOfferId }
  });
  return [
    { data: data?.productOffer?.customerSession, ...rest },
    queryProductOfferCustomerSession
  ];
}

// src/product-offer/hooks/use-product-offer-tax-amount.ts
import {
  ProductOfferTaxAmountDocument
} from "@soluto-private/asp-core";
import { useContext as useContext10 } from "react";
import { useQuery as useQuery4 } from "urql";
function useProductOfferTaxAmount({
  invoiceAmount,
  shipToAddress
}) {
  const productOfferId = useContext10(ProductOfferContext);
  invariant(
    productOfferId != null,
    "useProductOfferTaxAmount must be wrapped in a ProductOfferProvider component"
  );
  const [{ data, ...rest }, queryProductOfferTaxAmount] = useQuery4({
    query: ProductOfferTaxAmountDocument,
    variables: {
      productOfferId,
      invoiceAmount,
      shipToAddress
    }
  });
  return [
    { data: data?.productOffer?.taxAmount, ...rest },
    queryProductOfferTaxAmount
  ];
}

// src/product-offer/hooks/use-product-offer-updated.ts
import {
  ProductOfferUpdatedDocument
} from "@soluto-private/asp-core";
import { useSubscription as useSubscription3 } from "urql";
function useProductOfferUpdated({
  productOfferId,
  since
}) {
  const [{ data, ...rest }, subscribeProductOfferUpdated] = useSubscription3({
    query: ProductOfferUpdatedDocument,
    variables: { productOfferId, since },
    pause: productOfferId == null
  });
  return [
    { data: data?.productOfferUpdated ?? void 0, ...rest },
    subscribeProductOfferUpdated
  ];
}

// src/product-offer/hooks/use-product-offers-by-customer-session-id.ts
import {
  ProductOffersByCustomerSessionIdDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback10, useContext as useContext11, useMemo as useMemo2 } from "react";
import { useQuery as useQuery5 } from "urql";
function useProductOffersByCustomerSessionId({
  customerSessionId,
  includeProductEligibility,
  includeProductEnrollment
} = {}) {
  const contextId = useContext11(CustomerSessionContext);
  const sessionId = customerSessionId ?? contextId;
  const context = useMemo2(
    () => ({ additionalTypenames: ["ProductOffer"] }),
    []
  );
  const [{ data, ...rest }, queryProductOffersByCustomerSessionId] = useQuery5({
    query: ProductOffersByCustomerSessionIdDocument,
    variables: {
      includeProductEligibility,
      includeProductEnrollment,
      customerSessionId: sessionId
    },
    pause: sessionId == null,
    context
  });
  useExponentialInterval(
    useCallback10(() => {
      queryProductOffersByCustomerSessionId({ requestPolicy: "network-only" });
    }, [queryProductOffersByCustomerSessionId])
  );
  return [
    { data: data?.productOffersByCustomerSessionId, ...rest },
    queryProductOffersByCustomerSessionId
  ];
}

// src/product-offer/hooks/use-update-product-eligibility.ts
import {
  UpdateProductEligibilityDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback11, useContext as useContext12 } from "react";
import { useMutation as useMutation9 } from "urql";
function useUpdateProductEligibility() {
  const productOfferId = useContext12(ProductOfferContext);
  invariant(
    productOfferId != null,
    "useUpdateProductEligibility must be wrapped in a ProductOfferProvider component"
  );
  const [{ data: productOffer, fetching }] = useProductOffer({
    includeProductEligibility: true,
    productOfferId
  });
  const [
    { fetching: updatingProductEligibility, ...updateProductEligibilityResult },
    updateProductEligibility
  ] = useMutation9(UpdateProductEligibilityDocument);
  return [
    {
      fetching: fetching || updatingProductEligibility,
      ...updateProductEligibilityResult
    },
    useCallback11(
      (variables, context) => {
        invariant(
          productOffer?.productEligibility,
          "product eligibility is required to update eligibility"
        );
        return updateProductEligibility(
          {
            productOfferId,
            productEligibilityId: productOffer.productEligibility.id,
            ...variables
          },
          appendPartner(productOffer.partner, context)
        );
      },
      [
        productOffer?.productEligibility,
        updateProductEligibility,
        productOfferId
      ]
    )
  ];
}

// src/product-offer/hooks/use-update-product-offer.ts
import {
  UpdateProductOfferDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback12 } from "react";
import { useMutation as useMutation10 } from "urql";
function useUpdateProductOffer() {
  const [{ fetching, ...updateProductOfferResult }, updateProductOffer] = useMutation10(UpdateProductOfferDocument);
  return [
    {
      fetching,
      ...updateProductOfferResult
    },
    useCallback12(
      (variables) => {
        return updateProductOffer({
          ...pickDefined(variables)
        });
      },
      [updateProductOffer]
    )
  ];
}

// src/product-offer/ProductOfferProvider.tsx
import { jsx } from "react/jsx-runtime";
function ProductOfferProvider({
  children,
  fallback,
  includeProductEligibility,
  includeProductEnrollment,
  productOfferId
}) {
  const [{ data: productOffer }] = useProductOffer({
    includeProductEligibility,
    includeProductEnrollment,
    productOfferId
  });
  return /* @__PURE__ */ jsx(ProductOfferContext.Provider, { value: productOfferId, children: productOffer != null ? children : fallback });
}

// src/customer-session/hooks/use-create-pay-session.ts
function useCreatePaySession() {
  const [{ data: customerSession, fetching }] = useCustomerSession();
  const [{ data: productOffer, fetching: fetchingProductOffer }] = useProductOffer();
  const [
    { fetching: creatingPaySession, ...createPaySessionResult },
    createPaySession
  ] = useMutation11(CreatePaySessionDocument);
  return [
    {
      fetching: fetching || creatingPaySession || fetchingProductOffer,
      ...createPaySessionResult
    },
    useCallback13(
      (variables, context) => {
        invariant(
          customerSession,
          "customer session is required to be defined to create pay session"
        );
        const {
          productSku: providedProductSku,
          ...variablesWithoutProductSku
        } = variables ?? {};
        const productSku = providedProductSku ?? productOffer?.productSku;
        invariant(
          productSku,
          "Specific product sku or existing product offer is required to create pay session"
        );
        return createPaySession(
          {
            customerSessionId: customerSession.id,
            productSku,
            ...variablesWithoutProductSku
          },
          appendPartner(customerSession.partner, context)
        );
      },
      [customerSession, productOffer, createPaySession]
    )
  ];
}

// src/customer-session/hooks/use-customer.ts
import { useCallback as useCallback14, useContext as useContext13 } from "react";
function useCustomer() {
  const customerSessionId = useContext13(CustomerSessionContext);
  invariant(
    customerSessionId != null,
    "useCustomer must be wrapped in a CustomerSessionProvider component"
  );
  const [{ data: customerSession, ...rest }, queryCustomerSession] = useCustomerSession({
    customerSessionId,
    includeCustomer: true
  });
  useExponentialInterval(
    useCallback14(() => {
      queryCustomerSession({ requestPolicy: "network-only" });
    }, [queryCustomerSession])
  );
  return [
    { data: customerSession?.customer, ...rest },
    queryCustomerSession
  ];
}

// src/customer-session/hooks/use-customer-session-expert-sessions.ts
import {
  CustomerSessionExpertSessionsDocument
} from "@soluto-private/asp-core";
import { useContext as useContext14, useMemo as useMemo3 } from "react";
import { useQuery as useQuery6 } from "urql";
function useCustomerSessionExpertSessions() {
  const customerSessionId = useContext14(CustomerSessionContext);
  invariant(
    customerSessionId != null,
    "useCustomerSessionExpertSessions must be wrapped in a CustomerSessionProvider component"
  );
  const context = useMemo3(
    () => ({ additionalTypenames: ["ExpertSession"] }),
    []
  );
  const [{ data, ...rest }, queryCustomerSessionExpertSessions] = useQuery6({
    query: CustomerSessionExpertSessionsDocument,
    variables: { customerSessionId },
    context
  });
  return [
    { data: data?.customerSession?.expertSessions, ...rest },
    queryCustomerSessionExpertSessions
  ];
}

// src/customer-session/hooks/use-mdn-verification.ts
import {
  MdnVerificationDocument,
  MdnVerificationStatus
} from "@soluto-private/asp-core";
import { useCallback as useCallback15, useContext as useContext15 } from "react";
import { useQuery as useQuery7 } from "urql";
function useMdnVerification({
  mdnVerificationId,
  pollForVerifiedStatus = false
}) {
  const customerSessionId = useContext15(CustomerSessionContext);
  invariant(
    customerSessionId != null,
    "useMdnVerification must be wrapped in a CustomerSessionProvider component"
  );
  const [{ data, ...rest }, queryMdnVerification] = useQuery7({
    query: MdnVerificationDocument,
    variables: { customerSessionId, mdnVerificationId },
    pause: mdnVerificationId == null
  });
  useExponentialInterval(
    useCallback15(() => {
      queryMdnVerification({ requestPolicy: "network-only" });
    }, [queryMdnVerification]),
    {
      predicate: useCallback15(
        () => pollForVerifiedStatus && data?.customerSession?.mdnVerification?.status === MdnVerificationStatus.Pending,
        [data?.customerSession?.mdnVerification?.status, pollForVerifiedStatus]
      )
    }
  );
  return [
    { data: data?.customerSession?.mdnVerification, ...rest },
    queryMdnVerification
  ];
}

// src/customer-session/hooks/use-mdn-verification-updated.ts
import {
  MdnVerificationUpdatedDocument
} from "@soluto-private/asp-core";
import { useSubscription as useSubscription4 } from "urql";
function useMdnVerificationUpdated({
  customerSessionId,
  mdnVerificationId,
  since
}) {
  const [{ data, ...rest }, subscribeMdnVerificationUpdated] = useSubscription4({
    query: MdnVerificationUpdatedDocument,
    variables: {
      customerSessionId,
      mdnVerificationId,
      since
    }
  });
  return [
    { data: data?.mdnVerificationUpdated, ...rest },
    subscribeMdnVerificationUpdated
  ];
}

// src/customer-session/hooks/use-pay-session.ts
import {
  PaySessionDocument,
  PaySessionStatus
} from "@soluto-private/asp-core";
import { useCallback as useCallback16, useContext as useContext16 } from "react";
import { useQuery as useQuery8 } from "urql";
function usePaySession({
  paySessionId
}) {
  const customerSessionId = useContext16(CustomerSessionContext);
  invariant(
    customerSessionId != null,
    "usePaySession must be wrapped in a CustomerSessionProvider component"
  );
  const [{ data, ...rest }, queryPaySession] = useQuery8({
    query: PaySessionDocument,
    variables: { customerSessionId, paySessionId },
    pause: paySessionId == null
  });
  useExponentialInterval(
    useCallback16(() => {
      queryPaySession({ requestPolicy: "network-only" });
    }, [queryPaySession]),
    {
      predicate: useCallback16(
        () => paySessionId != null && data?.customerSession?.paySession?.status === PaySessionStatus.Initialized,
        [data?.customerSession?.paySession?.status, paySessionId]
      )
    }
  );
  return [
    { data: data?.customerSession?.paySession, ...rest },
    queryPaySession
  ];
}

// src/customer-session/hooks/use-pay-session-updated.ts
import {
  PaySessionUpdatedDocument
} from "@soluto-private/asp-core";
import { useSubscription as useSubscription5 } from "urql";
function usePaySessionUpdated({
  paySessionId,
  customerSessionId,
  since
}) {
  const [{ data, ...rest }, subscribePaySessionUpdated] = useSubscription5({
    query: PaySessionUpdatedDocument,
    variables: { paySessionId, customerSessionId, since },
    pause: paySessionId == null
  });
  return [
    { data: data?.paySessionUpdated, ...rest },
    subscribePaySessionUpdated
  ];
}

// src/customer-session/hooks/use-update-billing.ts
import {
  UpdateBillingDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback17 } from "react";
import { useMutation as useMutation12 } from "urql";
function useUpdateBilling() {
  const [{ data: customerSession, fetching: fetchingCustomerSession }] = useCustomerSession();
  const [{ fetching: updatingBilling, ...updateBillingResult }, updateBilling] = useMutation12(UpdateBillingDocument);
  return [
    {
      fetching: fetchingCustomerSession || updatingBilling,
      ...updateBillingResult
    },
    useCallback17(
      (variables, context) => {
        invariant(
          customerSession,
          "customer session is required to be defined to update Billing"
        );
        return updateBilling(
          variables,
          appendPartner(customerSession.partner, context)
        );
      },
      [customerSession, updateBilling]
    )
  ];
}

// src/customer-session/hooks/use-update-customer.ts
import {
  UpdateCustomerDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback18 } from "react";
import { useMutation as useMutation13 } from "urql";
function useUpdateCustomer() {
  const [{ data: customerSession, fetching: fetchingCustomerSession }] = useCustomerSession();
  const [
    { fetching: updatingCustomer, ...updateCustomerResult },
    updateCustomer
  ] = useMutation13(UpdateCustomerDocument);
  return [
    {
      fetching: fetchingCustomerSession || updatingCustomer,
      ...updateCustomerResult
    },
    useCallback18(
      (variables, context) => {
        invariant(
          customerSession,
          "customer session is required to be defined to update customer"
        );
        return updateCustomer(
          variables,
          appendPartner(customerSession.partner, context)
        );
      },
      [customerSession, updateCustomer]
    )
  ];
}

// src/customer-session/hooks/use-update-customer-session.ts
import {
  UpdateCustomerSessionDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback19 } from "react";
import { useMutation as useMutation14 } from "urql";
function useUpdateCustomerSession({
  partner
}) {
  const [updateCustomerSessionResult, updateCustomerSession] = useMutation14(
    UpdateCustomerSessionDocument
  );
  return [
    updateCustomerSessionResult,
    useCallback19(
      (variables, context) => {
        invariant(partner, "Partner not defined");
        return updateCustomerSession(
          {
            ...pickDefined(variables)
          },
          appendPartner(partner, context)
        );
      },
      [updateCustomerSession, partner]
    )
  ];
}

// src/customer-session/hooks/use-update-mdn-verification.ts
import {
  UpdateMdnVerificationDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback20 } from "react";
import { useMutation as useMutation15 } from "urql";
function useUpdateMdnVerification({
  mdnVerificationId
}) {
  const [{ data: customerSession, fetching: fetchingCustomerSession }] = useCustomerSession();
  const [{ data: mdnVerification, fetching: fetchingMdnVerification }] = useMdnVerification({ mdnVerificationId });
  const [
    { fetching: updatingMdnVerification, ...updateMdnVerificationResult },
    updateMdnVerification
  ] = useMutation15(UpdateMdnVerificationDocument);
  return [
    {
      fetching: fetchingCustomerSession || fetchingMdnVerification || updatingMdnVerification,
      ...updateMdnVerificationResult
    },
    useCallback20(
      (variables, context) => {
        invariant(
          !!customerSession && !!mdnVerification,
          "customer session and mdn verification are required to be defined to update mdn verification"
        );
        return updateMdnVerification(
          {
            id: mdnVerification.id,
            customerSessionId: customerSession.id,
            ...variables
          },
          appendPartner(customerSession.partner, context)
        );
      },
      [customerSession, mdnVerification, updateMdnVerification]
    )
  ];
}

// src/customer-session/hooks/use-update-pay-session.ts
import {
  UpdatePaySessionDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback21 } from "react";
import { useMutation as useMutation16 } from "urql";
function useUpdatePaySession() {
  const [{ data: customerSession, fetching: fetchingCustomerSession }] = useCustomerSession();
  const [
    { fetching: updatingPaySession, ...updatePaySessionResult },
    updatePaySession
  ] = useMutation16(UpdatePaySessionDocument);
  return [
    {
      fetching: fetchingCustomerSession || updatingPaySession,
      ...updatePaySessionResult
    },
    useCallback21(
      (variables, context) => {
        invariant(
          customerSession,
          "customer session is required to be defined to update pay session"
        );
        return updatePaySession(
          variables,
          appendPartner(customerSession.partner, context)
        );
      },
      [customerSession, updatePaySession]
    )
  ];
}

// src/customer-session/CustomerSessionProvider.tsx
import { jsx as jsx2 } from "react/jsx-runtime";
function CustomerSessionProvider({
  customerSessionId,
  children,
  fallback,
  includeCustomer
}) {
  const [{ data: customerSession }] = useCustomerSession({
    customerSessionId,
    includeCustomer
  });
  return /* @__PURE__ */ jsx2(CustomerSessionContext.Provider, { value: customerSessionId, children: customerSession != null ? children : fallback });
}

// src/common/hooks/use-start-customer-session.ts
function useStartCustomerSession() {
  const [{ fetching: creatingCustomerSession }, createCustomerSession] = useCreateCustomerSession();
  const [{ fetching: creatingProductOffer }, createProductOffer] = useCreateProductOffer();
  return [
    {
      fetching: creatingCustomerSession || creatingProductOffer
    },
    useCallback22(
      async ({
        id,
        partner,
        customer,
        expertSessionId,
        productSKUs,
        origin,
        asurionCall,
        externalSession,
        channel,
        extensions,
        sessionStatus
      }, context) => {
        const createCustomerSessionResult = await createCustomerSession(
          {
            id,
            partner,
            expertSessionId,
            externalSession,
            customer: {
              ...customer,
              mdn: customer?.mdn != null ? addCountryCode(customer.mdn) : null
            },
            asurionCall,
            origin,
            channel,
            extensions,
            sessionStatus
          },
          context
        );
        if (!isCreateCustomerSessionSuccessPayload(
          createCustomerSessionResult.data
        )) {
          return {
            customerSession: createCustomerSessionResult,
            productOffers: []
          };
        }
        const {
          createCustomerSession: { customerSession }
        } = createCustomerSessionResult.data;
        invariant(
          customerSession.customer != null,
          "customerSession should have been created with a customer"
        );
        invariant(
          customerSession.customer.mdn != null,
          "customerSession should have been created with a customer with an mdn"
        );
        const productsToOffer = [...productSKUs?.entries() ?? []].filter(([, skuPartner]) => skuPartner === partner).map(([productSKU]) => productSKU);
        const productOffers = await Promise.all(
          productsToOffer.map(
            (productSKU) => createProductOffer(
              {
                partner: customerSession.partner,
                // `customer.mdn` is asserted to be non-null above, but the type
                // system doesn't reflect that
                customerMdn: customerSession.customer.mdn,
                customerSessionId: customerSession.id,
                productSku: productSKU,
                productEligibilityStatus: PRODUCT_SKU_DEFAULT_ELIGIBILITY_MAP.get(productSKU),
                expertSessionId
              },
              context
            )
          )
        );
        return {
          customerSession: createCustomerSessionResult,
          productOffers
        };
      },
      [createCustomerSession, createProductOffer]
    )
  ];
}

// src/common/utils/append-partner.ts
function appendPartner(partner, context) {
  const fetchOptions = typeof context?.fetchOptions === "function" ? context.fetchOptions() : context?.fetchOptions ?? {};
  return {
    ...context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        "Asurion-Partner": partner
      }
    }
  };
}

// src/expert-session/hooks/use-expert-session.ts
import {
  ExpertSessionDocument
} from "@soluto-private/asp-core";
import { useContext as useContext17, useMemo as useMemo4 } from "react";
import { useQuery as useQuery9 } from "urql";
function useExpertSession({
  expertSessionId: providedId,
  required,
  includeExpert
} = {}) {
  const contextId = useContext17(ExpertSessionContext);
  const expertSessionId = providedId ?? contextId;
  const context = useMemo4(
    () => ({
      additionalTypenames: [includeExpert && "Expert"].filter(
        (value) => Boolean(value)
      )
    }),
    [includeExpert]
  );
  const [{ data, ...rest }, queryExpertSession] = useQuery9({
    query: ExpertSessionDocument,
    variables: {
      expertSessionId,
      includeExpert
    },
    pause: expertSessionId == null,
    context
  });
  invariant(
    !required || data?.expertSession != null,
    "expert session is required to be defined"
  );
  return [
    { data: data?.expertSession, ...rest },
    queryExpertSession
  ];
}

// src/expert-session/ExpertSessionProvider.tsx
import { jsx as jsx3 } from "react/jsx-runtime";
function ExpertSessionProvider({
  expertSessionId,
  children,
  fallback
}) {
  const [{ data: expertSession }] = useExpertSession({
    expertSessionId
  });
  return /* @__PURE__ */ jsx3(ExpertSessionContext.Provider, { value: expertSessionId, children: expertSession != null ? children : fallback });
}

// src/expert-session/hooks/use-create-expert-session.ts
import {
  CreateExpertSessionDocument
} from "@soluto-private/asp-core";
import { useCallback as useCallback23 } from "react";
import { useMutation as useMutation17 } from "urql";
function useCreateExpertSession(options) {
  const [createExpertSessionResult, createExpertSession] = useMutation17(
    CreateExpertSessionDocument
  );
  return [
    createExpertSessionResult,
    useCallback23(
      (variables = options, context = {}) => createExpertSession(variables, context),
      [createExpertSession, options]
    )
  ];
}

// src/expert-session/hooks/use-expert.ts
import {
  ExpertDocument
} from "@soluto-private/asp-core";
import { useContext as useContext18 } from "react";
import { useQuery as useQuery10 } from "urql";
function useExpert() {
  const expertSessionId = useContext18(ExpertSessionContext);
  invariant(
    expertSessionId != null,
    "useExpert must be wrapped in a ExpertSessionProvider component"
  );
  const [{ data, ...rest }, queryExpertSession] = useQuery10({
    query: ExpertDocument,
    variables: {
      expertSessionId
    }
  });
  return [{ data: data?.expertSession?.expert, ...rest }, queryExpertSession];
}

// src/sales-config/hooks/use-sales-config.ts
import {
  SalesConfigDocument
} from "@soluto-private/asp-core";
import { useQuery as useQuery11 } from "urql";
function useSalesConfig({
  applicationName,
  configName
}) {
  const [{ data, ...rest }, querySalesConfig] = useQuery11({
    query: SalesConfigDocument,
    variables: {
      applicationName,
      configName
    },
    requestPolicy: "network-only"
  });
  return [{ data: data?.salesConfig, ...rest }, querySalesConfig];
}
export {
  CustomerSessionContext,
  CustomerSessionProvider,
  ExpertSessionProvider,
  ProductOfferProvider,
  appendPartner,
  useCreateBilling,
  useCreateCommunication,
  useCreateCustomerSession,
  useCreateExpertSession,
  useCreateMdnVerification,
  useCreatePaySession,
  useCreateProductDeclination,
  useCreateProductEnrollment,
  useCreateProductOffer,
  useCustomer,
  useCustomerSession,
  useCustomerSessionExpertSessions,
  useDeleteProductOffer,
  useExpert,
  useExpertSession,
  useExponentialInterval,
  useMdnVerification,
  useMdnVerificationUpdated,
  usePaySession,
  usePaySessionUpdated,
  useProductEligibility,
  useProductEligibilityUpdated,
  useProductEnrollment,
  useProductEnrollmentUpdated,
  useProductOffer,
  useProductOfferCustomerSession,
  useProductOfferTaxAmount,
  useProductOfferUpdated,
  useProductOffersByCustomerSessionId,
  useSalesConfig,
  useStartCustomerSession,
  useUpdateBilling,
  useUpdateCustomer,
  useUpdateCustomerSession,
  useUpdateMdnVerification,
  useUpdatePaySession,
  useUpdateProductEligibility,
  useUpdateProductOffer
};
