import {
  AuiContainer,
  Dropdown,
  space,
  TextField,
} from '@soluto-private/mx-asurion-ui-react';
import { useEffect, useState } from 'react';
import { ExtractedAddress } from '../AddressSearch/types';
import { emptyAddress, stateNamesByAbbreviation } from './constants';
import { GridFormLayout } from './GridFormLayout';
import {
  validateCity,
  validateStreetAddress,
  validateZipCode,
} from './helpers';

interface AddressInputProps {
  onAddressCompleted: (address: ExtractedAddress) => void;
  address?: ExtractedAddress;
  disabled?: boolean;
}

export const AddressInput: React.FC<AddressInputProps> = ({
  onAddressCompleted = (address) => {},
  address = emptyAddress,
  disabled = false,
}) => {
  const [address1, setAddress1] = useState(address.address1);
  const [address2, setAddress2] = useState(address.address2);
  const [city, setCity] = useState(address.city);
  const [state, setState] = useState(address.state);
  const [zip, setZip] = useState(address.zip);
  const country = 'US';

  useEffect(() => {
    if (
      validateStreetAddress(address1) &&
      validateCity(city) &&
      state &&
      validateZipCode(zip)
    ) {
      onAddressCompleted({
        address1,
        address2,
        city,
        state,
        zip,
        country,
      });
    } else {
      onAddressCompleted(emptyAddress);
    }
  }, [address1, address2, city, state, zip, onAddressCompleted]);

  return (
    <div className="address-input-root" data-testid="address-input-root">
      <AuiContainer gap={space[200]}>
        <GridFormLayout>
          <TextField
            inputClassName="fs-mask"
            label="Street Address"
            value={address1}
            disabled={disabled}
            onChange={(e) => {
              setAddress1(e.target.value);
            }}
          />
        </GridFormLayout>
        <GridFormLayout>
          <TextField
            inputClassName="fs-mask"
            label="Apartment, unit, suite (optional)"
            value={address2}
            disabled={disabled}
            onChange={(e) => {
              setAddress2(e.target.value);
            }}
          />
        </GridFormLayout>
        <GridFormLayout columns={2}>
          <TextField
            inputClassName="fs-mask"
            label="City"
            value={city}
            disabled={disabled}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />

          <GridFormLayout columns={2}>
            <Dropdown
              containerClassName="fs-mask"
              data-testid="state-dropdown"
              label={'State'}
              value={state}
              disabled={disabled}
              options={Object.keys(stateNamesByAbbreviation).map((state) => ({
                name: state,
                value: state,
              }))}
              onChange={(e) => {
                setState(e.target.value);
              }}
            />
            <TextField
              inputClassName="fs-mask"
              label="Zip"
              value={zip}
              maxLength={5}
              disabled={disabled}
              onChange={(e) => {
                setZip(e.target.value);
              }}
            />
          </GridFormLayout>
        </GridFormLayout>
      </AuiContainer>
    </div>
  );
};
