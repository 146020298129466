import {
  BaseErrorFragment,
  isCreateProductEnrollmentSuccessPayload,
  NotFoundErrorFragment,
  ProductEnrollmentFragment,
} from '@soluto-private/asp-core';
import { useCreateProductEnrollment } from '@soluto-private/asp-react-core';
import { Button, ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import { MouseEvent } from 'react';
import { CombinedError } from 'urql';
import { ProductEnrollmentStatus } from '../../generated/types';

export interface CreateProductEnrollmentButton extends ButtonProps {
  onProductEnrollmentCreated?(
    productEnrollment: ProductEnrollmentFragment,
  ): void;
  onBaseError?(error: BaseErrorFragment): void;
  onNotFoundError?(error: NotFoundErrorFragment): void;
  onGraphQLError?(error: CombinedError): void;
  status?: ProductEnrollmentStatus;
  paySessionId?: string;
  confirmationCode?: string;
  externalReferenceId?: string;
}

export function CreateProductEnrollmentButton({
  onProductEnrollmentCreated,
  onBaseError,
  onNotFoundError,
  onGraphQLError,
  onClick,
  status,
  paySessionId,
  confirmationCode,
  externalReferenceId,
  children,
  disabled,
  ...rest
}: CreateProductEnrollmentButton) {
  const [{ fetching }, createProductEnrollment] = useCreateProductEnrollment();

  async function handleClick(event: MouseEvent<HTMLButtonElement>) {
    onClick?.(event);

    const { data, error } = await createProductEnrollment({
      status,
      paySessionId,
      confirmationCode,
      externalReferenceId,
    });

    if (error != null) {
      onGraphQLError?.(error);
    }

    if (isCreateProductEnrollmentSuccessPayload(data)) {
      onProductEnrollmentCreated?.(
        data.createProductEnrollment.productEnrollment,
      );
    } else if (data?.createProductEnrollment.__typename === 'BaseError') {
      onBaseError?.(data.createProductEnrollment);
    } else if (data?.createProductEnrollment.__typename === 'NotFoundError') {
      onNotFoundError?.(data.createProductEnrollment);
    }
  }

  return (
    <Button disabled={fetching || disabled} onClick={handleClick} {...rest}>
      {children ?? 'Enroll'}
    </Button>
  );
}
