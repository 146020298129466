"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  EventBridgeWriter: () => EventBridgeWriter,
  FullStoryWriter: () => FullStoryWriter,
  GoogleAnalyticsWriter: () => GoogleAnalyticsWriter,
  HTTPWriter: () => HTTPWriter,
  createBrowserAnalytics: () => createBrowserAnalytics
});
module.exports = __toCommonJS(src_exports);

// src/helpers/createBrowserAnalytics.ts
var import_analytics_core = require("@soluto-private/analytics-core");
var import_invariant3 = require("@soluto-private/invariant");

// src/writers/eventBridgeWriter.ts
var import_bridge = require("@soluto-private/bridge");
var import_invariant = require("@soluto-private/invariant");
var EventBridgeWriter = class {
  constructor(eventBridge) {
    this.eventBridge = eventBridge;
  }
  async identify(_userId, _traits) {
    (0, import_invariant.warning)(false, "identify is not supported");
  }
  async track(eventName, payload) {
    this.eventBridge.dispatchEvent(
      new import_bridge.TrackEvent({
        Name: eventName,
        ...payload
      })
    );
  }
};

// src/writers/fullstoryWriter.ts
var import_browser = require("@fullstory/browser");
var import_pii_redaction = require("@soluto-private/pii-redaction");
var FullStoryWriter = class {
  constructor() {
    if (!(0, import_browser.isInitialized)()) {
      (0, import_browser.init)({
        orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID,
        recordOnlyThisIFrame: window.self !== window.top,
        devMode: process.env.NEXT_PUBLIC_STAGE === "dev",
        startCaptureManually: false
      });
    }
  }
  async identify(userId, traits) {
    (0, import_browser.FullStory)("setIdentity", { uid: userId, properties: traits });
  }
  async track(eventName, data) {
    (0, import_browser.FullStory)("trackEvent", {
      name: eventName,
      properties: (0, import_pii_redaction.redactPii)(data)
    });
  }
};

// src/writers/googleAnalyticsWriter.ts
var GoogleAnalyticsWriter = class {
  #channel;
  #sessionId;
  #userBrowserId;
  #partner;
  #traits;
  constructor({ channel, partner, browserId }) {
    if (!channel || !partner) {
      throw new Error("Cannot initialize Google Analytics");
    }
    this.#channel = channel;
    this.#partner = partner;
    this.#userBrowserId = browserId;
  }
  /*
    identify(userId: string, traits?: Record<string, any> | undefined)
    Google Analytics does not support identify directly, so we just store the traits for use in the track calls.
  */
  async identify(userId, traits) {
    this.#traits = traits;
    this.#sessionId = userId;
  }
  /*
    track(eventName: string, data: EventProperties)
    Google Analytics does not support track directly, so we push the event data to the dataLayer for consumption by the
    Google Tag Manager Container that is injected into the head of the rendered html.
  */
  async track(eventName, data) {
    window.dataLayer?.push({
      channel: this.#channel,
      sessionId: this.#sessionId,
      userBrowserId: this.#userBrowserId,
      event: eventName,
      partner: data.MetaData?.Client || this.#partner,
      userTraits: this.#traits,
      data
    });
  }
};

// src/writers/httpWriter.ts
var import_invariant2 = require("@soluto-private/invariant");
var HTTPWriter = class {
  constructor(url) {
    this.url = url;
  }
  async postEvent(url, data) {
    const response = await fetch(new URL(url, window.location.origin), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      (0, import_invariant2.warning)(response.ok, `post to ${url} failed: ${await response.text()}`);
      return;
    }
    return response.json();
  }
  async identify(userId, traits) {
    const identifyData = { userId, data: traits };
    await this.postEvent(`${this.url}/identify`, identifyData);
  }
  async track(eventName, data) {
    const trackData = { name: eventName, data };
    await this.postEvent(`${this.url}/track`, trackData);
  }
};

// src/helpers/createBrowserAnalytics.ts
function createBrowserAnalytics(config) {
  const writers = config.customWriters ?? [];
  let httpWriter;
  if (config.httpUrl) {
    httpWriter = new HTTPWriter(config.httpUrl);
    writers.push(httpWriter);
  }
  (0, import_invariant3.invariant)(writers.length, "Cannot return Analytics instance with no writers");
  return new import_analytics_core.Analytics(writers, config.scope, config.sourceApp);
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  EventBridgeWriter,
  FullStoryWriter,
  GoogleAnalyticsWriter,
  HTTPWriter,
  createBrowserAnalytics
});
