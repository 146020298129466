import { assertCreateCustomerSessionSuccessPayload } from '@soluto-private/asp-core';
import { Origin, addCountryCode } from '@soluto-private/utils';

export async function handleCustomerAddressEntry(
  createCustomerSession: any,
  partner: string,
  externalSessionId: string,
  expertSessionId?: string,
  mdn?: string,
  asurionCallId?: string,
  flexReservationId?: string,
): Promise<string> {
  const { data: createCustomerSessionResult } = await createCustomerSession({
    expertSessionId: expertSessionId,
    partner,
    customer: {
      mdn: mdn != null ? addCountryCode(mdn) : null,
    },
    externalSession: {
      id: externalSessionId,
    },
    asurionCall: asurionCallId
      ? {
          id: asurionCallId,
          flexReservation: flexReservationId
            ? {
                id: flexReservationId,
              }
            : undefined,
        }
      : undefined,
    origin: Origin.twilioFlex,
  });

  assertCreateCustomerSessionSuccessPayload(createCustomerSessionResult);

  const {
    createCustomerSession: { customerSession },
  } = createCustomerSessionResult;

  return customerSession.id;
}
