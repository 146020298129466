import { getCurrentSessionURL, isInitialized } from '@fullstory/browser';
import { useAnalytics, useSalesData } from '@sales-domain/analytics-react';
import { invariant } from '@soluto-private/invariant';
import { redactedFsEvent } from '@soluto-private/utils';
import { useSession } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import { ReactNode } from 'react';

export interface IdentityData {
  UserId?: string;
  ExpertSessionId?: string;
}

export interface AnalyticsWrapperProps {
  children: ReactNode;
  name: string;
  extraData?: object;
  identityData?: IdentityData;
  styles?: object;
  className?: string;
  disabled?: boolean;
}

export function AnalyticsWrapper({
  children,
  name,
  extraData,
  identityData,
  styles,
  disabled = false,
  className,
}: AnalyticsWrapperProps) {
  const analytics = useAnalytics();

  invariant(analytics, 'Analytics is not defined');
  const isExpertSalesTool =
    analytics.sourceApp === 'sales-tool' &&
    analytics.scope === 'expert-sales-tool';

  const { data: session } = useSession();
  const searchParams = useSearchParams();
  const csid = searchParams?.get('csid');
  const poid = searchParams?.get('poid');
  const salesData = useSalesData({
    customerSessionId: csid as string | undefined,
    productOfferId: poid as string | undefined,
  });

  if (!isExpertSalesTool) {
    return children;
  }

  const fs =
    typeof window !== 'undefined' && isInitialized()
      ? getCurrentSessionURL()
      : undefined;

  const analyticEvent = {
    Name: name,
    Scope: analytics.scope,
    ExtraData: {
      FullstorySession: fs,
      CustomerSession: salesData?.CustomerSession,
      ProductOffer: salesData?.ProductOffer,
      Partner: salesData?.CustomerSession?.partner,
      CarrierName: salesData?.CustomerSession?.partner,
      Product: {
        SKU: salesData?.ProductOffer?.productSku,
      },
      ...extraData,
    },
    MetaData: {
      SourceApp: analytics.sourceApp,
      Client: salesData?.CustomerSession?.partner,
    },
    Identities: {
      ...identityData,
      CustomerSessionId: csid ?? salesData?.CustomerSession?.id,
      ProductOfferId: poid ?? salesData?.ProductOffer?.id,
      Email: session?.user?.email,
    },
  };

  const handleAnalyticsClick = () => {
    invariant(
      analyticEvent,
      'analyticEvent is not defined, clickable div should not have rendered',
    );

    analytics.track(analyticEvent.Name, analyticEvent);
    if (analyticEvent?.ExtraData?.FullstorySession) {
      redactedFsEvent(analyticEvent.Name, analyticEvent);
    }
  };

  return (
    <div
      style={styles}
      onClick={() => !disabled && handleAnalyticsClick()}
      className={className}
    >
      {children}
    </div>
  );
}
