// src/array-utils.ts
function asArray(value, options) {
  const { converter, nullableAsEmpty = true } = typeof options === "function" ? { converter: options } : options ?? {};
  if (Array.isArray(value)) {
    return value;
  } else if (nullableAsEmpty && value == null) {
    return [];
  } else if (typeof converter === "function") {
    return converter(value);
  } else {
    return [value];
  }
}
function isNonEmptyArray(input) {
  return Array.isArray(input) && input.length > 0;
}

// src/async-iterable.ts
function isAsyncIterable(value) {
  return typeof value === "object" && value !== null && Symbol.asyncIterator in value;
}
function toAsyncIterableIterator(iterable) {
  const iterator = iterable[Symbol.asyncIterator]();
  return {
    [Symbol.asyncIterator]() {
      return this;
    },
    next: iterator.next.bind(iterator),
    return: iterator.return?.bind(iterator),
    throw: iterator.throw?.bind(iterator)
  };
}

// src/case-convert-keys.ts
import { camelCase, pascalCase } from "change-case";

// src/map-objects-deep.ts
function mapEntriesDeep(object, mapper) {
  const result = {};
  for (const [key, value] of Object.entries(object)) {
    let deeplyMappedValue = value;
    if (value !== null && typeof value === "object") {
      if (Array.isArray(value)) {
        deeplyMappedValue = value.map(
          (item) => item !== null && typeof item === "object" ? mapEntriesDeep(item, mapper) : item
        );
      } else {
        deeplyMappedValue = mapEntriesDeep(value, mapper);
      }
    }
    const [mappedKey, mappedValue] = mapper(key, deeplyMappedValue);
    result[mappedKey] = mappedValue;
  }
  return result;
}
function mapKeysDeep(object, keyMapper) {
  return mapEntriesDeep(object, (key, value) => [
    keyMapper(key),
    value
  ]);
}
function mapValuesDeep(object, valueMapper) {
  return mapEntriesDeep(object, (key, value) => [
    key,
    valueMapper(value)
  ]);
}

// src/case-convert-keys.ts
function camelCaseKeys(object, customKeyMappings) {
  return mapKeysDeep(object, (key) => {
    return customKeyMappings?.[key] ?? camelCase(key);
  });
}
function pascalCaseKeys(object, customKeyMappings) {
  return mapKeysDeep(object, (key) => {
    return customKeyMappings?.[key] ?? pascalCase(key);
  });
}

// src/clientCodeMap.ts
var clientCodeMap = {
  HZNATT: "att",
  ATTSolutoMDPP: "att",
  ATT: "att",
  HZNCRCKT: "cricket",
  "5111": "verizon",
  FIOS: "verizon",
  FIOS_IVR: "verizon",
  FIOS_C2CTC: "verizon",
  FIOS_C2CMF: "verizon",
  FIOS_CSR: "verizon",
  Fios: "verizon",
  VERIZON_VPH_FIOS: "verizon",
  FIOS_BIZ_SEC: "verizon",
  FIOS_HNP: "verizon",
  FIOS_UPSELL: "verizon",
  FIOS_VPH: "verizon",
  HZNVZN: "verizon",
  PVL_HZNVZN_SAMEDAY_SOLUTO: "verizon",
  PVL_VEC: "verizon",
  PVL_VZ_TRBLE_SHOOT: "verizon",
  PVL_VZN_CONTACT_TECH_COACH: "verizon",
  PVL_VZN_CONTACT_ONB: "verizon",
  PVL_VERIZON_ONB: "verizon",
  PVL_VERIZON_SMB_SOLUTO: "verizon",
  PVL_VDHP_SelfServe_Save: "verizon",
  PVL_VZ_SMART_DISPLAY: "verizon",
  PVL_HZNVZN_AOLBUNDLE_SOLUTO: "verizon",
  PVL_VZNVPH_WEBSALE: "verizon",
  PVL_ASURION_APPLIANCE_PLUS: "verizon",
  FIOS_SMB: "verizon",
  PVL_HZNVZN_SMARTHOME_SMS_SOLUTO: "verizon",
  PVL_VEC_VZW_TechSure: "verizon",
  PVL_VERIZON_SAVES: "verizon",
  PVL_VZN_TECHTM_C2C: "verizon",
  PVL_VZW_Home_Awareness: "verizon",
  RTLOATHHOME: "verizon",
  "VERIZON 5G": "verizon",
  Verizon_C2C: "verizon",
  VERIZON_COMPANION: "verizon",
  Verizon_CSR: "verizon",
  VERIZON_DR: "verizon",
  VERIZON_ELIPSIS: "verizon",
  Verizon_IVR: "verizon",
  VERIZON_MIM: "verizon",
  VERIZON_ONB: "verizon",
  VERIZON_SHS: "verizon",
  VERIZON_TECHPACK: "verizon",
  Verizon: "verizon",
  VERIZON: "verizon",
  VERIZON5GAPP: "verizon",
  VERIZONSHS5G: "verizon",
  VZ_INNOVATE_LEARNING: "verizon",
  VZN_DR: "verizon",
  VZONB_DG_XP: "verizon",
  VZWPOSTCLAIM: "verizon",
  VZ_SEC_ADV: "verizon",
  VZN_BIZ_BOX: "verizon",
  VERIZON_SMB: "verizon",
  VERIZONMEDIA: "verizon",
  YAHOO: "verizon",
  YAHOO_WH: "verizon"
};

// src/constants.ts
var SalesToolEnv = /* @__PURE__ */ ((SalesToolEnv2) => {
  SalesToolEnv2["Local"] = "http://localhost:4000";
  SalesToolEnv2["Dev"] = "https://sales-tool-git-develop-asurion.vercel.app";
  SalesToolEnv2["Prod"] = "https://www.sales.asurion.com";
  return SalesToolEnv2;
})(SalesToolEnv || {});
var SalesToolEnvMap = {
  dev: "https://sales-tool-git-develop-asurion.vercel.app" /* Dev */,
  prod: "https://www.sales.asurion.com" /* Prod */,
  local: "http://localhost:4000" /* Local */
};
var PRODUCT_SKU_MAP = /* @__PURE__ */ new Map([
  ["asurion", "HOMEPLUS1000"],
  ["att", "HOMEPRO"],
  ["verizon", "3002"],
  ["cricket", "CRICKETHOMEPLUS"]
]);
var Origin = {
  twilioFlex: "twilio-flex",
  salesTool: "sales-tool"
};

// src/contract-testing/nitro-utils.ts
var NITRO_L7_BASE_URL = "https://apigw.sqa.asurionapi.com:8443";
var NitroVariable = {
  $ApiKey$: "$ApiKey$",
  $Authorization$: "$Authorization$",
  $CorrelationId$: "$CorrelationId$",
  $Client$: "$Client$",
  $ClientChannelId$: "$ClientChannelId$",
  $ClientProductSKUNumber$: "$ClientProductSKUNumber$",
  $Mdn$: "$Mdn$",
  $AddressLine1$: "$AddressLine1$",
  $City$: "$City$",
  $State$: "$State$",
  $PostalCode$: "$PostalCode$",
  $Country$: "$Country$",
  $SubscriptionNumber$: "$SubscriptionNumber$",
  $AsurionRouting$: "$AsurionRouting$",
  $LineOfBusiness$: "$LineOfBusiness$",
  $EndUserDomain$: "$EndUserDomain$"
};
var ContractUtils = class _ContractUtils {
  // changes will cause Nitro to invalidate the cache of any contract that uses this
  static generateMdnAndCorrelationId() {
    return {
      description: "Generate a random MDN and correlation ID",
      method: "post",
      url: "https://tas-hc-prod.use1.sqa.aws.asurion.net/api/nitro/v1/run/skip",
      body: {
        mdn: "6newDate(0,yyyySSSss)",
        correlationId: "nitro-newUUID()"
      },
      expect: {
        jsonBody: {
          mdn: "save(" + NitroVariable.$Mdn$ + ")",
          correlationId: "save(" + NitroVariable.$CorrelationId$ + ")"
        }
      }
    };
  }
  // changes (names ignoring-case) will cause Nitro to invalidate the cache of any contract that uses this
  static L7Headers() {
    return {
      "content-type": "application/json",
      "asurion-client": NitroVariable.$Client$,
      "asurion-correlationid": NitroVariable.$CorrelationId$,
      "asurion-apikey": NitroVariable.$ApiKey$,
      authorization: NitroVariable.$Authorization$,
      "asurion-channel": "web",
      "asurion-region": "North America",
      "asurion-routing": NitroVariable.$AsurionRouting$,
      "asurion-enduserdomain": NitroVariable.$EndUserDomain$,
      "asurion-lineofbusiness": NitroVariable.$LineOfBusiness$,
      "accept-language": "en-US",
      accept: "application/json",
      "cache-control": "no-cache",
      "asurion-enduser": "TAS_NITRO"
    };
  }
  static {
    this.consumerKey = process.env.NITRO_CONSUMER_KEY;
  }
  static async sendToNitro(contract) {
    const response = await fetch(
      // we will change to the '/consumer' endpoint once we're ready
      "https://tas-hc-prod.use1.sqa.aws.asurion.net/api/nitro/v1/tryit",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "consumer-key": _ContractUtils.consumerKey
        },
        body: JSON.stringify(contract)
      }
    );
    const jsonResponseBody = JSON.stringify(await response.json(), null, 2);
    if (response.ok) {
      console.log(jsonResponseBody);
    } else {
      throw new Error("Contract failed \u2193\n\n" + jsonResponseBody);
    }
  }
};

// src/country-code.ts
function addCountryCode(mdn, countryCode = "+1") {
  return mdn.startsWith("+") ? mdn : `${countryCode}${mdn}`;
}

// src/format-address.ts
function formatAddress({
  addressLine1 = "",
  addressLine2 = "",
  city = "",
  state = "",
  postalCode = ""
}) {
  const addressParts = [
    `${addressLine1}${addressLine2 ? `, ${addressLine2},` : ","}`,
    city ? `${city},` : "",
    state,
    postalCode
  ];
  const address = addressParts.filter((part) => part).join(" ");
  return address;
}

// src/formatMdn.ts
var formatMdn = (mdn = "") => {
  let cleanMdn = mdn.replace(/[^\d]+/g, "");
  if (cleanMdn.length === 11 && cleanMdn.startsWith("1")) {
    cleanMdn = cleanMdn.slice(1);
  }
  if (cleanMdn && cleanMdn.length <= 3) {
    return cleanMdn;
  }
  return cleanMdn.replace(/(\d{3})/, "($1) ").replace(/(\d{3})(\d{1})/, "$1-$2");
};

// src/get-result.ts
function getResult(promise) {
  return Promise.resolve(promise).then((value) => [null, value]).catch((error) => [error, null]);
}
function isResolved(result) {
  return result[0] === null;
}
function isRejected(result) {
  return !isResolved(result);
}
function assertResolved(result, message) {
  if (isRejected(result)) {
    throw message ? new Error(message) : result[0];
  }
}
function assertRejected(result, message) {
  if (isResolved(result)) {
    throw new Error(message);
  }
}

// src/isObject.ts
var isObject = (value) => typeof value === "object" && value != null && !Array.isArray(value);

// src/map-utils.ts
function emplace(map, key, handler) {
  const value = map.has(key) && "update" in handler ? handler.update(map.get(key), key, map) : handler.insert(key, map);
  map.set(key, value);
  return value;
}
function computeIfPresent(map, key, mapper) {
  if (map.has(key)) {
    const value = mapper(map.get(key), key);
    map.set(key, value);
    return value;
  }
}

// src/match.ts
var DefaultCase = Symbol("DefaultCase");
function match(value, ...cases) {
  for (const [predicate, mapper2] of cases) {
    if (value === predicate) {
      return typeof mapper2 === "function" ? mapper2(value) : mapper2;
    }
  }
  const defaultCase = cases.find(([predicate]) => predicate === DefaultCase);
  if (!defaultCase) {
    throw new Error("No matching case found");
  }
  const [, mapper] = defaultCase;
  return typeof mapper === "function" ? mapper(value) : mapper;
}

// src/object-utils.ts
function groupBy(iterable, callback) {
  const result = {};
  for (const item of iterable) {
    const key = callback(item);
    if (result[key] === void 0) {
      result[key] = [];
    }
    result[key].push(item);
  }
  return result;
}

// src/only-one-property-exists.ts
function onlyOnePropertyExists(obj) {
  let count = 0;
  for (const key in obj) {
    if (obj[key] !== void 0) {
      count++;
      if (count > 1) {
        return false;
      }
    }
  }
  return true;
}

// src/pickBy.ts
function pickBy(obj, predicate) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => predicate(value))
  );
}

// src/pickDefined.ts
function pickDefined(object) {
  return pickBy(
    object,
    (value) => value != null
  );
}

// src/promise-utils.ts
function isPromiseFulfilled(value) {
  return value.status === "fulfilled";
}
function isPromiseRejected(value) {
  return value.status === "rejected";
}

// src/random.ts
function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

// src/redactedFsEvent.ts
import { event, isInitialized } from "@fullstory/browser";
import { redactPii } from "@soluto-private/pii-redaction";
var redactedFsEvent = (analyticEventName, analyticEvent) => {
  if (isInitialized()) {
    event(
      analyticEventName,
      redactPii(analyticEvent)
    );
  }
};

// src/safe-json-parse.ts
function safeJsonParse(body) {
  try {
    return JSON.parse(body);
  } catch (error) {
    return body;
  }
}

// src/string-utils.ts
function capitalizeString(stringToCapitalize) {
  return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
}
function kebabCase(value) {
  return value.replace(/([a-z])([A-Z])/g, "$1-$2").replace(/\s+/g, "-").toLowerCase();
}
function formatListGrammaticallyI18n(lang, list) {
  if (typeof lang === "string") {
    if (lang === "en" || lang === "en-US") {
      return formatListGrammaticallyEN(list);
    }
    if (lang === "es" || lang === "es-US") {
      return formatListGrammaticallyES(list);
    }
  }
  if (Array.isArray(lang)) {
    for (const locale of lang) {
      const result = formatListGrammaticallyI18n(locale, list);
      if (result) {
        return result;
      }
    }
  }
}
function formatListGrammaticallyEN(list) {
  if (list.length === 1) {
    return list[0];
  }
  if (list.length === 2) {
    return list.join(" and ");
  }
  if (list.length > 2) {
    return `${[
      [...list].splice(0, list.length - 1).join(", "),
      list[list.length - 1]
    ].join(", and ")}`;
  }
}
function formatListGrammaticallyES(list) {
  if (list.length === 1) {
    return list[0];
  }
  if (list.length === 2) {
    return list.join(" y ");
  }
  if (list.length > 2) {
    return `${[
      [...list].splice(0, list.length - 1).join(", "),
      list[list.length - 1]
    ].join(" y ")}`;
  }
}

// src/timers.ts
function wait(delay, { signal } = {}) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(resolve, delay);
    if (signal) {
      signal.addEventListener("abort", () => {
        clearTimeout(timeout);
        const abortError = new Error("This operation was aborted");
        abortError.name = "AbortError";
        reject(abortError);
      });
    }
  });
}
export {
  ContractUtils,
  DefaultCase,
  NITRO_L7_BASE_URL,
  NitroVariable,
  Origin,
  PRODUCT_SKU_MAP,
  SalesToolEnv,
  SalesToolEnvMap,
  addCountryCode,
  asArray,
  assertRejected,
  assertResolved,
  camelCaseKeys,
  capitalizeString,
  clientCodeMap,
  computeIfPresent,
  emplace,
  formatAddress,
  formatListGrammaticallyEN,
  formatListGrammaticallyES,
  formatListGrammaticallyI18n,
  formatMdn,
  getResult,
  groupBy,
  isAsyncIterable,
  isNonEmptyArray,
  isObject,
  isPromiseFulfilled,
  isPromiseRejected,
  isRejected,
  isResolved,
  kebabCase,
  mapEntriesDeep,
  mapKeysDeep,
  mapValuesDeep,
  match,
  onlyOnePropertyExists,
  pascalCaseKeys,
  pickBy,
  pickDefined,
  randomBetween,
  redactedFsEvent,
  safeJsonParse,
  toAsyncIterableIterator,
  wait
};
