import { useCallback } from 'react';
import { useAnalytics } from './useAnalytics';

export function useAnalyticEvent<T extends any[]>(
  eventName: string,
  handler?: (...params: T) => void,
) {
  const analytics = useAnalytics();

  return useCallback(
    (...args: T) => {
      analytics.track(eventName, {});
      return handler?.(...args);
    },
    [analytics, eventName, handler],
  );
}
