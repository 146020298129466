import { EventProperties } from '@soluto-private/analytics-core';
import {
  useCustomerSession,
  useProductOffer,
} from '@soluto-private/asp-react-core';
import { useMemo } from 'react';
import { defineSalesData } from '../utils/analytics';

export type SalesDataParams = {
  customerSessionId?: string;
  productOfferId?: string;
};

export function useSalesData({
  customerSessionId,
  productOfferId,
}: SalesDataParams): EventProperties['ExtraData'] | undefined {
  const [{ data: productOffer }] = useProductOffer({
    productOfferId: productOfferId,
    includeProductEligibility: true,
    includeProductEnrollment: true,
  });
  const [{ data: customerSession }] = useCustomerSession({
    customerSessionId: customerSessionId,
    includeCustomer: true,
  });

  const analyticsData = useMemo(
    () =>
      defineSalesData({
        customerSession: customerSession ?? undefined,
        productOffer: productOffer ?? undefined,
      }),
    [customerSession, productOffer],
  );

  if (!customerSession && !productOffer) {
    return undefined;
  }

  return analyticsData;
}
