import { CenteredLoadingSpinner } from '@/components/CenteredLoadingSpinner/CenteredLoadingSpinner';
import { CustomerAutoPopHandler } from '@/components/CustomerAutoPopHandler/CustomerAutoPopHandler';
import FailurePage from '@/components/FailurePage';
import { InitializePlugins } from '@/components/InitializePlugins/InitializePlugins';
import { SoCoEventHandler } from '@/components/SoCoEventHandler/SoCoEventHandler';
import { TrackRefresh } from '@/components/TrackRefresh/TrackRefresh';
import { useEventBridge } from '@/hooks/useEventBridge';
import { FlexDataProvider } from '@/providers/FlexData';
import { analytics } from '@/utils/analytics';
import { ASPEventBridge } from '@/utils/aspEventBridge';
import { BugSnagErrorBoundary } from '@/utils/bugsnagUtils';
import { getUrlData } from '@/utils/urlData';
import { getCurrentSessionURL, isInitialized } from '@fullstory/browser';
import { AnalyticsProvider } from '@sales-domain/analytics-react';
import { WorkerAttributesEvent } from '@soluto-private/bridge';
import { GlobalStyles } from '@soluto-private/mx-asurion-ui-react';
import type { NextPage } from 'next';
import { SessionProvider as AuthSessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/navigation';
import Script from 'next/script';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { withUrql } from '../graphql/browserClient';
import '../styles/globals.css';

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('../mocks');
}

type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({
  Component,
  pageProps: { session: authSession, ...pageProps },
}: AppPropsWithLayout) {
  const [workerAttributes, setWorkerAttributes] = useState<
    Record<string, any> | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const embedded =
      typeof window !== 'undefined' && window.self !== window.parent;

    const intervalId = setInterval(
      () => {
        const shouldPoll =
          embedded &&
          (workerAttributes?.empId == null || workerAttributes?.lob == null);

        if (shouldPoll) {
          ASPEventBridge.getInstance().sendToHostApp(
            new WorkerAttributesEvent({}),
          );
        } else {
          setLoading(false);
          clearInterval(intervalId);
        }
      },
      embedded ? 2000 : 0, // If the app is in standalone mode, we don't need to delay the loading
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [workerAttributes, workerAttributes?.empId, workerAttributes?.lob]);

  const handleWorkerAttributes = useCallback(
    async (event: WorkerAttributesEvent) => {
      const { attributes } = event;

      const analyticEvent = {
        Name: 'WorkerAttributes_Handled',
        Scope: 'expert-sales-tool',
        ExtraData: {
          ...getUrlData(),
          FullstorySession: isInitialized()
            ? getCurrentSessionURL()
            : undefined,
          WorkerAttributes: attributes,
        },
        MetaData: {
          SourceApp: 'sales-tool',
          Client: 'asurion',
        },
        Identities: {
          UserId: attributes?.empId ?? crypto.randomUUID(),
          Email: attributes?.email ?? crypto.randomUUID(),
        },
      };

      analytics.track(analyticEvent.Name, analyticEvent);

      setWorkerAttributes(attributes);
    },
    [],
  );

  const nextjs14URL = process.env.NEXT_PUBLIC_NEXTJS14_SALES_TOOL_URL;
  const isIncludedInNextjs14TestGroup =
    process.env.NEXT_PUBLIC_NEXTJS14_EXPERT_IDS?.split(',')?.includes(
      workerAttributes?.empId,
    );
  useLayoutEffect(() => {
    if (isIncludedInNextjs14TestGroup && nextjs14URL) {
      router.replace(nextjs14URL);
      console.log('----- Using the Next.js 14 version of sales-tool -------');
    }
  }, [isIncludedInNextjs14TestGroup, nextjs14URL, router]);

  const isSocoEventHandlerEnabled =
    process.env.NEXT_PUBLIC_FEAT_SELL_1992 === 'true' &&
    workerAttributes?.lob === 'soluto';

  useEventBridge({ handleWorkerAttributes });

  return (
    <>
      {loading ? (
        <CenteredLoadingSpinner />
      ) : (
        <BugSnagErrorBoundary FallbackComponent={FailurePage}>
          {process.env.NEXT_PUBLIC_STAGE === 'prod' && (
            <Script
              type="text/javascript"
              src="https://js-cdn.dynatrace.com/jstag/165658ccba3/bf39993uwd/ffc3dbcab99a44a6_complete.js"
              crossOrigin="anonymous"
            />
          )}
          <Script
            src={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API}
            strategy="afterInteractive"
          />
          <Head>
            <title>Asurion Sales Platform</title>
          </Head>
          <AuthSessionProvider session={authSession} refetchInterval={60 * 55}>
            <AnalyticsProvider instance={analytics}>
              <FlexDataProvider
                workerAttributes={workerAttributes}
                isSocoEventHandlerEnabled={isSocoEventHandlerEnabled}
              >
                {isSocoEventHandlerEnabled ? (
                  <SoCoEventHandler />
                ) : (
                  <CustomerAutoPopHandler />
                )}
                <GlobalStyles />
                <InitializePlugins />
                {/* <SalesToolReadyHandler /> */}
                <TrackRefresh />
                {getLayout(<Component {...pageProps} />)}
              </FlexDataProvider>
            </AnalyticsProvider>
          </AuthSessionProvider>
        </BugSnagErrorBoundary>
      )}
    </>
  );
}

export default withUrql(App);
