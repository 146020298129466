import { AnalyticsInstance } from '@soluto-private/analytics-core';
import { createContext } from 'react';

const AnalyticsContext = createContext<AnalyticsInstance>({
  identify: (_userId: string): Promise<void> => Promise.resolve(),
  track: (
    _eventName: string,
    _properties: Record<string, any>,
  ): Promise<void> => Promise.resolve(),
});

export default AnalyticsContext;
