import { useEventBridge } from '@/hooks/useEventBridge';
import { useFlexData } from '@/providers/FlexData';
import { computePathWithQuery } from '@/utils/path';
import { getUrlData } from '@/utils/urlData';
import { getCurrentSessionURL, isInitialized } from '@fullstory/browser';
import { defineSalesData, useAnalytics } from '@sales-domain/analytics-react';
import { getCustomerSessionPartnerForProductSKU } from '@sales-domain/product-config';
import {
  CallDirection,
  CustomerDocument,
  CustomerSessionCreatedDocument,
  UpdateExpertOrgChartDataDocument,
  assertCreateCustomerSessionSuccessPayload,
} from '@soluto-private/asp-core';
import {
  useExpertSession,
  useSalesConfig,
  useStartCustomerSession,
} from '@soluto-private/asp-react-core';
import {
  CustomerSessionStartedEvent,
  UpdateExpertSessionEvent,
} from '@soluto-private/bridge';
import { invariant, warning } from '@soluto-private/invariant';
import {
  Origin,
  addCountryCode,
  clientCodeMap,
  redactedFsEvent,
} from '@soluto-private/utils';
import { useSession } from 'next-auth/react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useClient, useMutation } from 'urql';
import { OutboundCallModal } from '../OutboundCallModal/OutboundCallModal';
import { handleExternalCustomerSessionFactory } from './utils/handleExternalCustomerSessionFactory';

export type OutboundCallData = {
  mdn: string;
  partner?: string;
  asurionCallId?: string;
  flexReservationId?: string;
  externalSessionId?: string;
  channel?: string;
  language?: string;
  direction?: string;
};

export function CustomerAutoPopHandler() {
  const [handledEvent, setHandledEvent] = useState(false);
  const [, startCustomerSession] = useStartCustomerSession();
  const [{ data: salesConfig }] = useSalesConfig({
    applicationName: 'ExpertSalesTool',
    configName: 'ivr-client-config',
  });
  const { data: session, status } = useSession();
  const { flexPartner } = useFlexData();
  const analytics = useAnalytics();
  const client = useClient();
  const router = useRouter();
  const searchParams = useSearchParams();
  const path = usePathname();
  const [{ data: expertSession }] = useExpertSession({
    expertSessionId: session?.user?.expertSessionId!,
    includeExpert: true,
  });
  const [, updateExpert] = useMutation(UpdateExpertOrgChartDataDocument);
  const [outboundCallData, setOutboundCallData] =
    useState<OutboundCallData | null>();

  const productSKUs = useMemo(
    () =>
      new Map(
        session?.user?.products?.map((productSKU) => [
          productSKU,
          getCustomerSessionPartnerForProductSKU(productSKU).toLowerCase(),
        ]),
      ),
    [session?.user?.products],
  );

  const startNewCustomerSession = useCallback(
    async (data: CustomerSessionStartedEvent | OutboundCallData) => {
      let {
        mdn,
        partner,
        asurionCallId,
        externalSessionId,
        direction,
        language,
      } = data;
      if (!partner) {
        warning(false, 'partner not available in event');

        if (flexPartner) {
          partner = flexPartner;
        } else if (session?.user?.partners.length === 1) {
          partner = session.user.partners[0];
        } else {
          // As no partner was specified in the event, no previous inbound calls have been received from flex
          // and the expert doesn't have a single partner on their session, a choice cannot be made.
          const analyticEvent = {
            Name: 'NoPartner_Received',
            Scope: 'expert-sales-tool',
            ExtraData: {
              ...getUrlData(),
              Scope: 'expert-sales-tool',
              FullstorySession: isInitialized()
                ? getCurrentSessionURL()
                : undefined,
              CustomerInformation: {
                ExternalSessionId: externalSessionId,
                AsurionCallId: asurionCallId,
              },
              Partner: partner,
              CarrierName: partner,
            },
            MetaData: {
              SourceApp: 'sales-tool',
              Client: partner,
            },
            Identities: {
              UserId: session?.user?.expertId,
              Email: session?.user?.email,
              ExpertSessionId: session?.user?.expertSessionId,
            },
          };
          analytics.track(analyticEvent.Name, analyticEvent);
          redactedFsEvent(analyticEvent.Name, analyticEvent);

          return;
        }
      }
      // We have observed in data that the partner value coming in has many
      // different casings and it is causing CustomerSessions to be created
      // without ProductOffers
      partner = partner.toLowerCase();
      const {
        customerSession: { data: customerSessionData },
      } = await startCustomerSession({
        expertSessionId: session?.user?.expertSessionId,
        partner,
        customer: {
          mdn,
        },
        asurionCall: asurionCallId
          ? {
              id: asurionCallId,
              flexReservation: externalSessionId
                ? {
                    id: externalSessionId,
                  }
                : undefined,
            }
          : undefined,
        productSKUs,
        origin: Origin.twilioFlex,
        direction: direction?.toUpperCase() as CallDirection,
        language,
      });

      assertCreateCustomerSessionSuccessPayload(customerSessionData);

      const { customerSession } = customerSessionData.createCustomerSession;

      router.push(
        computePathWithQuery('/product-menu', null, {
          csid: customerSession.id,
        }),
      );
    },
    [
      startCustomerSession,
      session?.user?.expertSessionId,
      session?.user?.partners,
      session?.user?.expertId,
      session?.user?.email,
      productSKUs,
      router,
      flexPartner,
      analytics,
    ],
  );

  const handleExternalCustomerSessionCreatedRef = useRef(
    handleExternalCustomerSessionFactory(router, session, analytics),
  );

  const handleUpdateExpertSession = useCallback(
    async (event: UpdateExpertSessionEvent) => {
      warning(
        session?.user != null,
        'user not defined on session when handling UpdateExpertSessionEvent',
      );

      const formattedLOB = event.lob ? event.lob.toLowerCase() : undefined;
      if (
        session?.user &&
        formattedLOB &&
        expertSession?.expert?.lob !== formattedLOB
      ) {
        updateExpert({
          id: session.user.expertId,
          expertSessionId: session.user.expertSessionId,
          lob: formattedLOB,
        });
      }
    },
    [expertSession?.expert?.lob, session?.user, updateExpert],
  );

  const handleCustomerSessionStarted = useCallback(
    async (event: CustomerSessionStartedEvent) => {
      //Close outbound call modal if inbound call is received
      if (event.direction === 'inbound' && outboundCallData != null) {
        setOutboundCallData(null);
      }

      warning(
        session?.user != null,
        'user not defined on session when handling CustomerSessionStartedEvent',
      );

      if (
        session?.user &&
        event.channel != null &&
        expertSession?.expert?.lob !== event.channel
      ) {
        updateExpert({
          id: session.user.expertId,
          expertSessionId: session.user.expertSessionId,
          lob: event.channel,
        });
      }
      //when inbound call is mobility/protection, we should use the customer session created subscription to start the customer session instead of the flex payload.
      if (
        event.channel === 'mobility' &&
        event.direction === 'inbound' &&
        process.env.NEXT_PUBLIC_PROTECTION_ENABLED === 'true'
      ) {
        return;
      }
      // The value of event.partner is the ivr client and not the customer session partner.
      // For outbound calls, event.partner is undefined. In that case we will not check for partner in clientCodeMap
      let partner = event.partner
        ? !salesConfig
          ? clientCodeMap[event.partner]
          : salesConfig[event.partner]
        : undefined;

      // Handles Flex v1 scenario where mapped partner is passed to the frontend
      if (!partner && event.partner) {
        partner = event.partner;
      }

      warning(
        partner != null,
        'partner not defined for the ivr client in the clientCodeMap',
      );

      const analyticEvent = {
        Name:
          event.direction === 'inbound'
            ? 'CustomerInformation_Received'
            : 'OutboundCall_Received',
        Scope: 'expert-sales-tool',
        ExtraData: {
          ...getUrlData(),
          Scope: 'expert-sales-tool',
          FullstorySession: isInitialized()
            ? getCurrentSessionURL()
            : undefined,
          CustomerInformation: {
            Partner: partner,
            ExternalSessionId: event.externalSessionId,
            CallDirection: event.direction,
            AsurionCallId: event.asurionCallId,
          },
          Partner: partner,
          CarrierName: partner,
        },
        MetaData: {
          SourceApp: 'sales-tool',
          Client: partner,
        },
        Identities: {
          UserId: session?.user?.expertId,
          Email: session?.user?.email,
          ExpertSessionId: session?.user?.expertSessionId,
        },
      };
      analytics.track(analyticEvent.Name, analyticEvent);
      redactedFsEvent(analyticEvent.Name, analyticEvent);

      const csid = searchParams?.get('csid') as string | undefined;
      // we are using client instead of the useCustomer hook because we want to be
      // able to handle auto pop logic when there is no customerSessionProvider
      if (csid != null) {
        const customer = await client
          .query(CustomerDocument, {
            customerSessionId: csid,
          })
          .toPromise();

        if (
          customer.data?.customerSession?.customer?.mdn ===
            addCountryCode(event.mdn) &&
          event.direction === 'inbound'
        ) {
          const { Customer, CustomerSession } = defineSalesData({
            customerSession: customer.data.customerSession,
          });
          const analyticEvent = {
            Name: 'IdenticalCustomerInformation_Received',
            Scope: 'expert-sales-tool',
            ExtraData: {
              ...getUrlData(),
              Scope: 'expert-sales-tool',
              FullstorySession: isInitialized()
                ? getCurrentSessionURL()
                : undefined,
              CarrierName: partner,
              Partner: partner,
              CustomerInformation: {
                Partner: partner,
                ExternalSessionId: event.externalSessionId,
                CallDirection: event.direction,
              },
              Customer,
              CustomerSession,
            },
            MetaData: {
              SourceApp: 'sales-tool',
              Client: partner,
            },
            Identities: {
              UserId: session?.user?.expertId,
              Email: session?.user?.email,
              ExpertSessionId: session?.user?.expertSessionId,
              CustomerSessionId: csid,
            },
          };
          analytics.track(analyticEvent.Name, analyticEvent);
          redactedFsEvent(analyticEvent.Name, analyticEvent);

          return;
        }
      }

      if (status !== 'authenticated') {
        const callbackUrlParam = searchParams?.get('callbackUrl');
        invariant(
          !Array.isArray(callbackUrlParam),
          'callbackUrl cannot be an array',
        );

        const callbackUrl = new URL(
          callbackUrlParam ?? '/',
          window.location.origin,
        );
        callbackUrl.searchParams.set(
          'customerSessionStartedEvent',
          JSON.stringify({ ...event, partner }),
        );
        router.replace(
          computePathWithQuery(path, searchParams, {
            callbackUrl: callbackUrl.toString(),
          }),
        );

        return;
      }

      if (event.direction === 'outbound') {
        setOutboundCallData({
          mdn: event.mdn,
          partner: partner,
          flexReservationId: event.externalSessionId,
          asurionCallId: event.asurionCallId,
        });
      } else {
        startNewCustomerSession({ ...event, partner });
      }
    },
    [
      outboundCallData,
      session?.user,
      expertSession?.expert?.lob,
      salesConfig,
      analytics,
      router,
      status,
      updateExpert,
      client,
      startNewCustomerSession,
      path,
      searchParams,
    ],
  );

  useEventBridge({
    handleCustomerSessionStarted,
    handleUpdateExpertSession,
  });

  useEffect(() => {
    handleExternalCustomerSessionCreatedRef.current =
      handleExternalCustomerSessionFactory(router, session, analytics);
  }, [analytics, router, session]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_PROTECTION_ENABLED === 'true' &&
      session?.user?.expertSessionId
    ) {
      const { unsubscribe } = client
        .subscription(CustomerSessionCreatedDocument, {
          expertSessionId: session.user.expertSessionId,
        })
        .subscribe((result) => {
          if (result.data?.customerSessionCreated?.horizonSession?.id)
            handleExternalCustomerSessionCreatedRef.current(
              result.data?.customerSessionCreated,
            );
        });
      return () => unsubscribe();
    }
  }, [client, session?.user?.expertSessionId]);

  useEffect(() => {
    const customerSessionStartedEvent = searchParams?.get(
      'customerSessionStartedEvent',
    );
    if (
      typeof customerSessionStartedEvent === 'string' &&
      status === 'authenticated' &&
      !handledEvent
    ) {
      const event = JSON.parse(customerSessionStartedEvent);
      setHandledEvent(true);

      handleCustomerSessionStarted(event);
    }
  }, [
    handleCustomerSessionStarted,
    handledEvent,
    router,
    searchParams,
    status,
  ]);

  if (outboundCallData != null) {
    return (
      <OutboundCallModal
        startNewCustomerSession={() => {
          startNewCustomerSession(outboundCallData);
        }}
        outBoundCallData={outboundCallData}
        setOutboundCallData={setOutboundCallData}
      />
    );
  } else {
    return null;
  }
}
