import { useContext } from 'react';
import { AnalyticsContext } from '../../src/components';

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

export const useIdentify = () => {
  return useContext(AnalyticsContext).identify;
};

export const useTrack = () => {
  return useContext(AnalyticsContext).track;
};
