import {
  BaseErrorFragment,
  isUpdateProductEligibilitySuccessPayload,
  NotFoundErrorFragment,
  ProductEligibilityFragment,
  ProductEligibilityStatus,
} from '@soluto-private/asp-core';
import { useUpdateProductEligibility } from '@soluto-private/asp-react-core';
import { Button, ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import { MouseEvent } from 'react';
import { CombinedError } from 'urql';

export interface UpdateProductEligibilityButtonProps extends ButtonProps {
  onProductEligibilityUpdated?(
    productEligibility: ProductEligibilityFragment,
  ): void;
  onBaseError?(error: BaseErrorFragment): void;
  onNotFoundError?(error: NotFoundErrorFragment): void;
  onGraphQLError?(error: CombinedError): void;
  status: ProductEligibilityStatus;
  reason?: string;
}

export function UpdateProductEligibilityButton({
  onProductEligibilityUpdated,
  onBaseError,
  onNotFoundError,
  onGraphQLError,
  onClick,
  status,
  reason,
  children,
  disabled,
  ...rest
}: UpdateProductEligibilityButtonProps) {
  const [{ fetching }, updateProductEligibility] =
    useUpdateProductEligibility();

  async function handleClick(event: MouseEvent<HTMLButtonElement>) {
    onClick?.(event);

    const { data, error } = await updateProductEligibility({ status, reason });

    if (error != null) {
      onGraphQLError?.(error);
    }

    if (isUpdateProductEligibilitySuccessPayload(data)) {
      onProductEligibilityUpdated?.(
        data.updateProductEligibility.productEligibility,
      );
    } else if (data?.updateProductEligibility.__typename === 'BaseError') {
      onBaseError?.(data.updateProductEligibility);
    } else if (data?.updateProductEligibility.__typename === 'NotFoundError') {
      onNotFoundError?.(data.updateProductEligibility);
    }
  }

  return (
    <Button disabled={fetching || disabled} onClick={handleClick} {...rest}>
      {children ?? 'Update product eligibility'}
    </Button>
  );
}
