import { getUrlData } from '@/utils/urlData';
import { getCurrentSessionURL, isInitialized } from '@fullstory/browser';
import { useAnalytics, useSalesData } from '@sales-domain/analytics-react';
import { useExpertSession } from '@soluto-private/asp-react-core';
import { redactedFsEvent } from '@soluto-private/utils';
import { useSession } from 'next-auth/react';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

export function TrackRefresh() {
  const router = useRouter();
  const { data: session, status } = useSession();
  const searchParams = useSearchParams();
  const csid = searchParams?.get('csid');
  const poid = searchParams?.get('poid');
  const salesData = useSalesData({
    customerSessionId: csid as string | undefined,
    productOfferId: poid as string | undefined,
  });
  const [{ data: expertSession }] = useExpertSession({
    expertSessionId: session?.user?.expertSessionId,
  });
  const analytics = useAnalytics();

  useEffect(() => {
    function handleBeforeUnload() {
      if (status !== 'loading' && session) {
        const analyticEvent = {
          Name: 'App_Refreshed',
          Scope: 'expert-sales-tool',
          ExtraData: {
            ...getUrlData(),
            Scope: 'expert-sales-tool',
            FullstorySession: isInitialized()
              ? getCurrentSessionURL()
              : undefined,
            CustomerSession: salesData?.CustomerSession,
            ProductOffer: salesData?.ProductOffer,
            Partner: salesData?.CustomerSession?.partner,
            CarrierName: salesData?.CustomerSession?.partner,
            Product: {
              SKU: salesData?.ProductOffer?.productSku,
            },
          },
          MetaData: {
            HostApp: expertSession?.hostApp ?? undefined,
            SourceApp: 'sales-tool',
            Client: salesData?.CustomerSession?.partner,
          },
          Identities: {
            UserId: session?.user?.expertId,
            Email: session?.user?.email,
            ExpertSessionId:
              expertSession?.id ?? session?.user?.expertSessionId,
            ProductOfferId: salesData?.ProductOffer?.id ?? poid,
            CustomerSessionId: salesData?.CustomerSession?.id ?? csid,
          },
        };
        analytics.track(analyticEvent.Name, analyticEvent);
        redactedFsEvent(analyticEvent.Name, analyticEvent);
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [analytics, csid, expertSession, poid, salesData, session, status]);

  return null;
}
