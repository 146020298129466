import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Session } from 'next-auth';
import React from 'react';
import pkg from '../../package.json';

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
  appVersion: pkg.version,
  plugins: [new BugsnagPluginReact()],
  releaseStage:
    process.env.NEXT_PUBLIC_STAGE! === 'prod' ? 'production' : 'development',
});

/**
 * Set the user in Bugsnag when the user logs in.
 *
 * @param user The user object from the session.
 */
export function setBugsnagUser(user: Session['user']) {
  Bugsnag.setUser(
    user?.expertId,
    user?.email ?? undefined,
    user?.name ?? undefined,
  );
}

export function notifyBugsnag(error: Error) {
  Bugsnag.notify(error);
}

export const BugSnagErrorBoundary =
  Bugsnag.getPlugin('react')!.createErrorBoundary(React);
