import { analytics } from '@/utils/analytics';
import { getUrlData } from '@/utils/urlData';
import Bugsnag from '@bugsnag/js';
import { getCurrentSessionURL, isInitialized } from '@fullstory/browser';
import { invariant } from '@soluto-private/invariant';
import { redactedFsEvent } from '@soluto-private/utils';
import { devtoolsExchange } from '@urql/devtools';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { retryExchange } from '@urql/exchange-retry';
import { DocumentNode, GraphQLError, Kind } from 'graphql';
import { createClient as createWSClient } from 'graphql-ws';
import { getSession } from 'next-auth/react';
import { withUrqlClient } from 'next-urql';
import {
  CombinedError,
  Exchange,
  Operation,
  cacheExchange,
  fetchExchange,
  makeOperation,
  mapExchange,
  subscriptionExchange,
} from 'urql';

const isUrqlClientLoggingEnabled =
  process.env.NEXT_PUBLIC_ENABLE_URQL_CLIENT_LOGGING?.toLowerCase() === 'true';

export const withUrql = withUrqlClient(
  (ssrExchange) => {
    const exchanges: Exchange[] = [
      devtoolsExchange,
      requestPolicyExchange({}),
      cacheExchange,
      ssrExchange,
      mapExchange({
        onOperation(operation) {
          const fetchOptions = getFetchOptions(operation);

          const updatedOperation = makeOperation(operation.kind, operation, {
            ...operation.context,
            retryAttempts: 0,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                'Asurion-Source-App': 'sales-tool',
                'X-Correlation-ID': crypto.randomUUID(),
              },
            },
          });

          if (isUrqlClientLoggingEnabled) {
            sendAnalyticsEvent({
              name: 'GraphQLOperation',
              operation: updatedOperation,
            });
          }

          return updatedOperation;
        },
        onResult(result) {
          if (isUrqlClientLoggingEnabled || result.error != null) {
            sendAnalyticsEvent({
              name:
                result.error != null
                  ? 'GraphQLOperationError'
                  : 'GraphQLOperationResult',
              operation: result.operation,
              combinedError: result.error,
              extraData: {
                Typename: result.data?.__typename,
              },
            });
          }
        },
      }),
      retryExchange({
        retryIf: (error, operation) =>
          error.networkError != null || operation.kind === 'query',
        retryWith: (error, operation) => {
          const { graphQLErrors, networkError } = error;
          const retriedErrors = [
            ...(operation.context.retriedErrors ?? []),
            ...graphQLErrors,
            ...(networkError ? [networkError] : []),
          ];

          return {
            ...operation,
            context: {
              ...operation.context,
              retryAttempts: operation.context.retryAttempts + 1,
              retriedErrors,
            },
          };
        },
        maxNumberAttempts: 3,
      }),
      fetchExchange,
    ];

    if (typeof window !== 'undefined') {
      let activeSocket: WebSocket | undefined, timedOut: NodeJS.Timeout;

      const wsClient = createWSClient({
        url: process.env.NEXT_PUBLIC_WEBSOCKET_CLIENT_URL!,
        keepAlive: 60_000,
        connectionParams: async () => {
          const response = await fetch('/api/token');
          if (!response.ok) {
            throw new Error('Unauthenticated');
          }
          const { token } = await response.json();
          return { token };
        },
        on: {
          connected: (socket) => {
            invariant(socket instanceof WebSocket, 'socket is not a WebSocket');
            activeSocket = socket;
          },
          ping: (received) => {
            if (!received)
              // sent
              timedOut = setTimeout(() => {
                if (activeSocket?.readyState === WebSocket.OPEN)
                  activeSocket.close(4408, 'Request Timeout');
              }, 5_000); // wait 5 seconds for the pong and then close the connection
          },
          pong: (received) => {
            if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
          },
        },
        lazyCloseTimeout: 1000 * 60 * 5, // 5 minutes
      });

      const subExchange = subscriptionExchange({
        forwardSubscription(request) {
          const input = { ...request, query: request.query ?? '' };
          return {
            subscribe(sink) {
              const unsubscribe = wsClient.subscribe(input, sink);
              return { unsubscribe };
            },
          };
        },
      });

      exchanges.push(subExchange);
    }
    return {
      url: '/api/graphql',
      exchanges,
    };
  },
  { ssr: false, staleWhileRevalidate: true },
);

function getFetchOptions(operation: Operation) {
  return typeof operation.context?.fetchOptions === 'function'
    ? operation.context.fetchOptions()
    : operation.context?.fetchOptions ?? {};
}

async function sendAnalyticsEvent({
  name,
  operation,
  extraData = {},
  combinedError,
}: {
  name: string;
  operation: Operation;
  extraData?: Record<string, any>;
  combinedError?: CombinedError;
}) {
  const session = await getSession();
  const fetchOptions = getFetchOptions(operation);
  const headers = new Headers(fetchOptions.headers);

  const partner = headers.get('Asurion-Partner');
  const sourceApp = headers.get('Asurion-Source-App');
  const correlationId = headers.get('X-Correlation-ID');

  const { graphQLErrors = [], networkError } = combinedError ?? {};
  const allErrors = [...graphQLErrors, ...(networkError ? [networkError] : [])];
  const urlData = getUrlData();

  const analyticEvent = {
    Name: name,
    Scope: 'expert-sales-tool',
    ExtraData: {
      ...urlData,
      FullstorySession:
        typeof window !== 'undefined' && isInitialized()
          ? getCurrentSessionURL()
          : undefined,
      Operation: getOperationType(operation.query),
      QueryName: getOperationName(operation.query),
      CarrierName: partner,
      Partner: partner,
      RetryAttempts: operation.context.retryAttempts,
      ...extraData,
      ...(allErrors.length > 0
        ? {
            Errors: allErrors.map((error) => ({
              Error: error,
              ErrorType: error.name,
            })),
          }
        : {}),
      ...(operation.context.retriedErrors
        ? { RetriedErrors: operation.context.retriedErrors }
        : {}),
    },
    Identities: {
      UserId: session?.user?.expertId,
      Email: session?.user?.email,
      ExpertSessionId: session?.user?.expertSessionId,
      CustomerSessionId: urlData['QPcsid'],
      ProductOfferId: urlData['QPpoid'],
      CorrelationId: correlationId,
    },
    MetaData: {
      SourceApp: sourceApp ?? 'sales-tool',
      Client: partner,
    },
  };

  // Send event to /api/analytics/track
  await analytics.track(analyticEvent.Name, analyticEvent);

  // Send to Fullstory
  if (typeof window !== 'undefined') {
    redactedFsEvent(analyticEvent.Name, analyticEvent);
  }

  // Send all errors to Bugsnag
  if (Bugsnag.isStarted()) {
    for (const error of allErrors) {
      let isInfoSeverity = false;
      if (error instanceof GraphQLError) {
        const status = (error.extensions.http as any)?.status;
        if (status && status >= 400 && status < 500) {
          isInfoSeverity = true;
        }
      }

      Bugsnag.notify(error, (event) => {
        event.severity = isInfoSeverity ? 'info' : 'error';
        event.addMetadata('errorContext', analyticEvent);
      });
    }
  }
}

function getOperationName(query: DocumentNode): string | undefined {
  for (const node of query.definitions) {
    if (node.kind === Kind.OPERATION_DEFINITION && node.name) {
      return node.name.value;
    }
  }
}

function getOperationType(query: DocumentNode): string | undefined {
  for (const node of query.definitions) {
    if (node.kind === Kind.OPERATION_DEFINITION) {
      return node.operation;
    }
  }
}
