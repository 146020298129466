import {
  EventBridge,
  EventBridgeEventMap,
  exposeEventBridge,
  RemoteEventBridge,
  wrapGlowSDK,
} from '@soluto-private/bridge';

/**
 * Event bridge implementation for ASP.
 *
 * This class is a singleton that wraps the ASP event bridge and provides
 * additional functionality for interacting with the host app.
 */
export class ASPEventBridge extends EventTarget implements EventBridge {
  static #eventBridgeInstance: ASPEventBridge;

  #hostApp?: RemoteEventBridge;
  hostAppId!: string;

  private constructor() {
    super();
    this.#hostApp = wrapGlowSDK();
  }

  static getInstance(): ASPEventBridge {
    if (!ASPEventBridge.#eventBridgeInstance) {
      ASPEventBridge.#eventBridgeInstance = new ASPEventBridge();
    }
    return ASPEventBridge.#eventBridgeInstance;
  }

  init(hostAppId: string) {
    this.hostAppId = hostAppId;
  }

  minimizeApp(): void {
    this.#hostApp?.minimizeApp();
  }

  sendToHostApp(event: Event) {
    this.#hostApp?.dispatchEvent(event);
  }

  postToWindow(event: string, attributes?: any) {
    window.top!.postMessage(
      {
        event,
        ...attributes,
      },
      '*',
    );
  }

  addEventListener<K extends keyof EventBridgeEventMap>(
    type: K,
    listener: (this: EventBridge, event: EventBridgeEventMap[K]) => void,
    options?: boolean | AddEventListenerOptions,
  ): void;
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void;
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void {
    super.addEventListener(type, listener, options);
  }

  removeEventListener<K extends keyof EventBridgeEventMap>(
    type: K,
    listener: (this: EventBridge, event: EventBridgeEventMap[K]) => void,
    options?: boolean | EventListenerOptions,
  ): void;
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ): void;
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ): void {
    super.removeEventListener(type, listener, options);
  }
}

if (typeof window !== 'undefined' && !!window.self.parent) {
  const eventBridge = ASPEventBridge.getInstance();
  exposeEventBridge(eventBridge);
}
