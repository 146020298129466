import { Text } from '@soluto-private/mx-asurion-ui-react';
import Image from 'next/image';
import confusedMan from 'public/confusedMan.svg';
import ActionsContainer from '../ActionsContainer/ActionsContainer';
import ContentContainer from '../ContentContainer/ContentContainer';
import Layout from '../Layout/Layout';
import Link from '../Link';
import styles from './FailurePage.module.css';

export default function FailurePage() {
  return (
    <Layout>
      <ContentContainer>
        <div className={styles['custom-500']}>
          <Image src={confusedMan} alt="Confused Man" priority />
          <Text size={5} weight="feather">
            Something went wrong. Please try again.
          </Text>
        </div>
      </ContentContainer>
      <ActionsContainer>
        <Link
          href="/"
          variant="button"
          btnVariantProps={{
            btnSize: 'medium',
            btnVariant: 'default',
          }}
        >
          Return to home
        </Link>
      </ActionsContainer>
    </Layout>
  );
}
