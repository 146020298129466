import {
  AsurionDoodleSpinner,
  LoaderProps,
} from '@soluto-private/mx-asurion-ui-react';
import styles from './CenteredLoadingSpinner.module.css';

interface CenteredLoadingSpinnerProps extends LoaderProps {}

export function CenteredLoadingSpinner(props: CenteredLoadingSpinnerProps) {
  return (
    <div className={styles.loaderContainer}>
      <AsurionDoodleSpinner {...props} />
    </div>
  );
}
