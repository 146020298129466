import {
  BaseErrorFragment,
  isCreatePaySessionSuccessPayload,
  NotFoundErrorFragment,
  PaySessionFragment,
} from '@soluto-private/asp-core';
import { useCreatePaySession } from '@soluto-private/asp-react-core';
import { Button, ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import { MouseEvent } from 'react';
import { CombinedError } from 'urql';
import { TransactionType } from '../../generated/types';

export interface CreatePaySessionButtonProps extends ButtonProps {
  onPaySessionCreated?(paySession: PaySessionFragment): void;
  onBaseError?(error: BaseErrorFragment): void;
  onNotFoundError?(error: NotFoundErrorFragment): void;
  onGraphQLError?(error: CombinedError): void;
  transactionType?: TransactionType;
}

export function CreatePaySessionButton({
  onPaySessionCreated,
  onBaseError,
  onNotFoundError,
  onGraphQLError,
  onClick,
  children,
  disabled,
  transactionType,
  ...rest
}: CreatePaySessionButtonProps) {
  const [{ fetching }, createPaySession] = useCreatePaySession();

  async function handleClick(event: MouseEvent<HTMLButtonElement>) {
    onClick?.(event);

    const { data, error } = await createPaySession({ transactionType });

    if (error != null) {
      onGraphQLError?.(error);
    }

    if (isCreatePaySessionSuccessPayload(data)) {
      onPaySessionCreated?.(data.createPaySession.paySession);
    } else if (data?.createPaySession.__typename === 'BaseError') {
      onBaseError?.(data.createPaySession);
    } else if (data?.createPaySession.__typename === 'NotFoundError') {
      onNotFoundError?.(data.createPaySession);
    }
  }

  return (
    <Button disabled={fetching || disabled} onClick={handleClick} {...rest}>
      {children ?? 'Create Pay Session'}
    </Button>
  );
}
