import styles from './ActionsContainer.module.css';

type Props = {
  children: React.ReactNode;
  styles?: object;
};

export default function ActionsContainer(props: Props) {
  return (
    <div className={styles['actions-container']} style={props.styles}>
      {props.children}
    </div>
  );
}
