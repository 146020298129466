export function computePathWithQuery(
  path: string | null,
  searchParams: URLSearchParams | null,
  additionalParams: Record<string, string>,
) {
  const current = new URLSearchParams(
    Array.from(searchParams?.entries() || []),
  );

  Object.entries(additionalParams).forEach(([k, v]) => current.set(k, v));
  if (Array.from(current.entries()).length === 0) {
    return path || '';
  } else {
    return path ? `${path}?${current}` : `?${current}`;
  }
}
