import { getUrlData } from '@/utils/urlData';
import { getCurrentSessionURL, isInitialized } from '@fullstory/browser';
import { AnalyticsInstance } from '@soluto-private/analytics-core';
import {
  CustomerValidatedEvent,
  SupportSessionClosedEvent,
  SupportSessionStartedEvent,
} from '@soluto-private/bridge';
import { redactedFsEvent } from '@soluto-private/utils';
import { soCoEventClientMap } from './mappers';

export const sendAnalyticEvent = (
  analytics: AnalyticsInstance,
  hostApp: string | null | undefined,
  status: 'authenticated' | 'loading' | 'unauthenticated',
  expertSessionId: string | undefined,
  name: string,
  event:
    | CustomerValidatedEvent
    | SupportSessionStartedEvent
    | SupportSessionClosedEvent,
  extraData: object,
) => {
  const analyticEvent = {
    Name: name,
    Scope: 'expert-sales-tool',
    ExtraData: {
      ...getUrlData(),
      FullstorySession: isInitialized() ? getCurrentSessionURL() : undefined,
      ExpertAuthStatus: status,
      ...extraData,
    },
    MetaData: {
      Client: soCoEventClientMap[event.client] ?? event.client,
      HostApp: hostApp ?? undefined,
      SourceApp: 'sales-tool',
      IsTest: process.env.NEXT_PUBLIC_STAGE !== 'prod',
    },
    Identities: {
      UserId: event.employeeId,
      Email: event.email,
      ExpertSessionId: expertSessionId,
      ExternalSessionId: event.supportSessionId,
    },
  };

  analytics.track(analyticEvent.Name, analyticEvent);
  redactedFsEvent(analyticEvent.Name, analyticEvent);
};
