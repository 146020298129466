import {
  PredictiveTextField,
  TextFieldStatus,
} from '@soluto-private/mx-asurion-ui-react';
import React, { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { geocodeStatusMap, searchOptionsByRegion } from './constants';
import { extractAddressComponents, filterDropdownItems } from './helpers';
import { ExtractedAddress, GeocodeDefinedStatus } from './types';

interface AddressSearchProps {
  onAddressSelect: (
    address: ExtractedAddress | undefined,
    isValid: boolean,
  ) => void;
  region?: 'us' | 'uk';
  labelText?: string;
  retryText?: string;
  noResultsText?: string;
  minimumSearchTerms?: number;
  debounce?: number;
  disabled?: boolean;
  fieldStatus?: TextFieldStatus;
}

export const AddressSearch: React.FC<AddressSearchProps> = ({
  onAddressSelect = (
    address: ExtractedAddress | undefined,
    isValid: boolean,
  ) => {},
  region = 'us',
  labelText = 'Your Address',
  retryText = `We&rsquo;re having some problems, please try again later`,
  noResultsText = '',
  minimumSearchTerms = 2,
  debounce = 200,
  disabled = false,
  fieldStatus = 'default',
}) => {
  const [address, setAddress] = useState('');
  const [helpText, setHelpText] = useState('');

  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={(address: string) => setAddress(address)}
        searchOptions={searchOptionsByRegion[region]}
        shouldFetchSuggestions={address.length > minimumSearchTerms}
        debounce={debounce}
      >
        {({ getInputProps, suggestions }) => {
          const dropdownItems = suggestions
            .filter((suggestion) => filterDropdownItems(suggestion))
            .map((suggestion) => ({
              id: suggestion.placeId,
              header: suggestion.description,
              subHeader: '',
              value: '',
            }));
          return (
            <div className="autocomplete-root fs-mask">
              <PredictiveTextField
                iconSrc="LocationMap"
                label={labelText}
                value={address}
                disabled={disabled}
                includeSubheader={false}
                suggestions={dropdownItems}
                handleChange={(value: string) => {
                  getInputProps().onChange({ target: { value } });
                  setAddress(value);
                  onAddressSelect(undefined, true);
                }}
                noResultsText={noResultsText}
                helperText={helpText}
                onSelection={async (suggestion) => {
                  try {
                    const [address] = await geocodeByAddress(suggestion.header);
                    setAddress(address.formatted_address);
                    onAddressSelect(
                      extractAddressComponents(address.address_components),
                      true,
                    );
                  } catch (status: unknown) {
                    geocodeStatusMap[status as GeocodeDefinedStatus].retryable
                      ? setHelpText(retryText)
                      : null;
                  }
                }}
                fieldStatus={fieldStatus}
              />
            </div>
          );
        }}
      </PlacesAutocomplete>
    </>
  );
};
