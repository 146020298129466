import { SessionStatus } from '@soluto-private/asp-core';
import {
  CustomerValidatedEvent,
  SupportSessionStartedEvent,
} from '@soluto-private/bridge';
import { soCoEventClientMap } from './mappers';

export const createCustomerSessionWrapper = async (
  expertSessionId: string | undefined,
  twilioFlex: string,
  createCustomerSession: Function,
  event: CustomerValidatedEvent | SupportSessionStartedEvent,
  sessionStatus?: SessionStatus,
) => {
  const partner = soCoEventClientMap[event.client];
  const {
    supportSessionId: externalSessionId,
    mdn,
    asurionCallId,
    reservationId: flexReservationId,
  } = event;

  return await createCustomerSession({
    expertSessionId: expertSessionId,
    partner,
    customer: {
      mdn,
    },
    externalSession: {
      id: externalSessionId,
    },
    asurionCall: asurionCallId
      ? {
          id: asurionCallId,
          flexReservation: flexReservationId
            ? {
                id: flexReservationId,
              }
            : undefined,
        }
      : undefined,
    origin: twilioFlex,
    sessionStatus: sessionStatus,
  });
};

export const startCustomerSessionWrapper = async (
  expertSessionId: string | undefined,
  twilioFlex: string,
  productSKUs: Map<string, string>,
  startCustomerSession: Function,
  event: CustomerValidatedEvent | SupportSessionStartedEvent,
  sessionStatus?: SessionStatus,
) => {
  const partner = soCoEventClientMap[event.client];
  const {
    supportSessionId: externalSessionId,
    mdn,
    asurionCallId,
    reservationId: flexReservationId,
  } = event;

  return await startCustomerSession({
    expertSessionId: expertSessionId,
    partner,
    customer: {
      mdn,
    },
    externalSession: {
      id: externalSessionId,
    },
    asurionCall: asurionCallId
      ? {
          id: asurionCallId,
          flexReservation: flexReservationId
            ? {
                id: flexReservationId,
              }
            : undefined,
        }
      : undefined,
    origin: twilioFlex,
    productSKUs: productSKUs,
    sessionStatus: sessionStatus,
  });
};
