var __require = /* @__PURE__ */ ((x) => typeof require !== "undefined" ? require : typeof Proxy !== "undefined" ? new Proxy(x, {
  get: (a, b) => (typeof require !== "undefined" ? require : a)[b]
}) : x)(function(x) {
  if (typeof require !== "undefined")
    return require.apply(this, arguments);
  throw Error('Dynamic require of "' + x + '" is not supported');
});

// src/invariant.error.ts
var prefix = "Invariant failed";
var InvariantError = class extends Error {
  constructor(message, context, metadata) {
    super(message ? `${prefix}: ${message}` : prefix);
    this.name = "InvariantError";
    this.context = context;
    this.metadata = metadata;
  }
};

// src/bugsnag.ts
var bugsnag;
try {
  bugsnag = __require("@bugsnag/js").default;
} catch {
}
function bugsnagInvariantHandler(error) {
  if (bugsnag?.isStarted()) {
    bugsnag.notify(error, notifyCallback(error));
  }
}
function bugsnagWarningHandler(error) {
  if (bugsnag?.isStarted()) {
    bugsnag.notify(error, notifyCallback(error));
  }
}
function notifyCallback(error) {
  return (event) => {
    if (error instanceof InvariantError) {
      event.severity = "error";
    }
    if (error.context) {
      event.context = error.context;
    }
    if (error.metadata) {
      event.addMetadata("invariant", error.metadata);
    }
  };
}

// src/console.ts
function consoleInvariantHandler(error) {
  if (process.env.NODE_ENV !== "test") {
    console.error(error);
  }
}
function consoleWarningHandler(error) {
  if (process.env.NODE_ENV !== "test") {
    console.warn(error);
  }
}

// src/handler.ts
var invariantHandlers = [
  consoleInvariantHandler,
  bugsnagInvariantHandler
];
var warningHandlers = [
  consoleWarningHandler,
  bugsnagWarningHandler
];
function registerWarningHandler(handler) {
  warningHandlers.push(handler);
}
function registerInvariantHandler(handler) {
  invariantHandlers.push(handler);
}

// src/invariant.ts
function invariant(condition, message, options) {
  if (condition) {
    return;
  }
  const error = new InvariantError(
    message,
    options?.context,
    options?.metadata
  );
  Error.captureStackTrace(error, invariant);
  for (const handler of invariantHandlers) {
    handler(error);
  }
  throw error;
}

// src/warning.error.ts
var prefix2 = "Warning";
var WarningError = class extends Error {
  constructor(message, context, metadata) {
    super(message ? `${prefix2}: ${message}` : prefix2);
    this.name = "WarningError";
    this.context = context;
    this.metadata = metadata;
  }
};

// src/warning.ts
function warning(condition, message, options) {
  if (condition) {
    return;
  }
  const error = new WarningError(message, options?.context, options?.metadata);
  Error.captureStackTrace(error, warning);
  for (const handler of warningHandlers) {
    handler(error);
  }
  try {
    throw error;
  } catch {
  }
}
export {
  InvariantError,
  WarningError,
  invariant,
  registerInvariantHandler,
  registerWarningHandler,
  warning
};
