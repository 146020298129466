import {
  BaseErrorFragment,
  NotFoundErrorFragment,
  TransmittedDisclosureStatus,
  isUpdateProductOfferSuccessPayload,
} from '@soluto-private/asp-core';
import { useUpdateProductOffer } from '@soluto-private/asp-react-core';
import { Button, ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import { MouseEvent } from 'react';
import { CombinedError } from 'urql';

export interface UpdateProductOfferButtonProps extends ButtonProps {
  onBaseError?(error: BaseErrorFragment): void;
  onNotFoundError?(error: NotFoundErrorFragment): void;
  onGraphQLError?(error: CombinedError): void;
  onProductOfferUpdated?(): void;
  productOfferId: string;
  disclosureStatus: TransmittedDisclosureStatus;
}

export function UpdateProductOfferButton({
  onBaseError,
  onNotFoundError,
  onGraphQLError,
  onClick,
  onProductOfferUpdated,
  productOfferId,
  disclosureStatus,
  children,
  disabled,
  ...rest
}: UpdateProductOfferButtonProps) {
  const [{ fetching }, updateProductOffer] = useUpdateProductOffer();

  async function handleClick(event: MouseEvent<HTMLButtonElement>) {
    onClick?.(event);

    const { data, error } = await updateProductOffer({
      id: productOfferId,
      disclosureStatus,
    });

    if (error != null) {
      onGraphQLError?.(error);
    }

    if (isUpdateProductOfferSuccessPayload(data)) {
      onProductOfferUpdated?.();
    } else if (data?.updateProductOffer.__typename === 'BaseError') {
      onBaseError?.(data.updateProductOffer);
    } else if (data?.updateProductOffer.__typename === 'NotFoundError') {
      onNotFoundError?.(data.updateProductOffer);
    }
  }

  return (
    <Button disabled={fetching || disabled} onClick={handleClick} {...rest}>
      {children ?? 'Update product offer'}
    </Button>
  );
}
