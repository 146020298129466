import { soCoEventClientMap } from '@/components/SoCoEventHandler/utils/mappers';
import {
  useExpertSession,
  useSalesConfig,
} from '@soluto-private/asp-react-core';
import {
  CustomerSessionStartedEvent,
  CustomerValidatedEvent,
  TaskStatusUpdatedEvent,
} from '@soluto-private/bridge';
import { clientCodeMap } from '@soluto-private/utils';
import { useSession } from 'next-auth/react';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useEventBridge } from '../../hooks/useEventBridge';

type Props = {
  children?: ReactNode;
  workerAttributes?: Record<string, any> | undefined;
  isSocoEventHandlerEnabled?: boolean;
};

interface FlexData {
  asurionCallId?: string;
  externalSessionId?: string;
  flexReservationId?: string;
  flexPartner?: string;
  needManualMdn?: boolean;
  workerAttributes?: Record<string, any> | undefined;
  isSocoEventHandlerEnabled?: boolean;
}

const FlexDataContext = createContext<FlexData>({});

export const useFlexData = () => {
  return useContext(FlexDataContext);
};

export function FlexDataProvider({
  children,
  workerAttributes,
  isSocoEventHandlerEnabled,
}: Props) {
  const { data: session } = useSession();
  const [{ data: expertSession }] = useExpertSession({
    expertSessionId: session?.user?.expertSessionId!,
    includeExpert: true,
  });

  const [asurionCallId, setAsurionCallId] = useState<string | undefined>(
    undefined,
  );
  const [externalSessionId, setExternalSessionId] = useState<
    string | undefined
  >(undefined);
  const [flexReservationId, setFlexReservationId] = useState<string>();
  const [flexPartner, setFlexPartner] = useState<string>();
  const [needManualMdn, setNeedManualMdn] = useState<boolean>(false);

  const [{ data: salesConfig }] = useSalesConfig({
    applicationName: 'ExpertSalesTool',
    configName: 'ivr-client-config',
  });

  const handleTaskStatusUpdated = useCallback(
    (event: TaskStatusUpdatedEvent) => {
      if (event.status !== 'ACTIVE') {
        setAsurionCallId(undefined);
        setFlexReservationId(undefined);
      }
    },
    [],
  );

  const handleCustomerSessionStarted = useCallback(
    async (event: CustomerSessionStartedEvent) => {
      setAsurionCallId(event.asurionCallId);
      setFlexReservationId(event.externalSessionId);

      if (event.direction === 'inbound' && event.partner) {
        let partner = !salesConfig
          ? clientCodeMap[event.partner]
          : salesConfig[event.partner];

        // Handles Flex v1 scenario on the frontend
        if (!partner && event.partner) {
          partner = event.partner;
        }

        if (partner != flexPartner) {
          setFlexPartner(partner);
        }
      }
    },
    [flexPartner, salesConfig],
  );

  const handleCustomerValidated = useCallback(
    async (event: CustomerValidatedEvent) => {
      const validationMethod = event.validationMethod.toLowerCase();
      // TODO: will this map eventually be sourced from the salesConfig? If so, use similar partner logic to the handleCustomerSessionStarted function, except for with the soCoEventClientMap instead of clientCodeMap.
      const partner = soCoEventClientMap[event.client];
      setFlexPartner(partner);
      setAsurionCallId(event.asurionCallId);
      setFlexReservationId(event.reservationId);
      setExternalSessionId(event.supportSessionId);
      setNeedManualMdn(validationMethod !== 'mdn' || event.mdn == null);
    },
    [],
  );

  useEventBridge({
    handleTaskStatusUpdated: !isSocoEventHandlerEnabled
      ? handleTaskStatusUpdated
      : undefined,
    handleCustomerSessionStarted: !isSocoEventHandlerEnabled
      ? handleCustomerSessionStarted
      : undefined,
    handleCustomerValidated: isSocoEventHandlerEnabled
      ? handleCustomerValidated
      : undefined,
  });

  const contextValue = useMemo(
    () => ({
      asurionCallId,
      flexReservationId,
      flexPartner,
      externalSessionId,
      needManualMdn,
      workerAttributes,
      isSocoEventHandlerEnabled,
    }),
    [
      asurionCallId,
      flexReservationId,
      flexPartner,
      externalSessionId,
      needManualMdn,
      workerAttributes,
      isSocoEventHandlerEnabled,
    ],
  );

  return (
    <FlexDataContext.Provider value={contextValue}>
      {children}
    </FlexDataContext.Provider>
  );
}
