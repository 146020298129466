// src/index.ts
import * as Comlink2 from "comlink";

// src/polyfills/event.ts
import { warning as warn } from "@soluto-private/invariant";
if (typeof globalThis.Event === "undefined") {
  globalThis.Event = class Event {
    constructor() {
      warn(true, "Event is not supported in this environment");
    }
  };
}

// src/polyfills/eventTarget.ts
import { warning as warn2 } from "@soluto-private/invariant";
if (typeof globalThis.EventTarget === "undefined") {
  globalThis.EventTarget = class EventTarget {
    constructor() {
      warn2(true, "EventTarget is not supported in this environment");
    }
    addEventListener() {
    }
    dispatchEvent() {
      return true;
    }
    removeEventListener() {
    }
  };
}

// src/events/customerAddedEvent.ts
var CustomerAddedEvent = class _CustomerAddedEvent extends Event {
  static {
    this.type = "customeradded";
  }
  constructor({
    customer,
    sessionId
  }) {
    super(_CustomerAddedEvent.type);
    this.customer = customer;
    this.sessionId = sessionId;
  }
  toJSON() {
    return { customer: this.customer, sessionId: this.sessionId };
  }
  static from(obj) {
    return new _CustomerAddedEvent(obj);
  }
};

// src/events/customerEligibilityCheckedEvent.ts
var CustomerEligibilityCheckedEvent = class _CustomerEligibilityCheckedEvent extends Event {
  static {
    this.type = "customereligibilitychecked";
  }
  constructor(status) {
    super(_CustomerEligibilityCheckedEvent.type);
    this.status = status;
  }
  toJSON() {
    return { status: this.status };
  }
  static from(obj) {
    return new _CustomerEligibilityCheckedEvent(obj.status);
  }
};

// src/events/customerEnrolledEvent.ts
var CustomerEnrolledEvent = class _CustomerEnrolledEvent extends Event {
  constructor(planId) {
    super(_CustomerEnrolledEvent.type);
    this.planId = planId;
  }
  static {
    this.type = "customerenrolled";
  }
  toJSON() {
    return { planId: this.planId };
  }
  static from(obj) {
    return new _CustomerEnrolledEvent(obj.planId);
  }
};

// src/events/customerSessionStartedEvent.ts
var CustomerSessionStartedEvent = class _CustomerSessionStartedEvent extends Event {
  static {
    this.type = "customersessionstarted";
  }
  constructor({
    mdn,
    channel,
    externalSessionId,
    partner,
    direction,
    asurionCallId,
    language
  }) {
    super(_CustomerSessionStartedEvent.type);
    this.mdn = mdn;
    this.channel = channel;
    this.externalSessionId = externalSessionId;
    this.partner = partner;
    this.direction = direction;
    this.asurionCallId = asurionCallId;
    this.language = language;
  }
  toJSON() {
    return {
      mdn: this.mdn,
      channel: this.channel,
      externalSessionId: this.externalSessionId,
      partner: this.partner,
      direction: this.direction,
      asurionCallId: this.asurionCallId,
      language: this.language
    };
  }
  static from(obj) {
    return new _CustomerSessionStartedEvent(obj);
  }
};

// src/events/customerValidatedEvent.ts
var CustomerValidatedEvent = class _CustomerValidatedEvent extends Event {
  static {
    this.type = "customervalidated";
  }
  constructor({
    name,
    client,
    mdn,
    supportSessionId,
    validationMethod,
    lob,
    employeeId,
    email,
    asurionCallId,
    reservationId
  }) {
    super(_CustomerValidatedEvent.type);
    this.name = name;
    this.mdn = mdn;
    this.client = client.toLowerCase();
    this.supportSessionId = supportSessionId;
    this.validationMethod = validationMethod;
    this.lob = lob.toLowerCase();
    this.employeeId = employeeId;
    this.email = email;
    this.asurionCallId = asurionCallId;
    this.reservationId = reservationId;
  }
  toJSON() {
    return {
      name: this.name,
      client: this.client,
      mdn: this.mdn,
      supportSessionId: this.supportSessionId,
      validationMethod: this.validationMethod,
      lob: this.lob,
      employeeId: this.employeeId,
      email: this.email,
      asurionCallId: this.asurionCallId,
      reservationId: this.reservationId
    };
  }
  static from(obj) {
    return new _CustomerValidatedEvent(obj);
  }
};

// src/events/expertAuthStatusChangedEvent.ts
var ExpertAuthStatusChangedEvent = class _ExpertAuthStatusChangedEvent extends Event {
  static {
    this.type = "expertauthstatuschanged";
  }
  constructor(status) {
    super(_ExpertAuthStatusChangedEvent.type);
    this.status = status;
  }
  toJSON() {
    return {
      status: this.status
    };
  }
  static from(obj) {
    return new _ExpertAuthStatusChangedEvent(obj.status);
  }
};

// src/events/invariantEvent.ts
var InvariantEvent = class _InvariantEvent extends Event {
  static {
    this.type = "invariant";
  }
  constructor(message) {
    super(_InvariantEvent.type);
    this.message = message;
  }
  toJSON() {
    return { message: this.message };
  }
  static from(obj) {
    return new _InvariantEvent(obj.message);
  }
};

// src/events/partnerAddedEvent.ts
var PartnerAddedEvent = class _PartnerAddedEvent extends Event {
  constructor(partner) {
    super(_PartnerAddedEvent.type);
    this.partner = partner;
  }
  static {
    this.type = "partneradded";
  }
  toJSON() {
    return { partner: this.partner };
  }
  static from(obj) {
    return new _PartnerAddedEvent(obj.partner);
  }
};

// src/events/productSelectedEvent.ts
var ProductSelectedEvent = class _ProductSelectedEvent extends Event {
  static {
    this.type = "productselected";
  }
  constructor({
    productSKU,
    sessionId
  }) {
    super(_ProductSelectedEvent.type);
    this.productSKU = productSKU;
    this.sessionId = sessionId;
  }
  toJSON() {
    return { productSKU: this.productSKU, sessionId: this.sessionId };
  }
  static from(obj) {
    return new _ProductSelectedEvent(obj);
  }
};

// src/events/supportSessionClosedEvent.ts
var SupportSessionClosedEvent = class _SupportSessionClosedEvent extends Event {
  static {
    this.type = "supportsessionclosed";
  }
  constructor({
    name,
    client,
    supportSessionId,
    employeeId,
    email
  }) {
    super(_SupportSessionClosedEvent.type);
    this.name = name;
    this.client = client.toLowerCase();
    this.supportSessionId = supportSessionId;
    this.employeeId = employeeId;
    this.email = email;
  }
  toJSON() {
    return {
      name: this.name,
      client: this.client,
      supportSessionId: this.supportSessionId,
      employeeId: this.employeeId,
      email: this.email
    };
  }
  static from(obj) {
    return new _SupportSessionClosedEvent(obj);
  }
};

// src/events/supportSessionStartedEvent.ts
var SupportSessionStartedEvent = class _SupportSessionStartedEvent extends Event {
  static {
    this.type = "supportsessionstarted";
  }
  constructor({
    name,
    client,
    supportSessionId,
    lob,
    employeeId,
    email,
    mdn,
    asurionCallId,
    reservationId,
    callDirection
  }) {
    super(_SupportSessionStartedEvent.type);
    this.name = name;
    this.client = client.toLowerCase();
    this.supportSessionId = supportSessionId;
    this.lob = lob.toLowerCase();
    this.employeeId = employeeId;
    this.email = email;
    this.mdn = mdn;
    this.asurionCallId = asurionCallId;
    this.reservationId = reservationId;
    this.callDirection = callDirection;
  }
  toJSON() {
    return {
      name: this.name,
      client: this.client,
      supportSessionId: this.supportSessionId,
      lob: this.lob,
      employeeId: this.employeeId,
      email: this.email,
      mdn: this.mdn,
      asurionCallId: this.asurionCallId,
      reservationId: this.reservationId,
      callDirection: this.callDirection
    };
  }
  static from(obj) {
    return new _SupportSessionStartedEvent(obj);
  }
};

// src/events/taskStatusUpdatedEvent.ts
var TaskStatusUpdatedEvent = class _TaskStatusUpdatedEvent extends Event {
  constructor(status) {
    super(_TaskStatusUpdatedEvent.type);
    this.status = status;
  }
  static {
    this.type = "taskstatusupdated";
  }
  toJSON() {
    return { status: this.status };
  }
  static from(obj) {
    return new _TaskStatusUpdatedEvent(obj.status);
  }
};

// src/events/toggleSalesToolDisplay.ts
var ToggleSalesToolDisplayEvent = class _ToggleSalesToolDisplayEvent extends Event {
  static {
    this.type = "togglesalestooldisplay";
  }
  constructor({ shouldDisplay }) {
    super(_ToggleSalesToolDisplayEvent.type);
    this.shouldDisplay = shouldDisplay;
  }
  toJSON() {
    return {
      shouldDisplay: this.shouldDisplay
    };
  }
  static from(obj) {
    return new _ToggleSalesToolDisplayEvent(obj);
  }
};

// src/events/trackEvent.ts
var TrackEvent = class _TrackEvent extends Event {
  static {
    this.type = "track";
  }
  constructor(event) {
    super(_TrackEvent.type);
    this.name = event.Name;
    this.scope = event.Scope;
    this.time = event.Time;
    this.extraData = event.ExtraData;
    this.identities = event.Identities;
    this.metaData = event.MetaData;
  }
  toJSON() {
    return {
      Name: this.name,
      Scope: this.scope,
      Time: this.time,
      ExtraData: this.extraData,
      Identities: this.identities,
      MetaData: this.metaData
    };
  }
  static from(obj) {
    return new _TrackEvent(obj);
  }
};

// src/events/updateExpertSessionEvent.ts
var UpdateExpertSessionEvent = class _UpdateExpertSessionEvent extends Event {
  static {
    this.type = "updateExpertSession";
  }
  constructor({ hostApp, lob }) {
    super(_UpdateExpertSessionEvent.type);
    this.hostApp = hostApp;
    this.lob = lob;
  }
  toJSON() {
    return {
      hostApp: this.hostApp,
      lob: this.lob
    };
  }
  static from(obj) {
    return new _UpdateExpertSessionEvent(obj);
  }
};

// src/events/warnEvent.ts
var WarnEvent = class _WarnEvent extends Event {
  static {
    this.type = "warn";
  }
  constructor(message) {
    super(_WarnEvent.type);
    this.message = message;
  }
  toJSON() {
    return { message: this.message };
  }
  static from(obj) {
    return new _WarnEvent(obj.message);
  }
};

// src/events/workerAttributesEvent.ts
var WorkerAttributesEvent = class _WorkerAttributesEvent extends Event {
  static {
    this.type = "workerattributes";
  }
  constructor({ attributes }) {
    super(_WorkerAttributesEvent.type);
    this.attributes = attributes;
  }
  toJSON() {
    return {
      attributes: this.attributes
    };
  }
  static from(obj) {
    return new _WorkerAttributesEvent(obj);
  }
};

// src/transfer-handlers/register-event.ts
import * as Comlink from "comlink";
function registerEvent(EventClass) {
  Comlink.transferHandlers.set(EventClass.type, {
    canHandle: (obj) => obj instanceof EventClass,
    serialize: (obj) => [obj.toJSON(), []],
    deserialize: (obj) => EventClass.from(obj)
  });
}

// src/transfer-handlers/index.ts
registerEvent(CustomerAddedEvent);
registerEvent(CustomerEligibilityCheckedEvent);
registerEvent(CustomerEnrolledEvent);
registerEvent(CustomerSessionStartedEvent);
registerEvent(CustomerValidatedEvent);
registerEvent(TaskStatusUpdatedEvent);
registerEvent(SupportSessionStartedEvent);
registerEvent(SupportSessionClosedEvent);
registerEvent(ExpertAuthStatusChangedEvent);
registerEvent(InvariantEvent);
registerEvent(PartnerAddedEvent);
registerEvent(ProductSelectedEvent);
registerEvent(ToggleSalesToolDisplayEvent);
registerEvent(TrackEvent);
registerEvent(UpdateExpertSessionEvent);
registerEvent(WarnEvent);
registerEvent(WorkerAttributesEvent);

// src/types.ts
var RequiredSoCoSupportSessionClosedProperties = [
  "name",
  "client",
  "supportSessionId"
];
var RequiredSoCoSupportSessionStartedProperties = [
  "name",
  "client",
  "supportSessionId",
  "lob",
  "asurionCallId",
  "reservationId"
];
var RequiredSoCoCustomerValidatedProperties = [
  "name",
  "client",
  "supportSessionId",
  "validationMethod",
  "lob",
  "employeeId",
  "email"
];
var CallStatus = /* @__PURE__ */ ((CallStatus2) => {
  CallStatus2["Active"] = "ACTIVE";
  CallStatus2["NotActive"] = "NOT_ACTIVE";
  return CallStatus2;
})(CallStatus || {});

// src/index.ts
function exposeEventBridge(eventBridge) {
  Comlink2.expose(eventBridge, Comlink2.windowEndpoint(window.self.parent));
}
function wrapGlowSDK() {
  if (typeof window !== "undefined") {
    return Comlink2.wrap(
      Comlink2.windowEndpoint(window.self.parent)
    );
  }
}
async function wrapBridge(sdk, iframe) {
  Comlink2.expose(sdk, Comlink2.windowEndpoint(iframe.contentWindow));
  await new Promise((resolve) => iframe.onload = resolve);
  return Comlink2.wrap(
    Comlink2.windowEndpoint(iframe.contentWindow)
  );
}
export {
  CallStatus,
  CustomerAddedEvent,
  CustomerEligibilityCheckedEvent,
  CustomerEnrolledEvent,
  CustomerSessionStartedEvent,
  CustomerValidatedEvent,
  ExpertAuthStatusChangedEvent,
  InvariantEvent,
  PartnerAddedEvent,
  ProductSelectedEvent,
  RequiredSoCoCustomerValidatedProperties,
  RequiredSoCoSupportSessionClosedProperties,
  RequiredSoCoSupportSessionStartedProperties,
  SupportSessionClosedEvent,
  SupportSessionStartedEvent,
  TaskStatusUpdatedEvent,
  ToggleSalesToolDisplayEvent,
  TrackEvent,
  UpdateExpertSessionEvent,
  WarnEvent,
  WorkerAttributesEvent,
  exposeEventBridge,
  wrapBridge,
  wrapGlowSDK
};
