import {
  BaseErrorFragment,
  isCreateProductDeclinationSuccessPayload,
  NotFoundErrorFragment,
  ProductDeclinationFragment,
} from '@soluto-private/asp-core';
import { useCreateProductDeclination } from '@soluto-private/asp-react-core';
import { Button, ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import { MouseEvent } from 'react';
import { CombinedError } from 'urql';

export interface CreateProductDeclinationButtonProps extends ButtonProps {
  onDeclineProductOffer?(productDeclination: ProductDeclinationFragment): void;
  onBaseError?(error: BaseErrorFragment): void;
  onNotFoundError?(error: NotFoundErrorFragment): void;
  onGraphQLError?(error: CombinedError): void;
  reason?: string;
}

export const CreateProductDeclinationButton = ({
  onDeclineProductOffer,
  onBaseError,
  onNotFoundError,
  onGraphQLError,
  onClick,
  reason,
  children,
  disabled,
  ...rest
}: CreateProductDeclinationButtonProps) => {
  const [{ fetching }, createProductDeclination] =
    useCreateProductDeclination();

  async function handleClick(event: MouseEvent<HTMLButtonElement>) {
    onClick?.(event);

    const { data, error } = await createProductDeclination({ reason });

    if (error != null) {
      onGraphQLError?.(error);
    }

    if (isCreateProductDeclinationSuccessPayload(data)) {
      onDeclineProductOffer?.(data.createProductDeclination.productDeclination);
    } else if (data?.createProductDeclination.__typename === 'BaseError') {
      onBaseError?.(data.createProductDeclination);
    } else if (data?.createProductDeclination.__typename === 'NotFoundError') {
      onNotFoundError?.(data.createProductDeclination);
    }
  }

  return (
    <Button disabled={fetching || disabled} onClick={handleClick} {...rest}>
      {children ?? 'Decline'}
    </Button>
  );
};
