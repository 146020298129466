import { SDKEvent, SDKEventStatic } from '@soluto-private/bridge';
import { useEffect } from 'react';
import { ASPEventBridge } from '../utils/aspEventBridge';

/**
 * Hook that sets up event listeners for ASP events.
 *
 * @param eventClass The event class to listen for such as `TrackEvent`
 * @param handler The handler for the event
 */
export function useBridgeEvent<TEvent extends SDKEvent>(
  eventClass: SDKEventStatic<TEvent>,
  handler: (event: TEvent) => void,
): void {
  useEffect(() => {
    ASPEventBridge.getInstance().addEventListener(
      eventClass.type,
      handler as any,
    );

    return () => {
      ASPEventBridge.getInstance().removeEventListener(
        eventClass.type,
        handler as any,
      );
    };
  }, [eventClass, handler]);
}
