import { Modal } from '@soluto-private/aui-react-modal';
import {
  ButtonSelectionCard,
  RadioButton,
  Text,
} from '@soluto-private/mx-asurion-ui-react';

import { useFlexData } from '@/providers/FlexData';
import { getUrlData } from '@/utils/urlData';
import {
  useCustomerSession,
  useUpdateCustomerSession,
} from '@soluto-private/asp-react-core';
import { AnalyticsWrapper } from '@soluto-private/asp-react-ui';
import { capitalizeString } from '@soluto-private/utils';
import { useSession } from 'next-auth/react';
import { useSearchParams } from 'next/navigation';
import { ChangeEvent, useCallback, useState } from 'react';
import { OutboundCallData } from '../CustomerAutoPopHandler/CustomerAutoPopHandler';
import styles from './OutboundCallModal.module.css';

const UNKNOWN_VALUE = 'Unknown';

export type PartnerSelectProps = {
  setOutboundCallData: (outboundCallData: OutboundCallData) => void;
  outBoundCallData: OutboundCallData;
};

export type OutboundCallModalProps = {
  startNewCustomerSession: () => void;
  setOutboundCallData: (outboundCallData: OutboundCallData | null) => void;
  outBoundCallData: OutboundCallData;
};

export function PartnerSelect(props: PartnerSelectProps) {
  //FOR DEV USE ONLY: In the case that users  may have more than 1 partner, we need to allow them to specify a partner for new customer sessions created on outbound calls
  const { data: session } = useSession({ required: true });
  const [checked, setChecked] = useState('');

  const handleRadioButtonSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.value);
      props.setOutboundCallData({
        ...props.outBoundCallData,
        partner: event.target.value,
      });
    },
    [props],
  );

  return (
    <>
      {session?.user?.partners.map((partner) => (
        <RadioButton
          key={partner}
          label={capitalizeString(partner)}
          onChange={handleRadioButtonSelect}
          value={partner}
          checked={checked === partner}
        ></RadioButton>
      ))}
    </>
  );
}

export function OutboundCallModal(props: OutboundCallModalProps) {
  const { setOutboundCallData, outBoundCallData, startNewCustomerSession } =
    props;
  const { flexPartner } = useFlexData();
  const searchParams = useSearchParams();
  const csid = searchParams?.get('csid');

  const [{ data: customerSession }] = useCustomerSession({
    customerSessionId: csid,
  });
  const [, updateCustomerSession] = useUpdateCustomerSession({
    partner: customerSession?.partner,
  });
  const { data: session } = useSession();
  const { URL, Route } = getUrlData();

  const isDev =
    !(process.env.NODE_ENV === 'production') &&
    !(process.env.NEXT_PUBLIC_STAGE === 'prod');

  const isDevCallWithoutPartner =
    isDev && !props.outBoundCallData.partner && !flexPartner;

  function addNewCustomer() {
    startNewCustomerSession();
    setOutboundCallData(null);
  }

  function handleSameCustomerClick() {
    if (customerSession) {
      updateCustomerSession({
        id: customerSession.id,
        outboundCall: {
          id: outBoundCallData.asurionCallId ?? UNKNOWN_VALUE,
          flexReservation: {
            id: outBoundCallData.flexReservationId ?? UNKNOWN_VALUE,
          },
        },
      });
    }
    // TODO (SELL-1945): Handle edge case where expert clicks "calling same customer"
    // but customer session couldn't be retrieved (start new customer session)

    setOutboundCallData(null);
  }

  return (
    <Modal
      ariaLabel="Choose an option from the following"
      isOpen={outBoundCallData != null}
      onCloseModal={() => addNewCustomer()}
    >
      <Modal.Header>
        <Modal.Title>Calling a Different Number?</Modal.Title>
        <Modal.Subtitle>Choose one from the following:</Modal.Subtitle>
      </Modal.Header>
      <Modal.Content>
        <AnalyticsWrapper
          name="CallingSameCustomer_Clicked"
          identityData={{
            UserId: session?.user?.expertId,
            ExpertSessionId: session?.user?.expertSessionId,
          }}
          extraData={{ URL, Route }}
        >
          <ButtonSelectionCard
            className={styles.option}
            onClick={() => handleSameCustomerClick()}
          >
            I&#39;m calling the same customer
          </ButtonSelectionCard>
        </AnalyticsWrapper>
        {isDevCallWithoutPartner && (
          <div className={styles.radio}>
            <Text>Select a partner to call new customer</Text>
            <br />
            <PartnerSelect
              outBoundCallData={outBoundCallData}
              setOutboundCallData={setOutboundCallData}
            />
          </div>
        )}
        <br />
        <AnalyticsWrapper
          name="CallingNewCustomer_Clicked"
          identityData={{
            UserId: session?.user?.expertId,
            ExpertSessionId: session?.user?.expertSessionId,
          }}
          extraData={{ URL, Route }}
          disabled={isDevCallWithoutPartner}
        >
          <ButtonSelectionCard
            className={styles.option}
            disabled={isDevCallWithoutPartner}
            onClick={() => {
              addNewCustomer();
            }}
          >
            I&#39;m calling a new customer
          </ButtonSelectionCard>
        </AnalyticsWrapper>
      </Modal.Content>
    </Modal>
  );
}
