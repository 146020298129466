import { computePathWithQuery } from '@/utils/path';
import { getUrlData } from '@/utils/urlData';
import { getCurrentSessionURL, isInitialized } from '@fullstory/browser';
import { AnalyticsInstance } from '@soluto-private/analytics-core';
import { CustomerSessionQuery } from '@soluto-private/asp-core';
import { invariant } from '@soluto-private/invariant';
import { redactedFsEvent } from '@soluto-private/utils';
import { Session } from 'next-auth';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

export function handleExternalCustomerSessionFactory(
  router: AppRouterInstance,
  session: Session | null,
  analytics: AnalyticsInstance,
) {
  return (
    externalCustomerSession: NonNullable<
      CustomerSessionQuery['customerSession']
    >,
  ) => {
    invariant(
      externalCustomerSession?.horizonSession?.id != null,
      'No horizon session id found in external customer session.',
    );

    const analyticEvent = {
      Name: 'ExternalCustomerInformation_Received',
      Scope: 'expert-sales-tool',
      ExtraData: {
        ...getUrlData(),
        Scope: 'expert-sales-tool',
        FullstorySession: isInitialized() ? getCurrentSessionURL() : undefined,
        CustomerInformation: {
          Partner: externalCustomerSession.partner,
          ExternalSessionId: externalCustomerSession.horizonSession.id,
          CallDirection: 'inbound',
          Source: 'horizon',
        },
        Partner: externalCustomerSession.partner,
        CarrierName: externalCustomerSession.partner,
      },
      MetaData: {
        SourceApp: 'sales-tool',
        Client: externalCustomerSession.partner,
      },
      Identities: {
        UserId: session?.user?.expertId,
        Email: session?.user?.email,
        ExpertSessionId: session?.user?.expertSessionId,
        CustomerSessionId: externalCustomerSession.id,
      },
    };

    analytics.track(analyticEvent.Name, analyticEvent);
    redactedFsEvent(analyticEvent.Name, analyticEvent);

    router.push(
      computePathWithQuery('/product-menu', null, {
        csid: externalCustomerSession.id,
      }),
    );
  };
}
