import { ProductOfferFragment } from '@soluto-private/asp-core';
import { TagProps } from '@soluto-private/mx-asurion-ui-react';
import {
  ProductEligibilityStatus,
  ProductEnrollmentStatus,
  ProductOfferStatus,
} from '../generated/types';

export interface GetTagPropsOptions {
  productOffer?: ProductOfferFragment | null;
}

export function getTagProps({
  productOffer,
}: GetTagPropsOptions): Pick<TagProps, 'type' | 'iconSrc' | 'text'> {
  if (
    productOffer?.productEnrollment?.status === ProductEnrollmentStatus.Success
  ) {
    return {
      type: 'attention',
      text: 'Enrolled',
      iconSrc: 'SystemAlertCheckmark',
    };
  } else if (productOffer?.status === ProductOfferStatus.Declined) {
    return {
      type: 'error',
      text: 'Declined',
      iconSrc: 'CloseX',
    };
  } else if (
    productOffer?.productEligibility?.status ===
    ProductEligibilityStatus.Eligible
  ) {
    return {
      type: 'success',
      text: 'Eligible',
    };
  } else if (
    productOffer?.productEligibility?.status ===
    ProductEligibilityStatus.Ineligible
  ) {
    return {
      type: 'gray',
      text: 'Ineligible',
    };
  } else if (
    productOffer?.productEligibility?.status ===
    ProductEligibilityStatus.External
  ) {
    return {
      type: 'light',
      text: 'External Check',
    };
  } else {
    return {
      type: 'light',
      text: 'Eligibility Unknown',
    };
  }
}
