import { useProductOffer } from '@soluto-private/asp-react-core';
import {
  Card,
  CardProps,
  Tag,
  Text,
} from '@soluto-private/mx-asurion-ui-react';
import { getTagProps } from '../../utils';

export interface ProductSelectCardProps extends CardProps {
  className: string;
  partnerLogo: React.ReactNode;
  productPrice: React.ReactNode;
  productName: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function ProductSelectCard({
  partnerLogo,
  productPrice,
  productName,
  ...cardProps
}: ProductSelectCardProps) {
  const [{ data: productOffer }] = useProductOffer({
    required: false,
    includeProductEligibility: true,
    includeProductEnrollment: true,
    includeProductDeclination: true,
  });

  return (
    <Card
      element="button"
      type="call-to-action"
      style={{ padding: '1rem' }}
      {...cardProps}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>{partnerLogo}</div>
          <div>
            <Tag
              {...getTagProps({
                productOffer,
              })}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            paddingTop: '0.35rem',
          }}
        >
          <div>
            <Text>{productName}</Text>
          </div>
          <div>
            <Text>{productPrice}</Text>
          </div>
        </div>
      </div>
    </Card>
  );
}
