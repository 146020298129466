import querystring, { ParsedUrlQuery } from 'querystring';
import type { UrlObject } from 'url';
type Url = string | UrlObject;

const BLOCKED_KEYS = [
  'customerSessionStartedEvent',
  'customerValidatedEvent',
  'supportSessionStartedEvent',
  'customer-search',
  'state',
  'code',
  'error',
  'error_uri',
  'error_description',
];

let prevSearch: string | undefined = undefined;
let currentParams: Record<string, string> = {};
let prefixedParams: Record<string, string> | ParsedUrlQuery = {};

const filterObjectKeys = <T>(
  obj: { [k: string]: T },
  keysToFilter: string[],
): { [k: string]: T } => {
  const clonedObject = { ...obj };

  const objectKeys = Object.keys(clonedObject);

  keysToFilter.forEach(
    (filterKey) =>
      delete clonedObject[
        objectKeys.find((objectKey: string) => {
          return objectKey.toLowerCase() === `QP${filterKey}`.toLowerCase();
        }) as string
      ],
  );

  return clonedObject;
};

/**
 * Get query parameters from the URL.
 *
 * When href is provided, it parses the query parameters from the href and
 * prefixes them with 'Routing_'. Otherwise, checks if previous query string is
 * different from the current query string and returns query parameters prefixed
 * with 'QP'.
 *
 * @param href URL string
 * @returns Query parameters from the URL
 */
export function getQueryParams(href?: string) {
  if (typeof window === 'undefined') {
    return {};
  }

  if (href) {
    const urlSearchParams = querystring.parse(href.split('?')[1]);
    prefixedParams = {};
    for (const key in urlSearchParams) {
      prefixedParams[`Routing_${key}`] = urlSearchParams[key];
    }

    return prefixedParams;
  }
  if (prevSearch !== window.location.search) {
    const urlSearchParams = new URLSearchParams(window.location.search);

    currentParams = Object.fromEntries(urlSearchParams.entries());
    prefixedParams = {};
    for (const key in currentParams) {
      prefixedParams[`QP${key}`] = currentParams[key];
    }

    prevSearch = window.location.search;
    return prefixedParams;
  }

  return prefixedParams;
}

/**
 * Get URL origin, route, and query parameters from a URL object.
 *
 * @param href URL object or string
 * @returns Object with URL origin, route, and query parameters
 */
export function getUrlData(href?: Url): {
  URL: string | null;
  Route: string | null;
  [k: string]: string | null;
} {
  const queryParams = href ? getQueryParams(href.toString()) : getQueryParams();
  const filteredParams = filterObjectKeys(queryParams, BLOCKED_KEYS);

  return {
    URL: typeof window !== 'undefined' ? window.location.origin : null,
    Route:
      typeof window !== 'undefined'
        ? window.location.pathname.replace(/\//, '').replace(/\/+$/, '')
        : null,
    ...filteredParams,
  };
}
