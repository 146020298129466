import { useEventBridge } from '@/hooks/useEventBridge';
import { ASPEventBridge } from '@/utils/aspEventBridge';
import { computePathWithQuery } from '@/utils/path';
import { useAnalytics } from '@sales-domain/analytics-react';
import {
  CustomerSessionPartner,
  getCustomerSessionPartnerForProductSKU,
} from '@sales-domain/product-config';
import {
  CreateCustomerSessionMutation,
  CustomerSessionDocument,
  ProductOffersByCustomerSessionIdDocument,
  SessionStatus,
  UpdateCustomerDocument,
  UpdateCustomerSessionDocument,
  UpdateCustomerSessionsDocument,
  assertCreateCustomerSessionSuccessPayload,
  assertCreateProductOfferSuccessPayload,
  assertUpdateCustomerSuccessPayload,
} from '@soluto-private/asp-core';
import {
  appendPartner,
  useCreateCustomerSession,
  useCreateProductOffer,
  useExpertSession,
  useStartCustomerSession,
} from '@soluto-private/asp-react-core';
import {
  CustomerValidatedEvent,
  SupportSessionClosedEvent,
  SupportSessionStartedEvent,
  ToggleSalesToolDisplayEvent,
} from '@soluto-private/bridge';
import { invariant } from '@soluto-private/invariant';
import { Origin, addCountryCode } from '@soluto-private/utils';
import { useSession } from 'next-auth/react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClient, useMutation } from 'urql';
import { SocoEventHandlerQueue } from './utils/SoCoEventHandlerQueue';
import { sendAnalyticEvent } from './utils/analytics';
import {
  createCustomerSessionWrapper,
  startCustomerSessionWrapper,
} from './utils/customer-session';
import { handleCustomerAddressEntry } from './utils/handleCustomerAddressEntry';
import { soCoEventClientMap } from './utils/mappers';

export function SoCoEventHandler() {
  const socoEventHandlerQueue = useMemo(() => new SocoEventHandlerQueue(), []);
  const [handledEvent, setHandledEvent] = useState(false);
  const { data: session, status } = useSession();
  const analytics = useAnalytics();

  const [{ data: expertSession }] = useExpertSession({
    expertSessionId: session?.user?.expertSessionId!,
    includeExpert: true,
  });

  const client = useClient();

  const isQueueEnabled = useMemo(
    () =>
      process.env.NEXT_PUBLIC_FEAT_SOCO_HANDLER_QUEUE === 'true' ||
      ((session?.user?.expertId != null &&
        process.env.NEXT_PUBLIC_SOCO_EVENT_PILOT_EXPERTS?.split(',')
          .map((id) => id.trim())
          .includes(session?.user?.expertId)) ??
        false),
    [session?.user?.expertId],
  );

  // Call the mutation directly instead of using a asp-react-core hook due to not having the partner until the event comes in
  const [, updateCustomerSessions] = useMutation(
    UpdateCustomerSessionsDocument,
  );
  const [, updateCustomer] = useMutation(UpdateCustomerDocument);
  const [, startCustomerSession] = useStartCustomerSession();
  const [, createCustomerSession] = useCreateCustomerSession();
  const [, createProductOffer] = useCreateProductOffer();
  const [, updateCustomerSession] = useMutation(UpdateCustomerSessionDocument);

  const searchParams = useSearchParams();
  const path = usePathname();
  const router = useRouter();

  const productSKUs = useMemo(
    () =>
      new Map(
        session?.user?.products?.map((productSKU) => [
          productSKU,
          getCustomerSessionPartnerForProductSKU(productSKU).toLowerCase(),
        ]),
      ),
    [session?.user?.products],
  );

  const saveCsid = useCallback(
    (csid?: string | null) => {
      if (isQueueEnabled && csid) {
        localStorage.setItem('csid', csid);
      } else {
        localStorage.removeItem('csid');
        return;
      }
    },
    [isQueueEnabled],
  );

  useEffect(() => {
    const handleUnmount = () => {
      // remove the csid from local storage to prevent any issues in the future
      saveCsid();
    };

    return handleUnmount;
  }, [saveCsid, isQueueEnabled]);

  const fetchCsid = useCallback(() => {
    if (isQueueEnabled) {
      return localStorage.getItem('csid');
    } else {
      return searchParams?.get('csid');
    }
  }, [isQueueEnabled, searchParams]);

  const completeExistingCustomerSession = useCallback(
    (
      event:
        | CustomerValidatedEvent
        | SupportSessionStartedEvent
        | SupportSessionClosedEvent,
      initialCsid: string,
      sendExternalSessionId?: boolean,
    ) => {
      const partner = soCoEventClientMap[event.client];

      updateCustomerSessions(
        {
          id: initialCsid,
          status: SessionStatus.Completed,
          externalSessionId: sendExternalSessionId
            ? event.supportSessionId
            : null,
          expertId: event.employeeId,
        },
        appendPartner(partner ?? client, {}),
      );
      saveCsid();
    },
    [client, saveCsid, updateCustomerSessions],
  );

  const handleInvalidClient = useCallback(
    (urlOverride?: string) => {
      // If client is invalid, route to the waiting room if they are authenticated.
      // Otherwise, return immediately so that we don't persist the event with an invalid client in the callbackUrl
      if (status === 'authenticated') {
        router.push(urlOverride ? urlOverride : '/no-offers-available');
      }
    },
    [router, status],
  );

  const checkIsClientValid = useCallback(
    (
      event:
        | CustomerValidatedEvent
        | SupportSessionStartedEvent
        | SupportSessionClosedEvent,
    ) => {
      if (soCoEventClientMap[event.client] == null) {
        sendAnalyticEvent(
          analytics,
          expertSession?.hostApp,
          status,
          session?.user?.expertSessionId,
          'InvalidClient_Received',
          event,
          {
            EventType: event.type,
          },
        );

        return false;
      }

      return true;
    },
    [analytics, expertSession?.hostApp, session?.user?.expertSessionId, status],
  );

  const sendUnexpectedMissingMDNEvent = useCallback(
    (event: CustomerValidatedEvent) => {
      sendAnalyticEvent(
        analytics,
        expertSession?.hostApp,
        status,
        session?.user?.expertSessionId,
        'UnexpectedEmptyMDN_Received',
        event,
        {
          CustomerValidatedEvent: event.toJSON(),
        },
      );
    },
    [analytics, expertSession?.hostApp, session?.user?.expertSessionId, status],
  );

  const handleCustomerValidated = useCallback(
    async (event: CustomerValidatedEvent) => {
      const isClientValid = checkIsClientValid(event);
      const partner = soCoEventClientMap[event.client];
      const {
        mdn,
        asurionCallId,
        reservationId: flexReservationId,
        supportSessionId: externalSessionId,
      } = event;
      const validationMethod = event.validationMethod.toLowerCase();
      const initialCsid = fetchCsid();

      sendAnalyticEvent(
        analytics,
        expertSession?.hostApp,
        status,
        session?.user?.expertSessionId,
        'CustomerValidatedEvent_Received',
        event,
        {
          CustomerValidatedEvent: isClientValid
            ? event.toJSON()
            : { ...event.toJSON(), mdn: undefined },
        },
      );

      if (!isClientValid) {
        // If client is invalid, route to no-offers-available if they are authenticated.
        // Otherwise, return immediately so that we don't persist the event with an invalid client in the callbackUrl
        handleInvalidClient();
        return;
      }

      // If the user is not authenticated, persist the event in the callbackUrl so that when they sign in, it will be handled
      if (status !== 'authenticated') {
        const callbackUrlParam = searchParams?.get('callbackUrl');
        invariant(
          !Array.isArray(callbackUrlParam),
          'callbackUrl cannot be an array',
        );

        const callbackUrl = new URL(
          callbackUrlParam ?? '/',
          window.location.origin,
        );
        callbackUrl.searchParams.set(
          'customerValidatedEvent',
          JSON.stringify(event.toJSON()),
        );

        router.replace(
          computePathWithQuery(path, searchParams, {
            callbackUrl: callbackUrl.toString(),
          }),
        );
        return;
      }

      if (validationMethod === 'mdn' && mdn != null) {
        try {
          switch (event.client) {
            case CustomerSessionPartner.Microsoft:
            case CustomerSessionPartner.Google:
              const customerSessionId = await handleCustomerAddressEntry(
                createCustomerSession,
                partner,
                externalSessionId,
                session?.user?.expertSessionId,
                mdn,
                asurionCallId,
                flexReservationId,
              );
              router.push(
                computePathWithQuery('/customer-address', null, {
                  csid: customerSessionId,
                }),
              );
              saveCsid(customerSessionId);
              break;
            default: {
              if (initialCsid) {
                const customerSession = await client
                  .query(
                    CustomerSessionDocument,
                    {
                      customerSessionId: initialCsid,
                      includeCustomer: true,
                    },
                    {
                      requestPolicy: 'network-only',
                    },
                  )
                  .toPromise();

                const productOffersByCustomerSessionId = await client
                  .query(
                    ProductOffersByCustomerSessionIdDocument,
                    {
                      customerSessionId: initialCsid,
                    },
                    {
                      requestPolicy: 'network-only',
                    },
                  )
                  .toPromise();

                const customerMdn =
                  customerSession.data?.customerSession?.customer?.mdn;
                const customerId =
                  customerSession.data?.customerSession?.customer?.id;
                const isCustomerSessionActive =
                  customerSession.data?.customerSession?.status !==
                  SessionStatus.Completed;

                if (
                  isCustomerSessionActive &&
                  productOffersByCustomerSessionId.data
                    ?.productOffersByCustomerSessionId?.length
                ) {
                  /**
                   * There are product offers associated with the initialCsid,
                   * so we will either route to the product menu if the MDNs
                   * match, or close the initialCsid session and create a new
                   * one if they do not match
                   */

                  if (
                    customerMdn &&
                    addCountryCode(mdn) === addCountryCode(customerMdn)
                  ) {
                    updateCustomerSession({
                      id: initialCsid,
                      status: SessionStatus.InProgress,
                    });
                    router.push(
                      computePathWithQuery('/product-menu', null, {
                        csid: initialCsid,
                      }),
                    );

                    return;
                  } else {
                    completeExistingCustomerSession(event, initialCsid);

                    const {
                      customerSession: { data: customerSessionData },
                    } = await startCustomerSessionWrapper(
                      session?.user?.expertSessionId,
                      Origin.twilioFlex,
                      productSKUs,
                      startCustomerSession,
                      event,
                    );

                    assertCreateCustomerSessionSuccessPayload(
                      customerSessionData,
                    );

                    const { customerSession } =
                      customerSessionData.createCustomerSession;

                    router.push(
                      computePathWithQuery('/product-menu', null, {
                        csid: customerSession.id,
                      }),
                    );
                    saveCsid(customerSession.id);

                    return;
                  }
                } else if (isCustomerSessionActive && customerId != null) {
                  /**
                   * There are no product offers associated with the
                   * initialCsid, and we DO have a Customer ID to update the MDN
                   * for. We will update the MDN on the customer entity and
                   * create product offers on the existing session
                   */

                  if (
                    !customerMdn ||
                    addCountryCode(mdn) !== addCountryCode(customerMdn)
                  ) {
                    const { data: updateCustomerData } = await updateCustomer(
                      {
                        customerSessionId: initialCsid,
                        id: customerId,
                        mdn: addCountryCode(mdn),
                      },
                      appendPartner(partner ?? event.client, {}),
                    );

                    assertUpdateCustomerSuccessPayload(updateCustomerData);
                  }
                  updateCustomerSession({
                    id: initialCsid,
                    status: SessionStatus.InProgress,
                  });

                  const productsToOffer = [...(productSKUs?.entries() ?? [])]
                    .filter(([, skuPartner]) => skuPartner === partner)
                    .map(([productSKU]) => productSKU);

                  const productOffers = await Promise.all(
                    productsToOffer.map((productSKU) =>
                      createProductOffer({
                        partner,
                        customerSessionId: initialCsid,
                        productSku: productSKU,
                        expertSessionId: expertSession?.id,
                      }),
                    ),
                  );

                  productOffers.forEach((productOffer) => {
                    assertCreateProductOfferSuccessPayload(productOffer.data);
                  });

                  router.push(
                    computePathWithQuery('/product-menu', null, {
                      csid: initialCsid,
                    }),
                  );

                  return;
                }
              }
              /**
               * One of 3 scenarios:
               *
               * 1. The status associated with the initialCsid is completed (the
               *    initialCsid session is not active).
               * 2. There are no product offers associated with the initialCsid,
               *    but we do not have a Customer ID to update the MDN for.
               * 3. There was no initialCsid.
               *
               * In any of these cases, we need to start a new customer session
               * and route to product menu
               */

              // Needed in order to ensure that scenario 2 completes the session before starting a new one
              if (initialCsid != null) {
                completeExistingCustomerSession(event, initialCsid);
              }
              const {
                customerSession: { data: customerSessionData },
              } = await startCustomerSessionWrapper(
                session?.user?.expertSessionId,
                Origin.twilioFlex,
                productSKUs,
                startCustomerSession,
                event,
              );

              assertCreateCustomerSessionSuccessPayload(customerSessionData);

              const { customerSession } =
                customerSessionData.createCustomerSession;

              router.push(
                computePathWithQuery('/product-menu', null, {
                  csid: customerSession.id,
                }),
              );

              saveCsid(customerSession.id);

              return;
            }
          }
        } catch (error) {
          throw error;
        }
      } else {
        if (validationMethod === 'mdn' && mdn == null) {
          sendUnexpectedMissingMDNEvent(event);
        }

        if (initialCsid != null) {
          completeExistingCustomerSession(event, initialCsid);
        }

        router.push('/customer-search');

        return;
      }
    },
    [
      checkIsClientValid,
      fetchCsid,
      analytics,
      expertSession?.hostApp,
      expertSession?.id,
      status,
      session?.user?.expertSessionId,
      handleInvalidClient,
      searchParams,
      router,
      path,
      createCustomerSession,
      saveCsid,
      productSKUs,
      startCustomerSession,
      client,
      updateCustomerSession,
      completeExistingCustomerSession,
      updateCustomer,
      createProductOffer,
      sendUnexpectedMissingMDNEvent,
    ],
  );

  const handleCustomerValidatedQueue = useCallback(
    async (event: CustomerValidatedEvent) => {
      socoEventHandlerQueue.add(
        async () => await handleCustomerValidated(event),
        'CustomerValidatedEvent',
      );
    },
    [handleCustomerValidated, socoEventHandlerQueue],
  );

  const handleSupportSessionStarted = useCallback(
    async (event: SupportSessionStartedEvent) => {
      const isClientValid = checkIsClientValid(event);
      const { client, mdn } = event;
      const initialCsid = fetchCsid();

      sendAnalyticEvent(
        analytics,
        expertSession?.hostApp,
        status,
        session?.user?.expertSessionId,
        'SupportSessionStarted_Received',
        event,
        {
          SupportSessionStartedEvent: isClientValid
            ? event.toJSON()
            : { ...event.toJSON(), mdn: undefined },
        },
      );

      // Complete all customer sessions associated with the current queryParam CSID, if one exists
      if (initialCsid != null) {
        completeExistingCustomerSession(event, initialCsid);
      }

      if (!isClientValid) {
        handleInvalidClient();
        return;
      }

      // If the user is not authenticated, persist the event in the callbackUrl so that when they sign in, it will be handled
      if (status !== 'authenticated') {
        const callbackUrlParam = searchParams?.get('callbackUrl');

        invariant(
          !Array.isArray(callbackUrlParam),
          'callbackUrl cannot be an array',
        );

        const callbackUrl = new URL(
          callbackUrlParam ?? '/',
          window.location.origin,
        );
        callbackUrl.searchParams.set(
          'supportSessionStartedEvent',
          JSON.stringify(event.toJSON()),
        );
        router.replace(
          computePathWithQuery(path, searchParams, {
            callbackUrl: callbackUrl.toString(),
          }),
        );

        return;
      }

      const processedEvent = {
        ...event,
        mdn: mdn === '' ? null : mdn,
      } as SupportSessionStartedEvent;

      try {
        let customerSession: CreateCustomerSessionMutation | null | undefined;

        if (mdn) {
          const {
            customerSession: { data: customerSessionData },
          } = await startCustomerSessionWrapper(
            session?.user?.expertSessionId,
            Origin.twilioFlex,
            productSKUs,
            startCustomerSession,
            processedEvent,
            SessionStatus.StartedWithMdn,
          );

          customerSession = customerSessionData;
        } else {
          const { data } = await createCustomerSessionWrapper(
            session?.user?.expertSessionId,
            Origin.twilioFlex,
            createCustomerSession,
            processedEvent,
            SessionStatus.StartedWithoutMdn,
          );

          customerSession = data;
        }

        assertCreateCustomerSessionSuccessPayload(customerSession);

        ASPEventBridge.getInstance().sendToHostApp(
          new ToggleSalesToolDisplayEvent({
            shouldDisplay: true,
          }),
        );

        // set CSID on URL without refreshing or losing existing params
        // this will only be used if the expert is NOT in the refactor group
        if (!isQueueEnabled) {
          router.replace(
            computePathWithQuery(path, searchParams, {
              csid: customerSession.createCustomerSession.customerSession.id,
            }),
          );
        }
        saveCsid(customerSession.createCustomerSession.customerSession.id);
      } catch (error) {
        // Do we want to log some analytic here?
        throw error;
      }
    },
    [
      checkIsClientValid,
      fetchCsid,
      analytics,
      expertSession?.hostApp,
      status,
      session?.user?.expertSessionId,
      completeExistingCustomerSession,
      handleInvalidClient,
      searchParams,
      path,
      isQueueEnabled,
      saveCsid,
      productSKUs,
      startCustomerSession,
      createCustomerSession,
      router,
    ],
  );

  const handleSupportSessionStartedQueue = useCallback(
    async (event: SupportSessionStartedEvent) => {
      socoEventHandlerQueue.add(
        async () => await handleSupportSessionStarted(event),
        'SupportSessionStarted',
      );
    },
    [handleSupportSessionStarted, socoEventHandlerQueue],
  );

  const handleSupportSessionClosed = useCallback(
    async (event: SupportSessionClosedEvent) => {
      const isClientValid = checkIsClientValid(event);
      const initialCsid = fetchCsid();

      sendAnalyticEvent(
        analytics,
        expertSession?.hostApp,
        status,
        session?.user?.expertSessionId,
        'SupportSessionClosedEvent_Received',
        event,
        {
          SupportSessionClosedEvent: isClientValid
            ? event.toJSON()
            : { ...event.toJSON(), mdn: undefined },
        },
      );

      // Complete all customer sessions associated with the queryParam CSID and the supportSessionId on the event
      if (initialCsid != null) {
        completeExistingCustomerSession(event, initialCsid, true);
      }

      ASPEventBridge.getInstance().sendToHostApp(
        new ToggleSalesToolDisplayEvent({
          shouldDisplay: false,
        }),
      );

      router.push('/');
    },
    [
      analytics,
      checkIsClientValid,
      completeExistingCustomerSession,
      expertSession?.hostApp,
      fetchCsid,
      router,
      session?.user?.expertSessionId,
      status,
    ],
  );

  const handleSupportSessionClosedQueue = useCallback(
    async (event: SupportSessionClosedEvent) => {
      socoEventHandlerQueue.add(
        async () => await handleSupportSessionClosed(event),
        'SupportSessionClosed',
      );
    },
    [handleSupportSessionClosed, socoEventHandlerQueue],
  );

  /**
   * This useEffect is used to handle the scenario when a customerValidated
   * event came through while the expert was signed out. It will take the event
   * off of the query params and then pass it along to the
   * handleCustomerValidated function.
   *
   * This is effectively the same logic as the previous behavior in
   * CustomerAutoPopHandler.tsx
   */
  useEffect(() => {
    const customerValidatedEventParam = searchParams?.get(
      'customerValidatedEvent',
    );
    if (
      typeof customerValidatedEventParam === 'string' &&
      status === 'authenticated' &&
      !handledEvent
    ) {
      const event = JSON.parse(customerValidatedEventParam);
      setHandledEvent(true);

      const newCustomerValidatedEvent = new CustomerValidatedEvent({
        ...event,
      });

      isQueueEnabled
        ? handleCustomerValidatedQueue(newCustomerValidatedEvent)
        : handleCustomerValidated(newCustomerValidatedEvent);
    }
  }, [
    handleCustomerValidated,
    handledEvent,
    router,
    searchParams,
    status,
    isQueueEnabled,
    handleCustomerValidatedQueue,
  ]);

  useEventBridge({
    handleCustomerValidated: isQueueEnabled
      ? handleCustomerValidatedQueue
      : handleCustomerValidated,
    handleSupportSessionStarted: isQueueEnabled
      ? handleSupportSessionStartedQueue
      : handleSupportSessionStarted,
    handleSupportSessionClosed: isQueueEnabled
      ? handleSupportSessionClosedQueue
      : handleSupportSessionClosed,
  });

  return null;
}
