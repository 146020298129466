import { useAnalytics } from '@sales-domain/analytics-react';
import { TrackEvent } from '@soluto-private/bridge';
import { useCallback } from 'react';
import { useBridgeEvent } from './useBridgeEvent';

/**
 * Relay track events to the analytics service.
 *
 * This hook should be used in the root component of the application to ensure
 * that all ASPEventBridge events are reported.
 */
export function useBridgeAnalytics() {
  const analytics = useAnalytics();

  useBridgeEvent(
    TrackEvent,
    useCallback(
      (event) => {
        analytics.track(event.name ?? 'Unknown', {
          Name: event.name,
          Scope: event.scope,
          Time: event.time,
          ExtraData: event.extraData,
          Identities: event.identities,
          MetaData: event.metaData,
        });
      },
      [analytics],
    ),
  );
}
