import * as AUI from '@soluto-private/mx-asurion-ui-react';
import NextLink from 'next/link';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

type Props = Pick<
  ComponentPropsWithoutRef<typeof NextLink>,
  'href' | 'as' | 'replace' | 'scroll' | 'shallow' | 'prefetch' | 'locale'
> &
  Omit<AUI.LinkProps, 'href'>;

const Link = forwardRef<HTMLAnchorElement, Props>(function LinkComponent(
  { href, as, replace, scroll, shallow, prefetch, locale, children, ...rest },
  ref,
) {
  return (
    <NextLink
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
      locale={locale}
      passHref
      legacyBehavior
    >
      <AUI.Link {...{ ...rest, ref }}>{children}</AUI.Link>
    </NextLink>
  );
});

export default Link;
