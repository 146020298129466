import { CallType } from '@sales-domain/types';
import {
  AddressType,
  BaseErrorFragment,
  CustomerFragment,
  CustomerSessionFragment,
  isCreateCustomerSessionSuccessPayload,
} from '@soluto-private/asp-core';
import { useStartCustomerSession } from '@soluto-private/asp-react-core';
import { invariant } from '@soluto-private/invariant';
import {
  Button,
  ButtonProps,
  Dropdown,
  TextField,
  TextFieldProps,
  Tooltip,
  TooltipProps,
} from '@soluto-private/mx-asurion-ui-react';
import {
  Origin,
  addCountryCode,
  capitalizeString,
  formatMdn,
} from '@soluto-private/utils';
import ConditionalWrap from 'conditional-wrap';
import { ChangeEvent, FocusEvent, useCallback, useRef, useState } from 'react';
import { CombinedError } from 'urql';
import { ExtensionCode } from '../../generated/types';
import { emptyAddress } from '../AddressInput';
import { ExtractedAddress } from '../AddressSearch';
import { AddressSelect } from '../AddressSelect';
import { AnalyticsWrapper, IdentityData } from '../AnalyticsWrapper';

export interface CustomerSearchButtonProps extends ButtonProps {}

export interface CustomerSearchInputProps extends TextFieldProps {}

/**
 * CustomerSearchProps is used to allow consumers to pass props for specific
 * pieces of this component without worrying about overwriting props of the same
 * name.
 */
export interface CustomerSearchProps {
  partners: string | string[];
  /**
   * Map of product skus as keys with the value being the partner the product
   * belongs to.
   */
  productSKUs?: Map<string, string>;
  externalSessionId?: string;
  asurionCallId?: string;
  flexReservationId?: string;
  buttonProps?: CustomerSearchButtonProps;
  inputProps?: TextFieldProps;
  buttonTooltipProps?: TooltipProps;
  showAddressSearch?: boolean;
  showCallType?: boolean;
  selectedPartner?: string;
  callTypeOptions?: CallType[];
  selectedCallType?: string;
  onCallTypeSelect?: (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => void;
  onPartnerSelect?: (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => void;
  onSearch?: (data: {
    customer: CustomerFragment;
    customerSession: CustomerSessionFragment;
  }) => void;
  onBaseError?(error: BaseErrorFragment): void;
  onGraphQLError?(error: CombinedError): void;
  identityData?: IdentityData;
}

export function CustomerSearch({
  partners,
  productSKUs,
  asurionCallId,
  externalSessionId,
  flexReservationId,
  buttonTooltipProps,
  showAddressSearch,
  showCallType,
  selectedPartner,
  selectedCallType,
  callTypeOptions,
  onCallTypeSelect,
  onPartnerSelect,
  onSearch,
  onBaseError,
  onGraphQLError,
  buttonProps: { disabled, ...buttonProps } = {},
  inputProps,
  identityData,
}: CustomerSearchProps) {
  const [customerMdn, setCustomerMdn] = useState<string>(
    (inputProps?.value as string) ?? '',
  );
  const [inputError, setInputError] = useState<
    Pick<TextFieldProps, 'helperText' | 'fieldStatus'>
  >({
    helperText: 'Enter the customer MDN',
    fieldStatus: 'default',
  });

  const partnerDropdownOptions = Array.isArray(partners)
    ? partners.map((partner) => {
        return { name: capitalizeString(partner), value: partner };
      })
    : [];

  const sortedPartnerDropDownOptions = [...partnerDropdownOptions].sort(
    (a, b) => a.name.localeCompare(b.name),
  );
  const [{ fetching: startingCustomerSession }, startCustomerSession] =
    useStartCustomerSession();

  const [addressInput, setAddressInput] = useState<
    ExtractedAddress | undefined
  >(undefined);

  const hiddenAddressFieldRef = useRef<HTMLInputElement | null>(null);

  function handlePartnerSelect(
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) {
    onPartnerSelect?.(event);
  }

  function handleCallTypeSelect(
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) {
    onCallTypeSelect?.(event);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    inputProps?.onChange?.(event);
    setCustomerMdn(event.target.value.replace(/\D/g, '').slice(0, 10));
  }

  function handleBlur(event: FocusEvent<HTMLInputElement>) {
    inputProps?.onBlur?.(event);

    if (customerMdn.length !== 10) {
      setInputError({
        helperText: 'Customer MDN must be 10 digits',
        fieldStatus: 'error',
      });
    } else {
      setInputError({
        helperText: 'Enter the customer MDN',
        fieldStatus: 'default',
      });
    }
  }

  function handleFocus(event: FocusEvent<HTMLInputElement>) {
    inputProps?.onFocus?.(event);
    setInputError({
      helperText: 'Enter the customer MDN',
      fieldStatus: 'default',
    });
  }

  const onAddressSelected = useCallback(
    (address?: ExtractedAddress) =>
      setAddressInput(address ? address : undefined),
    [],
  );

  async function handleClick() {
    const customerAddress: ExtractedAddress | undefined = addressInput;
    const getPartnerToSend = (): string => {
      if (!Array.isArray(partners)) {
        return partners;
      }

      // selectedPartner can be undefined here if the length of the dropdown options is 1 or fewer.
      // This can happen by passing partner as a single string value, or as an array with a single item.
      // In this scenario, the search button will not be disabled if selectedPartner is undefined.
      return selectedPartner ?? partners[0];
    };

    const partnerToSend = getPartnerToSend();

    const {
      customerSession: {
        data: customerSessionData,
        error: customerSessionError,
      },
    } = await startCustomerSession(
      {
        partner: partnerToSend,
        customer: {
          mdn: addCountryCode(customerMdn),
          address: customerAddress?.address1
            ? {
                addressType: AddressType.Service,
                addressLine1: customerAddress.address1,
                addressLine2: customerAddress.address2,
                city: customerAddress.city,
                state: customerAddress.state,
                postalCode: customerAddress.zip,
                country: customerAddress.country,
              }
            : undefined,
        },
        externalSession: externalSessionId
          ? {
              id: externalSessionId,
            }
          : undefined,
        extensions: selectedCallType
          ? [
              {
                code: ExtensionCode.McafeeCallType,
                value: selectedCallType,
              },
            ]
          : undefined,
        asurionCall: asurionCallId
          ? {
              id: asurionCallId,
              flexReservation: flexReservationId
                ? {
                    id: flexReservationId,
                  }
                : undefined,
            }
          : undefined,
        productSKUs,
        origin: Origin.salesTool,
      },
      {
        additionalTypenames: ['SalesMetric'],
      },
    );

    if (customerSessionError != null) {
      onGraphQLError?.(customerSessionError);
    }

    if (!isCreateCustomerSessionSuccessPayload(customerSessionData)) {
      if (
        customerSessionData?.createCustomerSession.__typename === 'BaseError'
      ) {
        onBaseError?.(customerSessionData.createCustomerSession);
      }

      return;
    }

    invariant(
      customerSessionData.createCustomerSession.customerSession.customer !=
        null,
      'customer is expected to exist on customerSession',
    );

    onSearch?.({
      customerSession:
        customerSessionData.createCustomerSession.customerSession,
      customer:
        customerSessionData.createCustomerSession.customerSession.customer,
    });
  }

  const shouldDisableButton =
    customerMdn.length !== 10 ||
    startingCustomerSession ||
    (showAddressSearch &&
      (addressInput === undefined || addressInput === emptyAddress)) ||
    (showCallType && !selectedCallType) ||
    (partnerDropdownOptions.length > 1 && !selectedPartner) ||
    disabled;
  // disable button when a partner hasn't been selected and the dropdown is visible

  return (
    <>
      {partnerDropdownOptions.length > 1 && (
        <Dropdown
          options={sortedPartnerDropDownOptions}
          label="Select partner"
          value={selectedPartner}
          onChange={(event) => handlePartnerSelect(event)}
          style={{ marginBottom: '1rem' }}
        />
      )}
      <div style={{ marginBottom: '1rem' }}>
        <TextField
          label="MDN"
          affixLeft="SearchMagnifyGlass"
          value={formatMdn(customerMdn)}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          inputClassName="fs-mask"
          style={{ marginBottom: '1rem' }}
          {...inputError}
          {...inputProps}
        />
      </div>
      {showAddressSearch && (
        <div style={{ marginBottom: '2rem' }}>
          <input
            type="hidden"
            name="serviceAddress"
            ref={hiddenAddressFieldRef}
          />
          <AddressSelect
            onAddressSelected={onAddressSelected}
            labelText="Service address"
            helperText={
              !addressInput
                ? 'Service address is required.'
                : `We'll cover your eligible home devices at this address. Addresses in Puerto Rico or Canada are not eligible for this program.`
            }
            linkText="Can't find your address? Enter it manually."
            fieldStatus="default"
            disabled={false}
          />
        </div>
      )}
      {showCallType && callTypeOptions && (
        <Dropdown
          options={callTypeOptions}
          label="Select call type"
          value={selectedCallType}
          onChange={(event) => handleCallTypeSelect(event)}
          style={{ marginBottom: '1rem' }}
        />
      )}
      <ConditionalWrap
        condition={buttonTooltipProps != null}
        wrap={(children) => (
          <Tooltip {...buttonTooltipProps!}>{children}</Tooltip>
        )}
      >
        <AnalyticsWrapper
          name="SearchMDN_Clicked"
          identityData={{
            UserId: identityData?.UserId,
            ExpertSessionId: identityData?.ExpertSessionId,
          }}
          disabled={shouldDisableButton}
        >
          <Button
            onClick={handleClick}
            disabled={shouldDisableButton}
            style={{ width: '100%' }}
            {...buttonProps}
          >
            {buttonProps?.children ?? 'Search'}
          </Button>
        </AnalyticsWrapper>
      </ConditionalWrap>
    </>
  );
}
