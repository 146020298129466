import {
  AnalyticsCustomer,
  AnalyticsCustomerSession,
  AnalyticsProductOffer,
} from '@soluto-private/analytics-core';
import {
  CustomerFragment,
  CustomerSessionFragment,
  ProductOfferFragment,
} from '@soluto-private/asp-core';

interface AppData {
  Customer?: AnalyticsCustomer;
  CustomerSession?: AnalyticsCustomerSession;
  ProductOffer?: AnalyticsProductOffer;
}

export function defineProductOffer(
  productOffer: Partial<ProductOfferFragment>,
): AnalyticsProductOffer {
  const { productEligibility, productEnrollment } = productOffer;
  return {
    id: productOffer.id as string,
    partner: productOffer.partner as string,
    productSku: productOffer.productSku as string,
    status: productOffer.status,
    productEligibility: !productEligibility
      ? undefined
      : {
          externalEligibilityId:
            productEligibility?.externalEligibilityId ?? undefined,
          id: productEligibility.id,
          status: productEligibility.status,
        },
    productEnrollment: !productEnrollment
      ? undefined
      : {
          id: productEnrollment.id,
          status: productEnrollment.status,
          lastModified: productEnrollment.lastModified,
        },
  };
}

export function defineCustomer(customer: CustomerFragment): AnalyticsCustomer {
  return {
    id: customer.id,
    asurionId: customer.asurionId ?? undefined,
  };
}

export function defineSalesData({
  customerSession,
  productOffer,
}: {
  customerSession?: Partial<CustomerSessionFragment>;
  productOffer?: Partial<ProductOfferFragment>;
}): AppData {
  return {
    Customer: customerSession?.customer
      ? defineCustomer(customerSession.customer)
      : undefined,
    CustomerSession: customerSession
      ? {
          id: customerSession.id as string,
          partner: customerSession.partner as string,
          status: customerSession.status,
        }
      : undefined,
    ProductOffer:
      productOffer != null ? defineProductOffer(productOffer) : undefined,
  };
}
