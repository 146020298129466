import { CustomerSessionPartner } from '@sales-domain/product-config';

// This is a map of SoCo event clients to the corresponding CustomerSessionPartner
// ALL KEYS MAPPED SHOULD BE LOWERCASE -- The event constructors are responsible for lowercasing the client value for consistency
export const soCoEventClientMap: Record<string, CustomerSessionPartner> = {
  verizon: CustomerSessionPartner.Verizon,
  asurionhomeplus: CustomerSessionPartner.Asurion,
  att: CustomerSessionPartner.ATT,
  cricket: CustomerSessionPartner.Cricket,
  fios: CustomerSessionPartner.Verizon,
  microsoft: CustomerSessionPartner.Microsoft,
  google: CustomerSessionPartner.Google,
  amazon: CustomerSessionPartner.Amazon,
};
