import {
  Button,
  colors,
  Text,
  TextFieldStatus,
} from '@soluto-private/mx-asurion-ui-react';
import { useCallback, useState } from 'react';
import { AddressInput } from '../AddressInput';
import { AddressSearch, ExtractedAddress } from '../AddressSearch';

export interface AddressSelectProps {
  onAddressSelected: (address: ExtractedAddress | undefined) => void;
  labelText?: string;
  helperText?: string;
  linkText?: string;
  disabled?: boolean;
  fieldStatus?: TextFieldStatus;
}

export function AddressSelect({
  onAddressSelected,
  labelText,
  helperText,
  linkText,
  disabled,
  fieldStatus,
}: AddressSelectProps) {
  const [searchedAddress, setSearchedAddress] = useState<
    ExtractedAddress | undefined
  >(undefined);
  const [selectedAddress, setSelectedAddress] = useState<
    ExtractedAddress | undefined
  >(undefined);
  const [searchAddressEnabled, setSearchAddressEnabled] = useState(true);
  const [inputAddressEnabled, setInputAddressEnabled] = useState(false);

  const handleAddressChange = useCallback(
    (newAddress: ExtractedAddress | undefined) => {
      setSelectedAddress(newAddress);
      onAddressSelected(newAddress);
    },
    [onAddressSelected],
  );
  return (
    <>
      <div>
        <AddressSearch
          onAddressSelect={handleAddressChange}
          disabled={disabled || !searchAddressEnabled}
          labelText={labelText}
          fieldStatus={fieldStatus}
        />
        <Text
          data-testid="helper-text"
          size={1}
          color={
            fieldStatus === 'default' ? colors.neutralDeeper : colors.errorDeep
          }
          style={{ paddingLeft: '.75rem', display: 'inline-block' }}
        >
          {helperText}
          {linkText ? ' ' : ''}
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              if (!inputAddressEnabled) {
                setInputAddressEnabled(true);
                setSearchAddressEnabled(false);
                setSearchedAddress(selectedAddress);
              } else {
                setInputAddressEnabled(false);
                setSearchAddressEnabled(true);
                handleAddressChange(searchedAddress);
              }
            }}
          >
            {linkText}
          </Button>
        </Text>
      </div>
      {inputAddressEnabled && (
        <AddressInput
          onAddressCompleted={handleAddressChange}
          address={searchedAddress}
          disabled={disabled}
        />
      )}
    </>
  );
}
