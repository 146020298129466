import styled from 'styled-components';

interface GridFormLayoutProps {
  columns?: number;
  debug?: boolean;
}

export const GridFormLayout = styled.div<GridFormLayoutProps>`
  width: 100%;
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: ${({ columns }) =>
    columns === 2 ? '1fr 1fr' : '1fr'};
  & > div {
    outline: ${({ debug }) => (debug ? '1px dashed grey' : 'none')};
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
