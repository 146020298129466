// src/keys.ts
var keysToRedact = [
  "authorization",
  "asurion-apikey",
  "apikey",
  "cookie",
  "set-cookie",
  "token",
  "x-token",
  "fname",
  "lname",
  "locality",
  "password",
  "email",
  "phone",
  "streetaddress",
  "firstname",
  "lastname",
  "cpf",
  "mdn",
  "mtn",
  "min",
  "addressline1",
  "addressline2",
  "addressline3",
  "address1",
  "address2",
  "address3",
  "city",
  "state",
  "postalcode",
  "country",
  "municipality",
  "accesstoken",
  "refreshtoken",
  "brand",
  "lastfour",
  "expirationmonth",
  "expirationyear",
  "temporarymdn",
  "mobileban",
  "customermdn",
  "coordinates",
  "coordinate",
  "longitude",
  "latitude",
  "zip",
  "zipcode",
  "zipcodeextension",
  "streetname",
  "streetnumber",
  "homephone",
  "workphone",
  "canbereachedphone",
  "workphoneextension",
  "ssn",
  "socialsecuritynumber",
  "employername",
  "nationalnumber",
  "number",
  "ani",
  "managername",
  "expertname",
  "supervisorname",
  "x-vercel-proxy-signature",
  "accountnumber",
  "account",
  "billingtoken",
  "phonenumber",
  "emailaddress",
  "fullname",
  "subscriptionnumber",
  "paymentdetails",
  "encryptionkey",
  "cardbin",
  "firstsix",
  "cardsummary",
  "year",
  "month",
  "customerEmail",
  "stateprovincecode",
  "countrycode",
  "manager",
  "supervisor",
  "username",
  "jwt",
  "sourceip",
  "x-forwarded-for",
  "x-forwarded-port",
  "first_name",
  "last_name",
  "address"
];
var keysToValueRedact = ["message"];

// src/redactAst.ts
import { parse, print, visit } from "graphql";
var isAst = (input) => {
  try {
    return !!parse(input);
  } catch (e) {
    return false;
  }
};
var redactAst = (input) => {
  if (!isAst(input)) {
    return input;
  }
  const redactedAst = visit(parse(input), {
    ObjectField(node) {
      if (keysToRedact.includes(node.name.value.toLowerCase())) {
        return {
          ...node,
          value: {
            kind: "StringValue",
            value: "REDACTED"
          }
        };
      }
      return node;
    }
  });
  return print(redactedAst).replace(/[\s,]+/g, " ").trim();
};

// src/redact-pii.ts
var isJson = (input) => {
  try {
    JSON.parse(input);
    return true;
  } catch (e) {
    return false;
  }
};
var valueRedactor = (input) => {
  const regex = /(?<!\d)\d{10}(?!\d)/g;
  return input.replace(regex, "REDACTED");
};
var internalRedactor = (input, shouldValueRedact) => {
  if (Array.isArray(input)) {
    input.forEach((item, index) => {
      input[index] = internalRedactor(item, shouldValueRedact);
    });
  } else if (input != null && typeof input === "object") {
    Object.keys(input).forEach((key) => {
      if (keysToRedact.includes(key.toLowerCase()) && Array.isArray(input[key])) {
        input[key] = ["REDACTED"];
      } else if (typeof input[key] === "object") {
        internalRedactor(input[key]);
      } else if (typeof input[key] === "string" && isAst(input[key])) {
        input[key] = redactAst(input[key]);
      } else if (key === "body" && typeof input[key] === "string" && isJson(input[key])) {
        input[key] = internalRedactor(
          JSON.parse(input[key])
        );
      } else if (keysToRedact.includes(key.toLowerCase())) {
        input[key] = "REDACTED";
      } else if (typeof input[key] === "string" && keysToValueRedact.includes(key.toLowerCase())) {
        input[key] = valueRedactor(input[key]);
      }
    });
  } else if (input != null && typeof input === "string" && shouldValueRedact) {
    return valueRedactor(input);
  }
  return input;
};
function redactPii(input) {
  try {
    const inputClone = structuredClone(input);
    return internalRedactor(
      inputClone,
      typeof input === "string" || Array.isArray(input)
    );
  } catch (error) {
    return { error: `Error Redacting PII: ${error}` };
  }
}
export {
  redactPii
};
