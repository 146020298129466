import { FULLSTORY_ORG_ID } from '@/constants';
import { useBridgeAnalytics } from '@/hooks/useBridgeAnalytics';
import { useBridgeInvariant } from '@/hooks/useBridgeInvariant';
import { ASPEventBridge } from '@/utils/aspEventBridge';
import { setBugsnagUser } from '@/utils/bugsnagUtils';
import { identify, init, isInitialized } from '@fullstory/browser';
import { useAnalytics } from '@sales-domain/analytics-react';
import {
  UpdateExpertSessionDocument,
  assertUpdateExpertSessionSuccessPayload,
} from '@soluto-private/asp-core';
import { ExpertAuthStatusChangedEvent } from '@soluto-private/bridge';
import { pickDefined } from '@soluto-private/utils';
import { useSession } from 'next-auth/react';
import { useEffect, useRef } from 'react';
import { useMutation } from 'urql';

export function InitializePlugins() {
  const { data: session, status } = useSession();
  const [, updateExpertSession] = useMutation(UpdateExpertSessionDocument);
  const analytics = useAnalytics();
  const triggeredIdentify = useRef<boolean>(false);

  useBridgeAnalytics();
  useBridgeInvariant();

  useEffect(() => {
    if (isInitialized()) {
      return;
    }

    init({
      orgId: FULLSTORY_ORG_ID,
      devMode: process.env.NEXT_PUBLIC_STAGE === 'dev',
      recordOnlyThisIFrame: window.self !== window.top,
    });
  });

  useEffect(() => {
    const embedded = window.self !== window.parent;

    if (status !== 'loading' && session) {
      if (embedded) {
        //this mutation can be removed once flex has release sales-flex-plugin pkg v 1.1.0 to prod
        const variables = {
          id: session?.user?.expertSessionId,
          hostApp: 'twilio-flex',
        };
        updateExpertSession(pickDefined(variables)).then((result) =>
          assertUpdateExpertSessionSuccessPayload(result.data),
        );
        ASPEventBridge.getInstance().sendToHostApp(
          new ExpertAuthStatusChangedEvent(status),
        );
      }
    }
  }, [status, session, updateExpertSession]);

  useEffect(() => {
    if (status !== 'loading' && session?.user && isInitialized()) {
      identify(session.user.expertId, {
        email: session.user.email ?? undefined,
        name: session.user.name,
        sessionId: session.user.expertSessionId,
      });
    }
  }, [status, session]);

  useEffect(() => {
    if (status !== 'loading' && session?.user) {
      setBugsnagUser(session.user);
    }
  }, [status, session]);

  useEffect(() => {
    if (status !== 'loading' && session?.user && !triggeredIdentify.current) {
      const { expertSessionId, ...authUser } = session.user;
      analytics.identify(authUser.expertId, authUser);
      triggeredIdentify.current = true;
    }
  }, [analytics, status, session]);

  useEffect(() => {
    if (
      status !== 'loading' &&
      session?.user?.email &&
      window.dtrum?.identifyUser
    ) {
      window.dtrum.identifyUser(session.user.email);
    }
  }, [status, session]);

  return null;
}
