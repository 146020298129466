export const PRODUCT_SKU_PARTNER_MAP = new Map([
  ['HOMEPRO', 'att'],
  ['ATT_FIBER', 'att'],
  ['3002', 'verizon'],
  ['FWA_5G_CBAND', 'verizon'],
  ['HOMEPLUS1000', 'asurion'],
  ['APPLHOMEPLUS30', 'asurion'],
  ['HOMEPLUSENT', 'asurion'],
  ['APPHOMEPLUSANNUAL360', 'asurion'],
  ['APPLHOMEPLUS35_99', 'asurion'],
  ['CRICKETHOMEPLUS', 'cricket'],
  ['VZN_AAL', 'verizon'],
  ['VZN_ACC', 'verizon'],
  ['VZN_VMPMD', 'verizon'],
  ['VZN_UPGRADE', 'verizon'],
]);
